import { useNavigation } from '@react-navigation/core';
import * as React from 'react';
import { VehicleList } from '../../../components/vehicle_list/VehicleList';
import ScreenNames from '../../ScreenNames';
import { SelectVehicleProps } from '../tab_navigation/FleetstateTabNav';

export default ({selectedVehicle, setSelectedVehicle}: SelectVehicleProps) => {
	const navigation = useNavigation();

	return (
		<VehicleList route={{params: {
			selected: {vehicle: selectedVehicle},
			setSelected: ({vehicle, index}) => {
				setSelectedVehicle?.(vehicle);

				navigation.navigate('Map');
			}
		}}}/>
	);
}
