import * as React from 'react';
import {View, StyleSheet, Alert, Animated, Dimensions} from 'react-native';
import {FAB, ActivityIndicator, Text, IconButton, } from 'react-native-paper';
// import analytics from '@react-native-firebase/analytics';

import VehicleRouteContainer from '../../../containers/VehicleRouteContainer';
import Colors from '../../../constants/Colors';
import {Vehicle} from '../../../containers/FleetstateContainer';

import {VehicleDetailsGird} from '../../../components/VehicleDetailsGridGenerator';
import SettingsContainer from '../../../containers/settings/SettingsContainer';
import {translate} from "../../../components/hooks/useTranslate";
import { ErrorAlert } from '../../../components/ErrorAlert';
import { logoutJWT } from '../../../components/hooks/useJWTLogin';
import { Marker, useMap } from 'react-leaflet';
import * as _ from 'lodash';
import L from 'leaflet';
import { IgnitionOffMarker, IgnitionOnMarker } from '../../../components/icons/Icons.web';
// @ts-ignore
import ReactDOMServer from 'react-dom/server'
// import {View as MotiView} from 'moti';

interface Props {
  vehicle?: Vehicle;
  setVehicle: (arg: Vehicle | undefined) => void;
}

const getHeightByLines = (count: number) => {
  if (count === 0) { // nincs adatmező
    return 110;
  } else if (count > 0 && count <= 3) { // egy sor
    return 175;
  } else { // két sor
    return 255;
  }
}

let vehicleInterval: any;

export default function MapScreenVehicleDetails(props: Props) {
  const map = useMap();

  const [layout, setLayout] = React.useState({
    height: 500,
    opacity: 1,
  });
  const [ready, setReady] = React.useState(false);

  const vehicleRoute = VehicleRouteContainer.useContainer();
  const settingsContainer = SettingsContainer.useContainer();

  React.useEffect(() => {
    if (!props.vehicle) {

      clearInterval(vehicleInterval);
      
      return;
    }

    vehicleRoute.get(props.vehicle.vehicle_id);

    vehicleInterval = setInterval(() => {
      props.vehicle?.vehicle_id && vehicleRoute.get(props.vehicle.vehicle_id);
    }, 30000);

    return () => {
      clearInterval(vehicleInterval);
    };

  }, [props.vehicle]);

  // React.useEffect(() => {
  //   if (vehicleRoute.vehicle?.gps && _.isNumber(vehicleRoute.vehicle?.gps.latitude)
  //     && _.isNumber(vehicleRoute.vehicle?.gps.longitude)) {
  //     map.flyTo({
  //       lat: vehicleRoute.vehicle?.gps.latitude, 
  //       lng: vehicleRoute.vehicle?.gps.longitude,
  //     }, 
  //     15);
  //   }
  // }, [vehicleRoute.vehicle]);

  React.useEffect(() => {
    if (vehicleRoute.status === 'error') {
      if (vehicleRoute.errorCode === 401) {
        logoutJWT();
        ErrorAlert('unauthorized');
      } else {
        ErrorAlert('communication');
      }
  
      logoutJWT();
    }
  
  }, [vehicleRoute.status]);

  const content = React.useMemo(() => {
    let newZoom = '' + (2*(map.getZoom()+2)) +'px';

    if (vehicleRoute.status === 'success') {
      return (
        <>
        <VehicleDetailsGird 
          object={vehicleRoute.vehicle}
          props={props}
          settings={settingsContainer.mapVehicleDetailsFields}
          countItemInLine={6}
        />
        {
           vehicleRoute.vehicle?.gps?.latitude != null && vehicleRoute.vehicle?.gps?.longitude != null &&
          <Marker
            icon={L.divIcon({ className: "customIcon", iconAnchor: [22, 50], html: vehicleRoute.vehicle.ignition ? ReactDOMServer.renderToString( <IgnitionOnMarker height={newZoom} width={newZoom} /> ) : ReactDOMServer.renderToString( <IgnitionOffMarker height={newZoom} width={newZoom} /> ) })}
            position={{lat: vehicleRoute.vehicle?.gps.latitude, lng: vehicleRoute.vehicle?.gps.longitude}}
          />
        }
        
        </>
      );
    } else if (vehicleRoute.status === 'loading') {
      return (
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 139,
          }}>
          <ActivityIndicator />
          <Text>{translate('common.status.data-loading')}</Text>
        </View>
      );
    } else if (vehicleRoute.status === 'error' && vehicleRoute.errorCode === 0) {
      return (<Text>{translate('common.status.no-internet')}</Text>);
    }
  }, [vehicleRoute.status, map.getZoom()]);

  return (
    <div
      style={{
        backgroundColor: Colors.background,
        transition: 'all 2s ease-in-out',
        // overflowX: 'scroll',
      }}>
      <IconButton
        style={styles.fab}
        size={16}
        icon={'times'}
        color={Colors.primary}
        onPress={() => {
          props?.setVehicle?.(undefined);
        }}
      />
      
      {content}
      
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 160,
  },
  fab: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: 'lightgray',
    zIndex: 999,
    shadowOpacity: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});
