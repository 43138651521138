import { Alert } from 'react-native';
import { translate } from './hooks/useTranslate';

export type ErrorType = 'communication' | 'operation' | 'deafult' | 'network' | 'unauthorized';

export const ErrorAlert = (type: ErrorType, message?: string) => {
    let titleTranslated = '';
    let messageTranslated = message;

    switch(type) {
        case 'communication':
            titleTranslated = translate('common.status.error.query-title'); // Lekérdezési hiba
            messageTranslated = translate('common.status.error.communication'); // Az adatok lekérdezése közben hiba történt. Próbálja újra!
            break;
        case 'deafult':
            titleTranslated = translate('common.status.error.something-went-wrong-title'); 
            messageTranslated = translate('common.status.error.something-went-wrong'); // valami hiba történt 
            break;
        case 'operation':
            titleTranslated = translate('common.status.error.operation-title'); // Művelet nem sikerült...
            break;
        case 'network':
            titleTranslated = translate('common.status.error.query-title'); // Lekérdezési hiba
            messageTranslated = translate('common.status.error.no-internet'); // Nincs internet.....
            break;
        case 'unauthorized':
            titleTranslated = translate('common.status.error.unauthorized-title'); // session not found / unauthorized
            messageTranslated = translate('common.status.error.unauthorized'); // valami hogy mit cisnáljon
            break;
    }

    Alert.alert(titleTranslated, messageTranslated);
}