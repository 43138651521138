import * as React from 'react';

function SvgObUellKivalasztva(props) {
  return (
    <svg
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.462 7h1.104a.323.323 0 00.242-.11.333.333 0 00.082-.257l-.125-1.203a.222.222 0 00-.071-.142.216.216 0 00-.147-.056H11.48a.216.216 0 00-.146.056.222.222 0 00-.072.142l-.125 1.203a.334.334 0 00.193.338.322.322 0 00.133.028zm.155-2.652h.793a.215.215 0 00.163-.073.221.221 0 00.055-.171l-.068-.64h-1.093l-.067.64a.225.225 0 00.055.171.219.219 0 00.162.073zm1.766 7.023a.444.444 0 00-.142-.282.432.432 0 00-.291-.113h-1.873a.432.432 0 00-.291.113.444.444 0 00-.142.282l-.28 2.698h3.3l-.281-2.698zm-2.123-1.279h1.505a.43.43 0 00.323-.146.442.442 0 00.11-.342l-.138-1.326a.444.444 0 00-.141-.281.432.432 0 00-.291-.114h-1.229a.432.432 0 00-.291.113.444.444 0 00-.142.282l-.138 1.326a.449.449 0 00.11.342.436.436 0 00.323.146h-.001z"
        fill="url(#OBUell_kivalasztva_svg__paint0_linear)"
      />
      <path
        d="M19.776 12.817L15.971 3.98a.828.828 0 00-.754-.516H12.56l.067.64a.222.222 0 01-.129.225.215.215 0 01-.089.02h-.792a.215.215 0 01-.163-.074.22.22 0 01-.055-.171l.068-.64H8.81a.82.82 0 00-.454.144.838.838 0 00-.301.372L4.25 12.817c-.252.585.15 1.252.755 1.252h5.358l.28-2.698a.444.444 0 01.142-.282.432.432 0 01.291-.113h1.873c.107 0 .211.04.291.113.08.073.13.174.142.282l.28 2.698h5.358c.605 0 1.007-.667.755-1.252zm-8.639-6.184l.125-1.203a.222.222 0 01.072-.142.216.216 0 01.146-.056h1.07c.054 0 .106.02.146.056.04.037.066.087.071.142l.126 1.203a.334.334 0 01-.194.338.323.323 0 01-.133.028h-1.104a.323.323 0 01-.242-.11.331.331 0 01-.083-.256zm1.629 3.46h-1.505a.43.43 0 01-.324-.147.443.443 0 01-.109-.342l.138-1.326a.444.444 0 01.142-.282.432.432 0 01.291-.113h1.229c.107 0 .211.04.291.113.08.073.13.174.142.282l.138 1.326a.449.449 0 01-.11.342.435.435 0 01-.323.146z"
        fill="url(#OBUell_kivalasztva_svg__paint1_linear)"
      />
      <rect
        width={24}
        height={3.631}
        rx={1.815}
        fill="url(#OBUell_kivalasztva_svg__paint2_linear)"
      />
      <path
        d="M5.089 7.114L6.135 3.63H1.444v3.484h3.645zM18.73 7.114L17.685 3.63h4.692v3.484H18.73z"
        fill="#C4C4C4"
      />
      <defs>
        <linearGradient
          id="OBUell_kivalasztva_svg__paint0_linear"
          x1={10.364}
          y1={3.464}
          x2={17.382}
          y2={7.347}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CECECE" />
        </linearGradient>
        <linearGradient
          id="OBUell_kivalasztva_svg__paint1_linear"
          x1={4.175}
          y1={3.37}
          x2={20.52}
          y2={12.768}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3563BF" />
          <stop offset={1} stopColor="#052F84" />
        </linearGradient>
        <linearGradient
          id="OBUell_kivalasztva_svg__paint2_linear"
          x1={12}
          y1={0}
          x2={12}
          y2={3.631}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8B33" />
          <stop offset={1} stopColor="#D75E04" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgObUellKivalasztva;
