import React from 'react';
import {Animated, StyleSheet, TouchableOpacity, Text, Platform, View} from 'react-native';
import Colors from '../constants/Colors';
import { TollValue } from '../constants/Constants';
import { KeyForObjects } from '../constants/VehicleDetailsConstants';
import { getIcon } from '../constants/vehicle_details/Icons';

interface Props {
  id?: KeyForObjects;
  value: string | TollValue;
  icon?: any;
  iconOff?: any;
  iconError?: any;
  hasError?: boolean;
  tooltipText?: string;
  onPress?: () => void;
  selected?: boolean;
  selectedIcon?: any;
}

const Card: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const fadeAnim = React.useRef(new Animated.Value(1)).current;

  const fade = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      setVisible(!visible);

      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    });
  };

  const {id, icon, iconError, tooltipText, value, hasError, onPress, selected, selectedIcon} = props;

    if (id === 'toll_status') {
      const v = value as TollValue;

      const error = v.hasError;
      let Icon = <></>;

        if (error) {
          Icon = iconError({height: 20, width: 20});
        } else if (selected && selectedIcon) {
          Icon = selectedIcon({height: 20, width: 20});
        } else {
          Icon = icon({height: 20, width: 20});
        }

    return (
      <TouchableOpacity 
        onLongPress={() => {
          fade();
        }}
        onPress={onPress}
        disabled={onPress === undefined}
      >
      <Animated.View
        style={[styles.container, {opacity: fadeAnim}]}>
      {visible ? (
        <Text
          numberOfLines={1}
          style={{color: error ? '#CA0404' : Colors.primary}}
          >
          {tooltipText}
        </Text>
      ) : (
        Icon
      )}
      <Text style={[styles.valueText, {color: 'black'}]}>{v.value}</Text>
    </Animated.View>
    </TouchableOpacity>
    );
  }

  const Icon = getIcon(id, !!hasError, selected && selectedIcon);

  return (
    <TouchableOpacity 
    onLongPress={() => {
      fade();
    }}
    disabled={onPress === undefined}
    onPress={onPress}>
    <View
      // onTouchEnd={fade}
      style={[styles.container,]}>
      {visible ? (
        <Text
          numberOfLines={1}
          style={{color: hasError ? '#CA0404' : Colors.primary}}>
          {tooltipText}
        </Text>
      ) : 
          Icon
      }
      <Text 
        style={[styles.valueText, {color: 'black'}]}
        numberOfLines={3}
        >
          {value}
        </Text>
    </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  valueText: {
    textAlign: 'center',
  },
});

export default Card;
