import React from 'react';
import {createContainer} from 'unstated-next';
import AsyncStorage from '@react-native-community/async-storage';
import AllDataObject, {
	DefaultSettings,
	KeyForObjects,
	VehicleDetailsSetting,
	MapDefaultSettings,
	ListDefaultSettings, LicenseNoObject,
} from '../../constants/VehicleDetailsConstants';

export type Key =
	| 'map-vehicle-fields'
	| 'vehicle-fields'
	| 'list-vehicle-fields';

const getVehicleDetailsFields = async (key: Key) => {
	const data = await AsyncStorage.getItem(key);

	if (data) {
		const temp = JSON.parse(data) as VehicleDetailsSetting[];
		const t: VehicleDetailsSetting[] = [];
		temp
			.filter((o) => o.id !== 'license_no' && o.id !== 'address' && o.id !== 'last_event') // kivesszük, mert régi bétában benne volt még
			.forEach((o) => {
				/**
				 * egyszerűen nem foglalkozunk azzal a régi opcióval ami már nincs használatban
				 */
				const current = AllDataObject.get(o.id);
				if (current) {
					t.push(current);
				}
			});

		return t;
	}

	switch (key) {
		case 'list-vehicle-fields':
			return ListDefaultSettings;
		case 'map-vehicle-fields':
			return MapDefaultSettings;
		case 'vehicle-fields':
			return DefaultSettings;
	}
};

const writeDetailsFields = async (key: Key, data: VehicleDetailsSetting[]) => {
	await AsyncStorage.setItem(key, JSON.stringify(data));
};

const getShortField = async () => {
	const data = await AsyncStorage.getItem('shortField');
	if (data) {
		const temp = JSON.parse(data) as VehicleDetailsSetting;
		return temp;
	}

	return LicenseNoObject; // DEFAULT SHORT OBJECT
};

const writeShortField = async (data: VehicleDetailsSetting) => {
	await AsyncStorage.setItem('shortField', JSON.stringify(data));
};

export type mapType = 'standard' | 'satellite' | 'hybrid';

const writeFieldByKey = async (key: string, data: any) => {
	await AsyncStorage.setItem(key, JSON.stringify(data));
};

const getFieldByKey = async (key: string) => {
	const data = await AsyncStorage.getItem(key);
	if (data) {
		const t = JSON.parse(data);
		return t;
	}

	return undefined;
};

const useSettings = () => {
	const [mapVehicleDetailsFields, setMapVehicleDetailsFields] = React.useState<VehicleDetailsSetting[]>([]); // térképnézeten lévő fieldek
	const [vehicleDetailsFields, setVehicleDetailsFields] = React.useState<VehicleDetailsSetting[]>([]); // részletes gépjármű nézet fieldjei
	const [
		listVehicleDetailsFields,
		setListVehicleDetailsFields,
	] = React.useState<VehicleDetailsSetting[]>([]); // lista fieldjei

	const [shortByField, setShortByField] = React.useState<VehicleDetailsSetting>();

	const [mapVehicleLicenseNoShowOnMarker, setMapVehicleLicenseNoShowOnMarker] = React.useState<boolean>(true);
	const [routeAlignment, setRouteAlignment] = React.useState<boolean>(true); // útvonal illesztés
	const [counterForParking, setCounterForParking] = React.useState<boolean>(false); // Parkolásnál a vissza számláló menjen vagy csak sima text
	const [mapType, setMapType] = React.useState<mapType>('standard');

	React.useEffect(() => {
		const mapVehicleDetails = getVehicleDetailsFields('map-vehicle-fields');
		const vehicleDetails = getVehicleDetailsFields('vehicle-fields');
		const listVehicleDetails = getVehicleDetailsFields('list-vehicle-fields');

		mapVehicleDetails.then((settings) => {
			// @ts-ignore
			setMapVehicleDetailsFields(settings);
		});

		vehicleDetails.then((settings) => {
			// @ts-ignore
			setVehicleDetailsFields(settings);
		});

		listVehicleDetails.then((settings) => {
			// @ts-ignore
			setListVehicleDetailsFields(settings);
		});

		getShortField().then((field) => setShortByField(field));

		(async () => {
			const persisted = await getFieldByKey('route-alignment');
			if (persisted) {
				setRouteAlignment(persisted);
			}
		})();

	}, []);

	const writeVehicleDetailsFields = (
		key: Key,
		arrayWithKeys: KeyForObjects[],
	) => {
		const newFields: VehicleDetailsSetting[] = [];
		arrayWithKeys.forEach((key) => {
			const dataObject = AllDataObject.get(key);
			if (dataObject) {
				newFields.push(dataObject);
			}
		});

		switch (key) {
			case 'list-vehicle-fields':
				setListVehicleDetailsFields(newFields);
				break;
			case 'map-vehicle-fields':
				setMapVehicleDetailsFields(newFields);
				break;
			case 'vehicle-fields':
				setVehicleDetailsFields(newFields);
				break;
		}

		writeDetailsFields(key, newFields);
	};

	return {
		counterForParking,
		setCounterForParking,

		mapVehicleDetailsFields,
		vehicleDetailsFields,
		listVehicleDetailsFields,
		writeVehicleDetailsFields,
		mapVehicleLicenseNoShowOnMarker,
		setMapVehicleLicenseNoShowOnMarker,
		mapType,
		setMapType,
		shortByField,
		setShortByField: (f: VehicleDetailsSetting) => {
			setShortByField(f);
			writeShortField(f);
		},

		routeAlignment,
		setRouteAlignment: (val: boolean) => {
			setRouteAlignment(val);
			writeFieldByKey('route-alignment', val);
		},
	};
};

const SettingsContainer = createContainer(useSettings);

export default SettingsContainer;
