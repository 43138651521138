import React from 'react';
import {SafeAreaView, StyleSheet, View} from 'react-native';
import {Headline, Text, Checkbox, Divider, ActivityIndicator, Button} from 'react-native-paper';
import DispatcherContainer, {Group} from '../../containers/DispatcherContainer';
import {TouchableOpacity, ScrollView} from 'react-native-gesture-handler';
import Colors from '../../constants/Colors';
import {translate} from "../../components/hooks/useTranslate";

const VehicleGroup: React.FC = () => {
  const dispatcherContainer = DispatcherContainer.useContainer();
  const [checkedGroup, setCheckedGroup] = React.useState<Group | undefined>(() => // @ts-ignore 
  global.vehicleGroup);

  const setGroup = (group: Group) => {
      if (checkedGroup?.id === group.id) {
          setCheckedGroup(undefined);
          dispatcherContainer.setSelectedVehicleGroup();
      } else {
          setCheckedGroup(group);
          dispatcherContainer.setSelectedVehicleGroup(group);
      }
  }

  // // @ts-ignore
  // console.log({vehicleGroup: global.vehicleGroup})

  React.useEffect(() => {
    dispatcherContainer.fetch();
  }, []);

  if (dispatcherContainer.status === 'error') {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text>{translate('common.status.pls-try-again')}</Text>
        <Button onPress={() => dispatcherContainer.fetch()}>{translate('common.refresh')}</Button>
      </View>
    );
  }

  if (dispatcherContainer.status === 'loading') {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator />
        <Text>{translate('common.status.data-loading')}</Text>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <Headline style={styles.headText}>{translate('drawer.button.settings')}</Headline>
      <Text style={styles.secHeadText}>{translate('settings.vehicle-group')}</Text>
      <Text style={styles.sumCounter}></Text>

      <ScrollView style={{ width: '100%', height: '100%'}}>
      {dispatcherContainer?.vehicleGroups?.map((vGroup: Group) => {
        return (
          <View key={vGroup.id}>
            <Divider />
            <TouchableOpacity onPress={() => setGroup(vGroup)}>
              <View style={styles.row}>
                <Text style={styles.rowName}>{vGroup.name}</Text>
                <Checkbox
                  status={
                    checkedGroup?.id === vGroup.id ? 'checked' : 'unchecked'
                  }
                  // onPress={() => setGroup(vGroup)}
                  color={Colors.primary}
                  // uncheckedColor={'grey'}
                />
              </View>
            </TouchableOpacity>
          </View>
        );
      }) ?? 
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator />
        <Text>{translate('common.status.data-loading')}</Text>
      </View>
      }
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // marginHorizontal: 20,
  },
  headText: {
    fontSize: 26,
    fontWeight: '600',
    marginBottom: 5,
    marginTop: 10,
    paddingLeft: 20,
  },
  secHeadText: {
    fontSize: 16,
    fontWeight: '600',
    color: 'grey',
    marginBottom: 5,
    paddingLeft: 20,
  },
  sumCounter: {
    textAlign: 'right',
    fontSize: 20,
    paddingLeft: 20,
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    paddingHorizontal: 20,
  },
  rowName: {
    color: 'grey',
    fontSize: 18,
  },
});

export default VehicleGroup;
