import React, { ReactNode, useRef } from 'react';
import {
    TouchableOpacity,
    StyleSheet,
    Linking,
    Text,
    Image,
    ImageSourcePropType,
} from 'react-native';
import styled, { css } from 'styled-components';
import {Divider, TouchableRipple} from 'react-native-paper';

import { DrawerMenu, FleetLogo } from '../../components/icons/Icons';
import {Ifleet} from '../../assets/images/icons_components';
import { MenuItem } from '../../components/menu';
import Colors from '../../constants/Colors';
import { useReactiveVar } from '@apollo/client';
import { userVar } from '../../graphql/Client';

import iCellLogo from '../../assets/images/icell_logo_small_trans700x700.png';
import { logoutJWT } from '../../components/hooks/useJWTLogin.web';
import { translate } from '../../components/hooks/useTranslate';
import { Logout, RatingGreyIcon, RatingIcon, SettingsGreyIcon, UserTur } from '../../components/icons/Icons.web';
import { DrawerItem } from '@react-navigation/drawer';
import ScreenNames from '../ScreenNames';
import { useOutsideClick } from '../../components/hooks/useOutsideClick';
import { CenterDiv } from '../../components/Utils';
import { CategoryText } from '../menu/MenuScreen.web';
// const logo = Image.resolveAssetSource(iCellLogo as ImageSourcePropType).uri;

export const LogoTitle: React.FC = () => {
    // <Image source={Logo} style={styles.logo} resizeMode={'contain'} />
        // <FleetLogo height={35} width={35}/>
        return (
        // @ts-ignore
        <Ifleet height={35} width={35} style={{zIndex: 999}}/>
    );
}

export const HeaderRight: React.FC<{tintColor: any, navigation: any}> = ({tintColor, navigation}) => {
    return (
        <TouchableOpacity 
            style={headerRightStyle.container}
            onPress={() => {
                navigation.openDrawer();
            }}
        >
            {/*// @ts-ignore */}
            <DrawerMenu height={45} width={45} />
        </TouchableOpacity>
    );
}

const UserBadgeContainer = styled.div<{large?: boolean}>`
    width: ${props => props.large ? '69px' : '35px'};
    height: ${props => props.large ? '69px' : '35px'};
    font-size: ${props => props.large ? '32px' : '14px'};
    /* background-color: ${Colors.primary}; */
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;

    background: rgb(33,58,143);
    /* background: linear-gradient(138deg, rgba(33,58,143,1) 50%, rgba(9,37,121,1) 80%); */

    background: linear-gradient(111.25deg, #3563BF -0.25%, #052F84 99.63%);
`;

const UserBadge: React.FC<{chars: string, large?: boolean}> = ({chars, large}) => {
    return (
        <UserBadgeContainer large={large}>
            {chars}
        </UserBadgeContainer>
    );
}

/**
 * TODO céges adatok kellenek 
 * @param param0 
 * @returns 
 */
export const HeaderRightOnWeb: React.FC<{tintColor: any, navigation: any}> = ({tintColor, navigation}) => {
    const [isVisibleMenu, setVisibleMenu] = React.useReducer((state) => {return !state}, false);
    const {email, } = useReactiveVar(userVar);
    const menuRef = useRef();

    useOutsideClick(menuRef, () => {
        if (isVisibleMenu) {
            setVisibleMenu();
        }
    });

    return (
        <>
        <TouchableOpacity 
            style={[headerRightStyle.container, {paddingLeft: 20}]}
            onPress={() => {
                // navigation.openDrawer();
                setVisibleMenu();
            }}
        >
            {/*// @ts-ignore */}
            {/* <UserTur height={25} width={25} /> */}
            <UserBadge chars={`${email[0]}${email[1]}`} />
            
        </TouchableOpacity>
        { 
            isVisibleMenu && <WebMenu navigation={navigation} position={'right'}>
                {/*// @ts-ignore */}
                <div ref={menuRef}>
                    <CenterDiv>
                        <UserBadge chars={`${email[0]}${email[1]}`} large/>
                    </CenterDiv>
                <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // height: 200,
                        color: Colors.secondary,
                        padding: 10,
                    }}
                >
                    {email}
                </div>
                <MenuItem fun={'rate'} navigation={navigation} leftFloatMenuStyle/>
                <MenuItem fun={'settings'} navigation={navigation} leftFloatMenuStyle/>

                {/* <UserMenuItem 
                    color={'grey'} 
                    textKey={'drawer.button.rate'} 
                    icon={<RatingGreyIcon height={16} />}
                    onClick={() => navigation.navigate(ScreenNames.rate)}
                />
                <UserMenuItem 
                    color={'grey'} 
                    textKey={'drawer.button.settings'} 
                    icon={<SettingsGreyIcon height={16} />}
                    onClick={() => navigation.navigate(ScreenNames.settings.allSettings)}
                /> */}

                <Divider />
                {/* <UserMenuItem 
                    color={Colors.secondary} 
                    textKey={'drawer.button.logout'} 
                    icon={<Logout height={16} />}
                    onClick={() => logoutJWT()}
                /> */}
                <MenuItem fun={'logout'} navigation={navigation} leftFloatMenuStyle/>


                <Divider />
                <TouchableOpacity
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // height: 200,
                    }}
                    onPress={() => {
                        Linking.openURL('https://icell.hu');
                    }}>
                    <Image
                        source={{uri: iCellLogo}}
                        style={{
                            height: 80,
                            width: 80,
                        }}
                        resizeMode={'contain'}
                    />
                    <Text style={{color: 'grey'}}>i-Fleet by i-Cell Kft.</Text>
                </TouchableOpacity>
                </div>

            </WebMenu>
        }
        </>
    );
}

const UserMenuItem: React.FC<{textKey: string, icon: any, color: string, onClick: () => void}> = ({textKey, icon, color, onClick}) => {
    return (
        <TouchableRipple
            style={{borderRadius: 5}}
            onPress={onClick}>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} >
                {icon}
                <div style={{color, paddingLeft: 5, paddingTop: 10, paddingBottom: 10,}}>{translate(textKey)}</div>
            </div>
        </TouchableRipple>
    );
}

export const HeaderLeftOnWeb: React.FC<{tintColor: any, navigation: any}> = ({tintColor, navigation}) => {
    const [isVisibleMenu, setVisibleMenu] = React.useState(false);
    const menuRef = useRef();
    const user = useReactiveVar(userVar);
    
    useOutsideClick(menuRef, () => {
        if (isVisibleMenu) {
            setVisibleMenu(v => !v);
        }
    });

    return (
        <>
        <TouchableOpacity 
            style={[headerRightStyle.container, {paddingLeft: 20}]}
            onPress={() => {
                // navigation.openDrawer();
                setVisibleMenu((v) => !v);
            }}
        >
            {/*// @ts-ignore */}
            <DrawerMenu height={45} width={45} style={{transform: 'scaleX(-1)'}}/>
        </TouchableOpacity>
        {
            isVisibleMenu && <WebMenu navigation={navigation} position={'left'}>
                {/*// @ts-ignore */}
                <div ref={menuRef}>
                    {/* <button onClick={() => navigation.goBack()}>vissza</button> */}
                    <MenuItem fun={'menu'} navigation={navigation} leftFloatMenuStyle/>
                    <CategoryText fontSize={12}>Kedvencek</CategoryText>
                    <MenuItem fun={'fleetstate'} navigation={navigation} leftFloatMenuStyle  disabled={user.dispId === 0}/>
                    <MenuItem fun={'route'} navigation={navigation} leftFloatMenuStyle disabled/>
                    <MenuItem fun={'obu'} navigation={navigation} leftFloatMenuStyle disabled/>
                    <CategoryText fontSize={12}>Adminisztráció</CategoryText>
                    <MenuItem fun={'bug-report'} navigation={navigation} leftFloatMenuStyle disabled/>

                </div>
                
            </WebMenu>
        }
        </>
    );
}

const WebMenu: React.FC<{navigation: any, position: WebMenuPosition, children?: ReactNode}> = ({navigation, position, children}) => {
    return (
        <WebMenuContainer position={position}>
            {children}
        </WebMenuContainer>
    );
}

const headerRightStyle = StyleSheet.create({
    container: {
        height: '100%',
        width: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 20,
    },
});

// const headerLeftStyle = StyleSheet.create({
//     container: {
//         height: '100%',
//         width: 50,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         paddingRight: 20,
//     },
// });

type WebMenuPosition = 'right' | 'left';

const WebMenuContainer = styled.div<{position: WebMenuPosition}>`
    position: absolute;
    ${props => props.position === 'left' ? css`left: 20px;` : css`right: 20px;`}
    top: 35px;
    background-color: ${Colors.background};
    box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
    padding: 10px;
    border-radius: 10px;
`;