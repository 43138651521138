import { sendEvent } from './EventContainer.web';
// import analytics from '@react-native-firebase/analytics';
import React from 'react';
// import crashlytics from '@react-native-firebase/crashlytics';
// import messaging from '@react-native-firebase/messaging';
import axios from 'axios';
import {APIURL} from '@env';
import {createContainer} from 'unstated-next';
import { VERSION } from '../constants/Constants.web';
// import { Status, getLoginInfo } from './LoginContainer.web';
// import SInfo from 'react-native-sensitive-info';

export enum RateStatus {
  rated = 'rated',
  later = 'later',
  notRate = 'notRate',
}

const useRating = () => {
  const [state, setState] = React.useState<any>();
  const [error, setError] = React.useState<number | undefined>();

  const saveRate = async (rate: {
    message: string;
    rate: number;
    status: RateStatus;

    speedProblem: boolean;
    limitedData: boolean;
    application: boolean;
    someProblem: boolean;
  }) => {
    try {
      setState('loading');
      // const token = await messaging().getToken();
      const token = undefined;

      if (!token) {
        setState('error');
        setError(1); // NO TOKEN
      }

      // const loginRaw = await SInfo.getItem('login', {});
      // const loginData = JSON.parse(loginRaw);

      const {status} = await axios.post(
        `${APIURL}/admin/rating`,
        {
          ...rate,
          fcmToken: token,
          // companyCode: loginData?.companyCode,
          // userName: loginData?.userName,
          version: VERSION,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 201) {
        setState('success');
      } else {
        setState('error');
        setError(status);
        sendEvent('error', {
          function: 'rate-save',
          status,
        });
      }
    } catch (saveRateExp) {
      console.error({saveRateExp});
      // crashlytics().log(
      //   `Rate save exception, exp: ${JSON.stringify(saveRateExp)}`,
      // );
      // analytics().logEvent('rate-save-error', {
      //   exception: saveRateExp,
      // });
      sendEvent('error', {
        function: 'rate-save',
        exception: saveRateExp,
      });
      if (saveRateExp.response) {
        setError(saveRateExp.response.status);
      } else {
        setError(0);
      }

      setState('error');
    }
  };

  return {state, saveRate, error};
};

const RatingContainer = createContainer(useRating);

export default RatingContainer;
