import * as React from 'react';
import * as RNLocalize from 'react-native-localize';
import i18n from 'i18n-js';
import memoize from 'lodash.memoize';
import {useContext} from 'react';
import AsyncStorage from '@react-native-community/async-storage';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/hu';

import hu from '../../assets/translations/hu.json';
import en from '../../assets/translations/en.json';
import de from '../../assets/translations/de.json';

/**
 * Thread 8: "Unhandled JS Exception: Error: Requiring unknown module \"./locale/de\"., stack:\nv@2:1305\nd@2:875\nvt@1119:16827\nSt@1119:17863\nkt@1119:16926\nP@1121:1048\n<unknown>@1121:1305\nonPress@1051:8010\nvalue@228:7659\nvalue@228:6915\nonResponderRelease@228:5669\np@98:384\nb@98:527\nT@98:581\nw@98:876\nke@98:12621\nforEach@(null):(null)\n_@98:2057\n<unknown>@98:12968\nxe@98:89817\nEe@98:12419\nRe@98:12808\nreceiveTouches@98:13600\nvalue@27:3350\n<unknown>@27:747\nvalue@27:2610\nvalue@27:719\nvalue@(null):(null)\n"
 *
 */

export const translationGetters = {
  // hu: () => require('../assets/translations/hu.json'),
  // en: () => require('../assets/translations/en.json'),
  // de: () => require('../assets/translations/de.json'),
  hu: () => hu,
  en: () => en,
  de: () => de,
};

export type AvailableLanguages = 'hu' | 'en' | 'de';

export const translate = memoize(
  (key, config?: any) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key),
);

export const getDefaultLanguage = (): AvailableLanguages => {
  const fallback = {languageTag: 'en'};
  const {languageTag} =
    RNLocalize.findBestAvailableLanguage(Object.keys(translationGetters)) ||
    fallback;
  // @ts-ignore
  return languageTag;
};

/**
 * Ha nincs eltárolva semmilyen nyelv akkor a készülék / böngésző nyelvét használjuk.
 */
const getActiveLanguage = async (): Promise<AvailableLanguages> => {
  // TODO: vissza komizni ha már le lett fordítva a nyelvek.
  // const savedLang = await getSavedLanguage();

  // if (savedLang && (savedLang === 'hu' || savedLang === 'en' || savedLang === 'de')) {
  //   return savedLang;
  // }

  // return getDefaultLanguage();
  return 'hu';
}

export const setI18nConfig = async () => {
  // const languageTag = getDefaultLanguage();
  const languageTag = await getActiveLanguage();

  translate?.cache?.clear?.();
  i18n.translations = { [languageTag]: translationGetters[languageTag]() };
  i18n.locale = languageTag;

  setLanguage(languageTag);

  i18n.missingBehaviour = 'guess';
  i18n.missingTranslationPrefix = 'NK: ';
};

const getSavedLanguage = () => {
  return AsyncStorage.getItem('language');
}

export const setLanguage = (lang: AvailableLanguages) => {
  translate?.cache?.clear?.();
  i18n.translations = {[lang]: translationGetters[lang]()};
  i18n.locale = lang;

  if (lang === 'en') {
    moment.locale('en-gb');
  } else {
    moment.locale(lang);
  }

  AsyncStorage.setItem('language', lang);
};

const defaultState = {
  lang: getDefaultLanguage(),
  setLang: (arg: AvailableLanguages) => {},
};

export const LanguageContext = React.createContext(defaultState);

export const useLanguage = () => {
  const [lang, setLang] = React.useState<AvailableLanguages>(getDefaultLanguage);

  React.useEffect(() => {
    // setLanguage(lang);
    // console.warn({lang});
  }, [lang]);

  const LANG = (arg: AvailableLanguages) => {
    setLanguage(arg);
    setLang(arg);
  };

  return [lang, LANG];
};

export const LangProvider: React.FC = ({children}: any) => {
  const [lang, setLang] = useLanguage();

  React.useEffect(() => {
    AsyncStorage.getItem('language').then((l) => {
      // console.warn({l, defaultLang: getDefaultLanguage(), lang});
      if (l === 'hu' || l === 'en' || l === 'de') {
        // @ts-ignore
        setLang(l);
      } else {
        // @ts-ignore
        setLang(getDefaultLanguage());
      }
    });
  }, []);

  return (
    // @ts-ignore
    <LanguageContext.Provider value={{lang, setLang}}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLangContext = () => {
  const state = useContext(LanguageContext);
  return [state.lang, state.setLang];
};
