import * as React from 'react';

function SvgKijelentkezesFixNarancs(props) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#Kijelentkezes_fix_narancs_svg__clip0)">
        <path
          opacity={0.4}
          d="M7.031 17.5H3.75A3.75 3.75 0 010 13.75v-7.5A3.75 3.75 0 013.75 2.5h3.281a.469.469 0 01.469.469V4.53A.469.469 0 017.031 5H3.75A1.25 1.25 0 002.5 6.25v7.5A1.25 1.25 0 003.75 15h3.281a.469.469 0 01.469.469v1.562a.469.469 0 01-.469.469z"
          fill="url(#Kijelentkezes_fix_narancs_svg__paint0_linear)"
        />
        <path
          d="M13.789 3.45l5.934 5.882a.936.936 0 010 1.332l-5.938 5.891a.94.94 0 01-1.324-.004l-.856-.855a.94.94 0 01.032-1.356l3.031-2.777h-7.48a.936.936 0 01-.938-.938v-1.25a.935.935 0 01.937-.937h7.481L11.637 5.66a.937.937 0 01-.028-1.352l.856-.856a.938.938 0 011.324-.003z"
          fill="url(#Kijelentkezes_fix_narancs_svg__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="Kijelentkezes_fix_narancs_svg__paint0_linear"
          x1={3.75}
          y1={2.5}
          x2={3.75}
          y2={17.5}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8B33" />
          <stop offset={1} stopColor="#D75E04" />
        </linearGradient>
        <linearGradient
          id="Kijelentkezes_fix_narancs_svg__paint1_linear"
          x1={13.125}
          y1={3.178}
          x2={13.125}
          y2={16.826}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8B33" />
          <stop offset={1} stopColor="#D75E04" />
        </linearGradient>
        <clipPath id="Kijelentkezes_fix_narancs_svg__clip0">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgKijelentkezesFixNarancs;
