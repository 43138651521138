import * as React from 'react';
import { LoginScreen } from '../screens/LoginScreenGraphQL.web';
import { Background } from './components/login/Background';
import ParticlesParamsLight from './components/login/particles/ParticlesLightConfig';

export const Login = () => {
    return (
        <div style={{
            display: 'flex',
            height: '100vh',
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: -2,
            width: '100vw',
        }}>
            <Background params={ParticlesParamsLight} dark={false} />
            <LoginScreen />
        </div>
    );
}