import {Vehicle} from '../../containers/FleetstateContainer';
import {AnalogsFields} from '../../containers/route/RouteEntity';
import {FunctionType} from '../VehicleDetailsConstants';

const getTempatureByKey = (object: Vehicle, key: AnalogsFields) => {
  const indexOffTempature = object.signal_means.analogs.findIndex(
    v => v === key,
  );

  return `${object.analogs?.[indexOffTempature]?.toFixed(0) ?? '-'} °C`;
};

export const TempatureFrontObject: FunctionType = {
    id: AnalogsFields.TempFront,
    path: '.',
    type: 'function',
    tooltipText: 'Temp front',
    callFunction: (object: Vehicle) => getTempatureByKey(object, AnalogsFields.TempFront),
};

export const TempatureMiddleObject: FunctionType = {
  id: AnalogsFields.TempMiddle,
  path: '.',
  type: 'function',
  tooltipText: 'Temp middle',
  callFunction: (object: Vehicle) => getTempatureByKey(object, AnalogsFields.TempMiddle),
};

export const TempatureRearObject: FunctionType = {
    id: AnalogsFields.TempRear,
    path: '.',
    type: 'function',
    tooltipText: 'Temp rear',
    callFunction: (object: Vehicle) => getTempatureByKey(object, AnalogsFields.TempRear),
};

export default [
    TempatureFrontObject,
    TempatureMiddleObject,
    TempatureRearObject,
];