import * as React from 'react';

function SvgMenuGomb(props) {
  return (
    <svg
      viewBox="0 0 75 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M48 19a1 1 0 11-2 0 1 1 0 012 0zM57 19a1 1 0 11-2 0 1 1 0 012 0z"
        fill="#003194"
      />
      <path d="M47 19h9" stroke="#003194" strokeWidth={2} />
      <path
        d="M38 26a1 1 0 11-2 0 1 1 0 012 0zM57 26a1 1 0 11-2 0 1 1 0 012 0z"
        fill="#003194"
      />
      <path d="M37 26h19" stroke="#003194" strokeWidth={2} />
      <path
        d="M43 33a1 1 0 11-2 0 1 1 0 012 0zM57 33a1 1 0 11-2 0 1 1 0 012 0z"
        fill="#003194"
      />
      <path d="M42 33h14" stroke="#003194" strokeWidth={2} />
    </svg>
  );
}

export default SvgMenuGomb;
