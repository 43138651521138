import React from 'react';
import {SafeAreaView, View, StyleSheet, Alert} from 'react-native';
import SettingsContainer, {
  Key,
} from '../../containers/settings/SettingsContainer';
import {
  VehicleDetailsSetting,
  AllDataObjectArray,
  KeyForObjects,
  MaxFieldCountOnMapVehicleDetails,
  MaxFieldCountOnVehicleDetails,
  MaxFieldCountOnListVehicleDetails,
} from '../../constants/VehicleDetailsConstants';
import {Text, Headline, Divider} from 'react-native-paper';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Colors from '../../constants/Colors';
import {translate} from "../../components/hooks/useTranslate";

interface Props {
  route: {
    params: {
      type: Key;
    }
  };
}

const getMaxFieldCount = (key: Key) => {
  switch (key) {
    case 'list-vehicle-fields':
      return MaxFieldCountOnListVehicleDetails;
    case 'map-vehicle-fields':
      return MaxFieldCountOnMapVehicleDetails;
    case 'vehicle-fields':
      return MaxFieldCountOnVehicleDetails;
  }
};

// const getSettingsName = (key: Key) => {
//   switch (key) {
//     case 'list-vehicle-fields':
//       return 'Lista oszlopai';
//     case 'map-vehicle-fields':
//       return 'Jármű adatok (térkép)';
//     case 'vehicle-fields':
//       return 'Jármű adatok (mai nap)';
//   }
// };

const VehicleDetailsSettings: React.FC<Props> = (props) => {
  const settingsContainer = SettingsContainer.useContainer();

  const [allFields] = React.useState<VehicleDetailsSetting[]>(
    AllDataObjectArray,
  );

  // console.warn(JSON.stringify(allFields));

  const [selectedFields, setSelectedFields] = React.useState<KeyForObjects[]>(
    [],
  );

  React.useEffect(() => {
    const setCurrentFields = (key: Key) => {
      switch (key) {
        case 'list-vehicle-fields':
          return settingsContainer.listVehicleDetailsFields.map((o) => o.id);
        case 'map-vehicle-fields':
          return settingsContainer.mapVehicleDetailsFields.map((o) => o?.id);
        case 'vehicle-fields':
          return settingsContainer.vehicleDetailsFields.map((o) => o.id);
      }
    };

    const currentFields = setCurrentFields(props.route.params.type);

    setSelectedFields(currentFields);

    // console.log({
    //   key: props.route.params,
    //   current: currentFields,
    //   settingsContainer: settingsContainer.vehicleDetailsFields,
    // });
  }, [props.route.params]);

  //   React.useEffect(() => {
  //     settingsContainer.writeVehicleDetailsFields(
  //       props.route.params,
  //       selectedFields,
  //     );

  //     console.log({key: props.route.params})
  //   }, [selectedFields]);

  const setField = (key: KeyForObjects) => {
    const index = selectedFields.findIndex((v) => v === key);

    if (index === -1) {
      // akkor még nincs a listában
      if (selectedFields.length + 1 <= getMaxFieldCount(props.route.params.type)) {
        const temp = [...selectedFields, key];
        settingsContainer.writeVehicleDetailsFields(props.route.params.type, temp);
        setSelectedFields(temp);
      } else {
        Alert.alert(translate('settings.status.count-limit'));
      }
    } else {
      const temp = [...selectedFields];
      temp.splice(index, 1);
      settingsContainer.writeVehicleDetailsFields(props.route.params.type, temp);
      setSelectedFields(temp);
    }
  };

  const isLicenseFieldFix = props.route.params.type === 'list-vehicle-fields';

  return (
    <SafeAreaView style={styles.container}>
      <Headline style={styles.headText}>{translate('drawer.button.settings')}</Headline>
      <Text style={styles.secHeadText}>
        {translate(`settings.header.${props.route.params.type}`)}
      </Text>
      <Text style={styles.sumCounter}>
        <Text style={styles.selectedFieldsCount}>{selectedFields?.length ?? 0}</Text>
        {' / '}
        <Text style={styles.maxFieldsCount}>{getMaxFieldCount(props.route.params.type)}</Text>
      </Text>

      {allFields.map((obj) => {
        const index = selectedFields.findIndex((v) => v === obj.id);

        const isLicense = obj.id === 'license_no';

        if (props.route.params.type === 'list-vehicle-fields' && obj.id === 'daily_max_speed') {
          return <></>;
        }

        return (
          <View key={`${obj.id}-${props.route.params}`}>
            <Divider />
            <TouchableOpacity
              onPress={() => setField(obj.id)}
              disabled={isLicense && isLicenseFieldFix}>
              <View style={styles.row}>
                <Text style={styles.rowName}>{translate(`fleet.vehicle-details.fields.${obj.id}`)}</Text>
                {index === -1 ? <></> : <Text style={styles.rowIndex}>{index + 1}</Text>}
              </View>
            </TouchableOpacity>
          </View>
        );
      })}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
  },
  headText: {
    fontSize: 26,
    fontWeight: '600',
    marginBottom: 5,
    marginTop: 10,
  },
  secHeadText: {
    fontSize: 16,
    fontWeight: '600',
    color: 'grey',
    marginBottom: 5,
  },
  sumCounter: {
    textAlign: 'right',
    fontSize: 20,
    marginBottom: 10,
  },
  selectedFieldsCount: {
    textAlign: 'right',
    color: Colors.secondary,
  },
  maxFieldsCount: {
    textAlign: 'right',
    color: Colors.primary,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  rowName: {
    color: 'grey',
    fontSize: 18,
  },
  rowIndex: {
    color: Colors.secondary,
    borderColor: Colors.primary,
    borderWidth: 1,
    paddingHorizontal: 5,
    borderRadius: 5,
  }
});

export default VehicleDetailsSettings;
