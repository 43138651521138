import {VehicleDetailsSetting, NumberType, DateType, BooleanType, FunctionType} from '../../constants/VehicleDetailsConstants';
import * as _ from 'lodash';
import moment from 'moment';

export const getVehicleData = (
	object: any,
	props: any,
	element: VehicleDetailsSetting,
	removeSuffix?: boolean,
	) => {

	switch (element.type) {
		case 'number':
			const n = element as NumberType;
			let number: number | string = +_.get(object, element.path);
			if (_.isNumber(n.multiplication)) {
				number *= n.multiplication;
			}

			if (_.isNumber(n.fixed)) {
				number = +number.toFixed(n.fixed);
			}

			if (_.isNaN(number)) {
				if (n.isNullText) {
					number = n.isNullText;
				}
			}

			let val;

			if (n.suffix && !removeSuffix) {
				val = `${number} ${n.suffix}`;
			} else {
				val = `${number}`;
			}

			return {
				value: val,
			};

		case 'date':
			const d = element as DateType;

			return {
				value: moment(_.get(object, d.path)).format(d.format),
			}

		case 'boolean':
			const v = element as BooleanType;

			return {
				value: !!_.get(object, element.path) ? v.trueValue : v.falseValue,
			}

		case 'function':
			const f = element as FunctionType;
			let va = '';
			if (element.propsPath) {
				va = _.get(props, element.propsPath);
			} else {
				va = _.get(object, element.path, object);
			}

			if (typeof f?.callFunction === 'function') {
				return {
					value: f.callFunction(va),
				};
			} else {
				return {
					value: f.id
				}
			}

		default:
			return {
				value: _.get(object, element.path, '-')
			};
	}
};