import { Vehicle } from '../FleetstateContainer';

export interface GreendrivingEvent {
    score: number;
    type: string;
  }

  export interface OOOLDGC {
    n: string;
    d: number;
    h: number;
  }

  export interface GC {
    country?: string;
    city?: OOOLDGC;
    road?: OOOLDGC;
    poi: any[];
    poly: any[];
    closestPoi?: any;
  }
  export interface Route {
    fix: boolean;
    ign: boolean;
    head: number;
    lat: number;
    lon: number;
    speed: number;
    t: string;
    geocode: GC;

    greendrivings?: GreendrivingEvent[];

    digitals?: boolean[];
    analogs?: number[];

    total_dist: number;
  }

  export interface GreendrivingEventCounts {
    harsh_accel?: number;
    harsh_brake?: number;
    harsh_corner?: number;
    cold_rpm?: number;
    high_rpm?: number;
    idling?: number;
    [key: string]: any; // Add index signature
  }

  export interface Greendriving {
    event_counts: GreendrivingEventCounts;
    score: number;
    dist: number;
    ign_on_time: number;
  }

  /**
   * TODO: majd elkérni a pontos listát.
   */
  export type digitals = 'bal_ajto' | 'braking_g' | 'gyorsulas_g' | 'left_lane_departure' | 'pedestrian' | 'pedestrian_warning' | 'headway_warning_1' | 'headway_warning_2';
  // export type analogs = "temp"
  //   | "temp_front"
  //   | "temp_middle"
  //   | "temp_rear"
  //   //
  //   | "freezer_temp_front"
  //   | "freezer_temp_rear"
  //   //
  //   | "battery"
  //   | "fms_accel_pedal"
  //   | "gsm_signal"
  //   | "gps_radius"
  //   //
  //   | "raw_fuel_level"
  //   | "left_fuel_level"
  //   | "right_fuel_level"
  //   | "fuel_level";

  export enum AnalogsFields {
    Temp = 'temp',
    TempFront = 'temp_front',
    TempMiddle = 'temp_middle',
    TempRear = 'temp_rear',
    //
    FreezerTempFront = 'freezer_temp_front',
    FreezerTempRear = 'freezer_temp_rear',
    //
    Battery = 'battery',
    FmsAccelPedal = 'fms_accel_pedal',
    GsmSignal = 'gsm_signal',
    GpsRadius = 'gps_radius',
    //
    RawFuelLevel = 'raw_fuel_level',
    LeftFuelLevel = 'left_fuel_level',
    RightFuelLevel = 'right_fuel_level',
    FuelLevel = 'fuel_level',
  }
  export type analogTypes = 'temp' | 'voltage' | 'volume';
  export interface VehicleData extends Vehicle {
    last_duration?: Route[];
    greendriving: Greendriving;
    max_speed: number;
  }
