import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import {StyleSheet} from 'react-native';
import {IconButton, Searchbar} from 'react-native-paper';
import Colors from "../../constants/Colors";
import ScreenNames from '../../screens/ScreenNames';
import { navigate } from '../RootNavigation';
import { SearchComponentProps, SearchContentProps, } from "./VehicleList";

export const useMotiAnimationState = () => {
    return undefined;
}

const SearchContent: React.FC<SearchContentProps> = ({searchbarPlaceHolder, search, setSearch}) => {
    return (
        <Searchbar
            style={searchComponentStyles.bar}
            inputStyle={{
                fontSize: 14,
            }}
            placeholder={searchbarPlaceHolder}
            value={search}
            onChangeText={(text) => setSearch(text)}
            autoCapitalize={'none'}
            spellCheck={false}
            autoCorrect={false}
            icon={({ size, color }) => (
                <FontAwesomeIcon
                    icon={['fal', 'search']}
                      // style={{ width: size, height: size, tintColor: color }}
                      color={color}
                      size={18}
                />
              )}
            clearIcon={'times'}
        />
    );
}

const searchComponentStyles = StyleSheet.create({
    bar: {
        height: 20,
        width: '80%',
        marginTop: 20,
        marginBottom: 20,
        shadowOpacity: 0,
        elevation: 0,
        backgroundColor: 'transparent',
    }
});

export const SearchComponent: React.FC<SearchComponentProps> = ({animateState, setOpened, ...props}) => {

    return (<div style={{
        display: 'flex',
        alignItems: 'center',
    }}>
        <SearchContent {...props} />
        <div style={{
            display: 'flex'
        }}>
            <IconButton
                // style={styles.fab}
                style={{
                    backgroundColor: 'lightgrey'
                }}
                size={16}
                icon={'sort-amount-down-alt'}
                color={Colors.primary}
                onPress={() => {
                // props?.setVehicle?.(undefined);
                    navigate(ScreenNames.fleetState.vehicleListShort, undefined);
                }}
            />

            <IconButton
                // style={styles.fab}
                style={{
                    backgroundColor: 'lightgrey'
                }}
                size={16}
                icon={'times'}
                color={Colors.primary}
                onPress={() => {
                    // props?.setVehicle?.(undefined);
                    setOpened?.(false);
                }}
            />		
        </div>
    </div>);
}