import * as React from 'react';
import styled from 'styled-components';
import Particles from 'react-particles-js';

export const Background: any = styled(Particles) <any>`
    z-index: -1;
    background-color: ${props => props.dark ? '#373737' : '#f0f0f0'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    transition: ease-in-out 1s;

    display: flex;
    justify-content: center;
    align-items: center;
`;