import * as React from 'react';

function SvgTestreszabasKivalasztva(props) {
  return (
    <svg
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#Testreszabas_kivalasztva_svg__clip0)">
        <path
          d="M16.071 1.286H1.93A1.929 1.929 0 000 3.214v14.143a1.929 1.929 0 001.929 1.929H16.07A1.928 1.928 0 0018 17.357V3.214a1.929 1.929 0 00-1.929-1.928zM8.036 9H7.07v7.232a.482.482 0 01-.482.482H4.982a.482.482 0 01-.482-.482V9h-.964a.962.962 0 01-.965-.964v-.643a.962.962 0 01.965-.965H4.5V4.34a.482.482 0 01.482-.482H6.59a.482.482 0 01.482.482v2.09h.965A.962.962 0 019 7.393v.643A.962.962 0 018.036 9zm7.393 4.178a.961.961 0 01-.965.965H13.5v2.09a.482.482 0 01-.482.481H11.41a.482.482 0 01-.482-.482v-2.09h-.965A.961.961 0 019 13.18v-.643a.961.961 0 01.964-.965h.965V4.34a.482.482 0 01.482-.482h1.607a.482.482 0 01.482.482v7.232h.964a.962.962 0 01.965.965v.643z"
          fill="url(#Testreszabas_kivalasztva_svg__paint0_linear)"
        />
        <path
          d="M9 7.393a.962.962 0 00-.964-.964h-4.5a.962.962 0 00-.965.964v.643A.962.962 0 003.536 9h4.5A.962.962 0 009 8.036v-.643zm5.464 4.179h-4.5a.963.963 0 00-.964.964v.643a.962.962 0 00.964.964h4.5a.962.962 0 00.965-.964v-.643a.96.96 0 00-.965-.964z"
          fill="url(#Testreszabas_kivalasztva_svg__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="Testreszabas_kivalasztva_svg__paint0_linear"
          x1={0}
          y1={1.126}
          x2={21.689}
          y2={9.56}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3563BF" />
          <stop offset={1} stopColor="#052F84" />
        </linearGradient>
        <linearGradient
          id="Testreszabas_kivalasztva_svg__paint1_linear"
          x1={9}
          y1={6.429}
          x2={9}
          y2={14.143}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8B33" />
          <stop offset={1} stopColor="#D75E04" />
        </linearGradient>
        <clipPath id="Testreszabas_kivalasztva_svg__clip0">
          <path fill="#fff" d="M0 0h18v20.571H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTestreszabasKivalasztva;
