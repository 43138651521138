import * as React from 'react';

function SvgHugoPiros(props) {
  return (
    <svg
      viewBox="0 0 290 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M140.704 64.5h8.589c2.925 0 5.211-3.11 4.899-6.682l-1.888-21.96c-.176-2.05-1.586-3.608-3.268-3.608h-8.08c-1.682 0-3.092 1.557-3.268 3.608l-1.888 21.96c-.307 3.573 1.979 6.682 4.904 6.682zm-3.031 56.438h14.651c3.902 0 6.943-4.148 6.535-8.91l-2.079-24.187c-.353-4.102-3.172-7.216-6.535-7.216h-10.488c-3.363 0-6.182 3.114-6.535 7.216l-2.079 24.187c-.413 4.762 2.628 8.91 6.53 8.91zm5.367-104.813h3.922c1.954 0 3.474-2.076 3.268-4.454l-.695-8.063C149.359 1.558 147.949 0 146.267 0h-2.532c-1.682 0-3.091 1.557-3.268 3.608l-.695 8.063c-.206 2.378 1.315 4.454 3.268 4.454zm12.058 120.937h-20.199c-3.363 0-6.183 3.115-6.535 7.216l-3.469 40.313c-.408 4.762 2.633 8.909 6.535 8.909h27.132c3.902 0 6.943-4.147 6.535-8.909l-3.469-40.313c-.347-4.101-3.167-7.216-6.53-7.216zM79.22 0a4.02 4.02 0 00-3.716 2.48L.628 182.337c-2.21 5.307 1.687 11.162 7.436 11.162h14.097a8.054 8.054 0 007.734-5.805L83.087 5.16C83.837 2.58 81.903 0 79.22 0zm210.149 182.338L214.498 2.479A4.032 4.032 0 00210.782 0c-2.683 0-4.617 2.58-3.866 5.16l53.191 182.535a8.052 8.052 0 007.734 5.805h14.097c5.745 0 9.646-5.855 7.431-11.162z"
        fill="#CA0404"
      />
    </svg>
  );
}

export default SvgHugoPiros;
