import {useReactiveVar} from '@apollo/client';
import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {Pressable, SafeAreaView} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import { TouchableRipple } from 'react-native-paper';
import styled, { css } from 'styled-components';
import {MenuItem} from '../../components/menu';
import Colors from '../../constants/Colors';
import {getToken} from '../../constants/Constants';
import {userVar} from '../../graphql/Client';

export type PressableState = Readonly<{
  pressed: boolean;
  hovered?: boolean;
  focused?: boolean;
}>;

export const MenuScreen: React.FC = () => {
  const user = useReactiveVar(userVar);
  const navigation = useNavigation();

  const [fcmToken, setFCMToken] = React.useState('');

  React.useEffect(() => {
    (async () => {
      const token = await getToken();

      setFCMToken(token);
    })();
  }, []);

  return (
    <Container>
      <h1 style={{color: '#373737', fontWeight: 600}}>iFleet Szolgáltatás Központ</h1>
      <Category>
        <CategoryText>Követés</CategoryText>
        <CategoryItems>
          <MenuItem navigation={navigation} fun={'fleetstate'} disabled={user.dispId === 0}/>
          <MenuItem navigation={navigation} fun={'route'} disabled/>
          <MenuItem navigation={navigation} fun={'obu'} disabled/>
          {/* <MenuItem navigation={navigation} fun={'parking'} disabled/> */}
          {/* {user.functions?.map((func) => {
            return <MenuItem navigation={navigation} fun={func} />;
          })} */}
        </CategoryItems>
      </Category>
      <Category>
        <CategoryText>
          Adminisztráció
        </CategoryText>
        <CategoryItems>
          <MenuItem navigation={navigation} fun={'e-obu-registration'} disabled />
          <MenuItem navigation={navigation} fun={'bug-report'} disabled/>
        </CategoryItems>
      </Category>
      <Category>
        <CategoryText>
          Információ
        </CategoryText>
        <CategoryItems>
          <MenuItem navigation={navigation} fun={'service-prices'} disabled />
          <MenuItem navigation={navigation} fun={'contacts'} />
        </CategoryItems>
      </Category>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;

  padding: 20px;

  background-color: ${Colors.background};
`;

export const Category = styled.div``;

export const CategoryItems = styled.div`
  display: flex;

  > * + * {
    margin-left: 10px;
  }
`;

export const CategoryText = styled.p<{fontSize?: number}>`
  text-transform: uppercase;
  color: #7b7b7b;
  font-weight: bold;
  ${props => props.fontSize && css`font-size: ${props.fontSize}px !important;`}
`;
