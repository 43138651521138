import { Vehicle, TollState, Drivers } from "../containers/FleetstateContainer";
// import messaging from '@react-native-firebase/messaging';
import {Dimensions} from 'react-native';
import {translate} from "../components/hooks/useTranslate";
import { getBuildId, getSystemName, getSystemVersion } from "react-native-device-info";

export const VERSION = '1.6.0 BÉTA';

export const DeviceType = async () => {
    return `${getSystemName()} ${getSystemVersion()} ${await getBuildId()}` ?? " asd ";
} 

export const DeviceScreen = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
};

export const getToken = async () => {
    // return await messaging().getToken();
    return ''; // TODO
}

export const getActualDriverName = (drivers: Drivers[]) => {
    return drivers?.find((driver: any) => driver.state)?.name ?? translate('fleet.vehicle-details.fields.no-driver');
}

export const getActualDriver = (vehicle: Vehicle) => {
    return vehicle?.drivers?.find((driver: any) => driver.state) ?? {name: translate('fleet.vehicle-details.fields.no_driver'), logintime: undefined};
}

export const BudapestCoords = {
    latitude: 47.491321,
    longitude: 19.054278,
    latitudeDelta: 0.1,
    longitudeDelta: 0.1,
}

export interface TollValue {
    hasError: boolean; 
    hasWarning?: boolean; 
    ud?: boolean;
    value: string;
}

export const getTollStatus = (toll?: TollState): TollValue => {
    
    switch(toll) {
        case "balanceerror":
            return {
                hasError: true,
                // value: 'Nincs egyenleg',
                value: translate('fleet.vehicle-details.fields.toll-status-values.balance-error'),
            } as TollValue;
        case "balancewarning":
            // return {
            //     hasError: true,
            //     // value: 'Alacsony egyenleg',
            //     value: 'Nincs egyenleg',
            // };
        case "fine":
            return {
                hasError: false,
                value: translate('fleet.vehicle-details.fields.toll-status-values.fine'),
            };
        case "gpserror":
            return {
                hasError: true,
                value: translate('fleet.vehicle-details.fields.toll-status-values.gps-error'),
            };
        case "noassign":
            return {
                hasError: true,
                value: translate('fleet.vehicle-details.fields.toll-status-values.no-assign'),
            };
        case "nobalancedata":
            return {
                hasError: true,
                value: translate('fleet.vehicle-details.fields.toll-status-values.no-balance-data'),
            };

        case "none":
        default:
            return {
                hasError: false,
                value: '-',
            };
    }
    
}

export function getRegionForCoordinates(points: {latitude: number, longitude: number}[]) {
    let minX: number, maxX: number, minY: number, maxY: number;
  
    if (!points?.length) {
        return BudapestCoords;
    }

    // init first point
    ((point) => {
      minX = point.latitude;
      maxX = point.latitude;
      minY = point.longitude;
      maxY = point.longitude;
    })(points[0]);
  
    // calculate rect
    points.map((point) => {
      minX = Math.min(minX, point.latitude);
      maxX = Math.max(maxX, point.latitude);
      minY = Math.min(minY, point.longitude);
      maxY = Math.max(maxY, point.longitude);
    });
  
    const midX = (minX + maxX) / 2;
    const midY = (minY + maxY) / 2;
    const deltaX = (maxX - minX);
    const deltaY = (maxY - minY);
  
    return {
      latitude: midX,
      longitude: midY,
      latitudeDelta: deltaX + .05,
      longitudeDelta: deltaY + .05
    };
  }
  