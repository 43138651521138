import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import Colors from '../../constants/Colors';

// SVGS
export { default as LicenseNo} from '../../assets/images/icons_components/JarmuRendszam';
export { default as Ignition} from '../../assets/images/icons_components/Gyujtas';
export { default as Driver} from '../../assets/images/icons_components/Sofor';

// export { default as DailyDist} from '../../assets/images/icons/napi_megtett_tavolsag';
// export { default as DailyDist} from '../../assets/images/icons/route_light';
export const DailyDist = (props: {height?: number, width?: number}) => <FontAwesomeIcon icon={['fal', 'route']} color={Colors.primary} size={props.height}/>;
export const Address = (props: {height?: number, width?: number}) => <FontAwesomeIcon icon={['fal', 'map-marker-alt']} color={Colors.primary} size={props.height}/>;
export { default as LastEvent} from '../../assets/images/icons_components/UtolsoEsemeny';
export { default as CurrentSpeed} from '../../assets/images/icons_components/JelenlegiSebesseg';

export { default as EngineTemp} from '../../assets/images/icons_components/MotorHomerseklet';
export { default as FuelLevel} from '../../assets/images/icons_components/Uzemanyagszint';
export { default as DailyMaxSpeed} from '../../assets/images/icons_components/NapiLegnagyobbSebesseg';

export { default as FleetMapSelected} from '../../assets/images/icons_components/TerkepAlmenupontSelected';
export { default as FleetMapUnselected} from '../../assets/images/icons_components/TerkepAlmenupontUnselected';
export { default as FleetListSelected} from '../../assets/images/icons_components/ListaAlmenupontSelected';
export { default as FleetListUnselected} from '../../assets/images/icons_components/ListaAlmenupontUnselected';

export { default as FleetLogo} from '../../assets/images/icons_components/Ifleet';

export { default as Toll} from '../../assets/images/icons_components/HugoKek';
export { default as TollError} from '../../assets/images/icons_components/HugoPiros';

// MAPS

export { default as StartMarker} from '../../assets/images/icons_components/StartMarker';
export { default as IgnitionOnMarkerD} from '../../assets/images/icons_components/GyujtasBe';
export { default as IgnitionOffMarkerD} from '../../assets/images/icons_components/GyujtasKi';

export { default as IgnitionOnMarkerDSelected} from '../../assets/images/icons_components/GyujtasBeSelected';
export { default as IgnitionOffMarkerDSelected} from '../../assets/images/icons_components/GyujtasKiSelected';

export { default as IgnitionChange} from '../../assets/images/icons_components/GyujtasValtozas';
export { default as IgnitionChangeSelected} from '../../assets/images/icons_components/GyujtasValtozasSelected';

// KÉK markerek
export { default as IgnitionOnMarker} from '../../assets/images/icons_components/JarmuGyujtason';
export { default as IgnitionOffMarker} from '../../assets/images/icons_components/JarmuGyujtaski';

// narancs markerek
export { default as GasPumpOrange} from '../../assets/images/icons_components/field/orange/GasPumpLight1Narancs';
export { default as PowerOffOrange} from '../../assets/images/icons_components/field/orange/PowerOffLight2Narancs';
export { default as RoadOrange} from '../../assets/images/icons_components/field/orange/RoadLight1Narancs';
export { default as RouteOrange} from '../../assets/images/icons_components/field/orange/RouteLight1Narancs';
export { default as ShippingFastOrange} from '../../assets/images/icons_components/field/orange/ShippingFastLight2Narancs';
export { default as TachometerAltFastOrange} from '../../assets/images/icons_components/field/orange/TachometerAltFastLight2Narancs';
export { default as ThermometerHalfOrange} from '../../assets/images/icons_components/field/orange/ThermometerHalfLight1Narancs';
export { default as UserOrange} from '../../assets/images/icons_components/field/orange/UserLight3Narancs';

// tűrkíz markerek
export { default as GasPumpTur} from '../../assets/images/icons_components/field/turquoise/GasPumpLight1';
export { default as PowerOffTur} from '../../assets/images/icons_components/field/turquoise/PowerOffLight2';
export { default as RoadTur} from '../../assets/images/icons_components/field/turquoise/RoadLight1';
export { default as RouteTur} from '../../assets/images/icons_components/field/turquoise/RouteLight1';
export { default as ShippingFastTur} from '../../assets/images/icons_components/field/turquoise/ShippingFastLight2';
export { default as TachometerAltFastTur} from '../../assets/images/icons_components/field/turquoise/TachometerAltFastLight2';
export { default as ThermometerHalfTur} from '../../assets/images/icons_components/field/turquoise/ThermometerHalfLight1';
export { default as UserTur} from '../../assets/images/icons_components/field/turquoise/UserLight3';

// logsheet details lista kék bogyó
export { default as LogsheetStopMarker} from '../../assets/images/icons_components/UtvonalMegallasMarker';

// OBU
export { default as BatteryVoltageIcon} from '../../assets/images/icons_components/obu/AkkuFesz';
export { default as JDBIcon} from '../../assets/images/icons_components/obu/Jarmukategoria';
export { default as PositionIcon} from '../../assets/images/icons_components/obu/Pizicio';
export { default as SIMCardIcon} from '../../assets/images/icons_components/obu/SimErvenyesseg';
export { default as VVoltageIcon} from '../../assets/images/icons_components/obu/TapFeszultseg';
export { default as TollStatusIcon} from '../../assets/images/icons_components/obu/UtdijEgyenlegStatusza';
export { default as LatestTicketIcon} from '../../assets/images/icons_components/obu/UtolsoLetepettSzakaszjegy';

// DRAWER
export { default as DrawerMenu} from '../../assets/images/icons_components/MenuGomb';
export { default as Support} from '../../assets/images/icons_components/Ugyfelszolgalat';
export { default as FleetModuleIcon} from '../../assets/images/icons_components/drawer/FlottaKivalasztva';
export { default as FleetModuleGreyIcon} from '../../assets/images/icons_components/drawer/FlottaSzurke';
export { default as OBUModuleIcon} from '../../assets/images/icons_components/drawer/ObUellKivalasztva';
export { default as OBUModuleGreyIcon} from '../../assets/images/icons_components/drawer/ObUellSzurke';
export { default as AppInformationIcon} from '../../assets/images/icons_components/drawer/NevjegyKivalasztva';
export { default as AppInformationGreyIcon} from '../../assets/images/icons_components/drawer/NevjegySzurke';
export { default as SettingsIcon} from '../../assets/images/icons_components/drawer/TestreszabasKivalasztva';
export { default as SettingsGreyIcon} from '../../assets/images/icons_components/drawer/TestreszabasSzurke';
export { default as RatingIcon} from '../../assets/images/icons_components/drawer/ErtekelesKivalasztva';
export { default as RatingGreyIcon} from '../../assets/images/icons_components/drawer/ErtekelesSzurke';
export { default as SupportIcon} from '../../assets/images/icons_components/drawer/UgyfelszolgalatKivalasztva';
export { default as SupportGreyIcon} from '../../assets/images/icons_components/drawer/UgyfelszolgalatSzurke';
// export { default as Logout from '../../assets/images/icons_components/kijelentkezes';
export { default as Logout } from '../../assets/images/icons_components/drawer/KijelentkezesFixNarancs';
