import * as React from 'react';

function SvgJarmuRendszam(props) {
  return (
    <svg
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#jarmu_rendszam_svg__clip0)">
        <path
          d="M20.052 8.18l.064-.359a.54.54 0 00-.53-.633h-4.43l.914-5.117a.54.54 0 00-.53-.634h-.366a.54.54 0 00-.53.445l-.948 5.306H7.97l.913-5.117a.54.54 0 00-.53-.634h-.365a.539.539 0 00-.531.445l-.947 5.306H1.887a.539.539 0 00-.53.444l-.065.36a.54.54 0 00.53.633h4.43l-1.027 5.75H.603a.539.539 0 00-.53.444l-.065.36a.54.54 0 00.531.633h4.43l-.914 5.117a.54.54 0 00.53.634h.366a.539.539 0 00.53-.445l.948-5.305h5.727l-.914 5.116a.54.54 0 00.531.634h.365a.54.54 0 00.53-.445l.948-5.305h4.622a.54.54 0 00.53-.445l.065-.36a.54.54 0 00-.53-.633h-4.43l1.027-5.75h4.621a.539.539 0 00.531-.444zm-7.64 6.195H6.686l1.027-5.75h5.727l-1.026 5.75z"
          fill="#003194"
        />
      </g>
      <defs>
        <clipPath id="jarmu_rendszam_svg__clip0">
          <path fill="#fff" d="M0 0h20.125v23H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgJarmuRendszam;
