import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { Marker } from 'react-leaflet';
import styled from 'styled-components';
import { CenterDiv } from '../components/Utils';
import Colors from '../constants/Colors';
import { Map } from './Map';
import iCellLogo from '../assets/images/icell_logo_small_trans700x700.png';

const HungariKoz5Coords = {
    lat: 47.508236,
    lng: 19.104582,
}

/**
 * - térkép
 * - 
 * @returns 
 */
export const ContactsView: React.FC = () => {
    return (
        <Container>
            {/* térképp a hungária közzel */}
            <div style={{height: 400, width: '100%'}}>
                <Map zoom={15} center={{latitude: HungariKoz5Coords.lat, longitude: HungariKoz5Coords.lng}}>
                    <Marker position={HungariKoz5Coords} />
                </Map>
            </div>

            <CenterDiv style={{height: 100, width: 500, justifyContent: 'space-between', borderBottom: '1px black solid'}}>
                <a target={'_blank'} href={`https://g.page/icellkft?share`}>
                    <CenterDiv>
                        <FontAwesomeIcon icon={['fal', 'map-marked-alt']} color={Colors.secondary} size={24}/>
                        <span>1143 Budapest, Hungária köz 5.</span>
                    </CenterDiv>
                </a>
                <CenterDiv>
                    <FontAwesomeIcon icon={['fal', 'clock']} color={Colors.tertiary} size={24}/>
                    <span>hétfő - péntek, 09 - 17-ig.</span>
                </CenterDiv>
            </CenterDiv>
            {/* divider vagy legyen border */}
            {/* 3 oszlop */}
            <CenterDiv style={{height: 300, width: '80%', justifyContent: 'space-between',}}>
                <div>
                    <HeaderText>i-cell kft. központ</HeaderText>
                    <CenterDiv style={{justifyContent: 'flex-start', paddingBottom: '10px'}}>
                        <FontAwesomeIcon icon={['fal', 'phone']} color={Colors.primary} size={24}/>
                        <span>+36 (1) 467-1750</span>
                    </CenterDiv>
                    <a href={'mailto: info@icell.hu'}>
                        <CenterDiv style={{justifyContent: 'flex-start', paddingBottom: '10px'}}>
                            <FontAwesomeIcon icon={['fal', 'envelope']} color={Colors.primary} size={24} />
                            <span>info@icell.hu</span>
                        </CenterDiv>
                    </a>
                </div>

                <div>
                    <HeaderText>terméktámogatás</HeaderText>
                    <CenterDiv style={{justifyContent: 'flex-start', paddingBottom: '10px'}}>
                        <FontAwesomeIcon icon={['fal', 'phone']} color={Colors.primary} size={24}/>
                        <span>+36 (1) 467-1765</span>
                    </CenterDiv>
                    <a href={'mailto: ifleetsupport@icell.hu'}>
                        <CenterDiv style={{justifyContent: 'flex-start', paddingBottom: '10px'}}>
                            <FontAwesomeIcon icon={['fal', 'envelope']} color={Colors.primary} size={24}/>
                            <span>ifleetsupport@icell.hu</span>
                        </CenterDiv>
                    </a>
                </div>

                <div>
                    <HeaderText>szervíz</HeaderText>
                    <CenterDiv style={{justifyContent: 'flex-start', paddingBottom: '10px'}}>
                        <FontAwesomeIcon icon={['fal', 'phone']} color={Colors.primary} size={24}/>
                        <span>+36 (1) 467-1756</span>
                    </CenterDiv>
                    <a href={'mailto: szerviz@icell.hu'}>
                        <CenterDiv style={{justifyContent: 'flex-start', paddingBottom: '10px'}}>
                            <FontAwesomeIcon icon={['fal', 'envelope']} color={Colors.primary} size={24}/>
                            <span>szerviz@icell.hu</span>
                        </CenterDiv>
                    </a>
                </div>
            </CenterDiv>
            <CenterDiv style={{height: '30%'}}>
                <a
                    href={'https://icell.hu'}
                    target={'_blank'}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // height: 200,
                    }}
                    >
                    <img
                        src={iCellLogo}
                        style={{
                            height: 80,
                            width: 80,
                        }}
                        // resizeMode={'contain'}
                    />
                    <span style={{color: 'grey'}}>i-Fleet by i-Cell Kft.</span>
                </a>
            </CenterDiv>

            {/* icell logo meg ifleet by icell kft felirat, ua mint a jobb menüben */}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 700px;

    & a {
        color: grey;
    }

    & span {
        padding-left: 10px;
        color: grey;
    }
`;

const HeaderText = styled.h4`
    text-transform: uppercase;
    color: ${Colors.secondary};
`;