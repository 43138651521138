import * as React from 'react';

function SvgRoadLight1Narancs(props) {
  return (
    <svg
      viewBox="0 0 576 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#road-light_1narancs_svg__clip0)">
        <path
          d="M279.47 192h17.06c5.81 0 10.35-6.17 9.73-13.26l-3.75-43.58c-.35-4.07-3.15-7.16-6.49-7.16h-16.05c-3.34 0-6.14 3.09-6.49 7.16l-3.75 43.58c-.61 7.09 3.93 13.26 9.74 13.26zm-6.02 112h29.1c7.75 0 13.79-8.23 12.98-17.68l-4.13-48c-.7-8.14-6.3-14.32-12.98-14.32h-20.83c-6.68 0-12.28 6.18-12.98 14.32l-4.13 48c-.82 9.45 5.22 17.68 12.97 17.68zm10.66-208h7.79c3.88 0 6.9-4.12 6.49-8.84l-1.38-16c-.35-4.07-3.15-7.16-6.49-7.16h-5.03c-3.34 0-6.14 3.09-6.49 7.16l-1.38 16c-.41 4.72 2.61 8.84 6.49 8.84zm23.95 240h-40.12c-6.68 0-12.28 6.18-12.98 14.32l-6.89 80c-.81 9.45 5.23 17.68 12.98 17.68h53.89c7.75 0 13.79-8.23 12.98-17.68l-6.89-80c-.69-8.14-6.29-14.32-12.97-14.32zM157.35 64a7.986 7.986 0 00-7.38 4.92L1.25 425.85C-3.14 436.38 4.6 448 16.02 448h28c7.11 0 13.37-4.69 15.36-11.52L165.03 74.24c1.49-5.12-2.35-10.24-7.68-10.24zm417.4 361.85L426.04 68.92a8.004 8.004 0 00-7.38-4.92c-5.33 0-9.17 5.12-7.68 10.24l105.65 362.24A16 16 0 00531.99 448h28c11.41 0 19.16-11.62 14.76-22.15z"
          fill="#F86F08"
        />
      </g>
      <defs>
        <clipPath id="road-light_1narancs_svg__clip0">
          <path fill="#fff" d="M0 0h576v512H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRoadLight1Narancs;
