import { userVar } from './../graphql/Client';
import { useReactiveVar } from '@apollo/client';
// import SInfo from 'react-native-sensitive-info';
import {APIURL} from '@env';
import React from 'react';
import LoginContainer, {Status, getLoginInfo} from './LoginContainer';
import axios from 'axios';
import {createContainer} from 'unstated-next';

export interface Group {
  id: string;
  is_disjunct: boolean;
  is_own: boolean;
  members: number[];
  name: string;
  node_id: number;
  type_id: number;
}

// TODO: presist to device storage
const useDispatcher = () => {
  const [status, setStatus] = React.useState<Status>();
  const [errorCode, setErrorCode] = React.useState<number | undefined>();
  const [vehicleGroups, setVehicleGroups] = React.useState<Group[]>();
  const [areaGroups, setAreaGroups] = React.useState<Group[]>();

  const [currentVehicleGroup, setCurrentVehicleGroup] = React.useState<Group>();

  // const loginContainer = LoginContainer.useContainer();
  const user = useReactiveVar(userVar);

  // React.useEffect(() => {
  //     initDispatcherSettings();
  // }, [loginContainer.loggined]);

  const fetch = async () => {
    try {
      setStatus('loading');

      if (!user.token) {
        console.error('No token, fleetstate get all function.', user.token);
        // analytics().logEvent('no-token-error', {
        //     function: 'fleetstate-getAll',
        // });
        setStatus('error');
        return;
      }

      const {data, status} = await axios.get(
        `${APIURL}/user_details/token/dispGroups`,
        {
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${user.token}`
          }
        }
      );

      if (data && data.length) {
        setVehicleGroups(data.filter((group: any) => group.type_id === 1));
        setAreaGroups(data.filter((group: any) => group.type_id === 2));
        setStatus('success');
      } else {
        console.error('NO DATA!');
        setErrorCode(1); // NO DATA
        setStatus('error');
      }
    } catch (e) {
      console.error({e});
      setStatus('error');
    }
  };

  const initDispatcherSettings = async () => {

    const selectedVehicleGroup = await readSelectedVehicleGroup();
    // @ts-ignore
    global.vehicleGroup = selectedVehicleGroup;
    setCurrentVehicleGroup(selectedVehicleGroup);
  };

  const readSelectedVehicleGroup = async () => {
    const loginData = await getLoginInfo();
    if (loginData?.userName && loginData?.companyCode) {
        const key = `vehicle-group-${loginData.userName}-${loginData.companyCode}`;
        // const raw = await SInfo.getItem(key, {});
    
        // if (!raw) {
        //   return undefined;
        // }
    
        // const group = JSON.parse(raw);
        // return group;
    }

    return undefined;
  };

  const setSelectedVehicleGroup = async (value?: Group) => {
    const loginData = await getLoginInfo();
    const key = `vehicle-group-${loginData.userName}-${loginData.companyCode}`;

    if (value) {
      // await SInfo.setItem(key, JSON.stringify(value), {});
      // @ts-ignore
      global.vehicleGroup = value;
      setCurrentVehicleGroup(value);
    } else {
      // await SInfo.deleteItem(key, {});
      // @ts-ignore
      global.vehicleGroup = undefined;
      setCurrentVehicleGroup(value);
    }
  };

  return {
    status,
    errorCode,
    vehicleGroups,
    fetch,
    initDispatcherSettings,
    readSelectedVehicleGroup,
    setSelectedVehicleGroup,
    currentVehicleGroup,
  };
};

const DispatcherContainer = createContainer(useDispatcher);

export default DispatcherContainer;
