import * as React from 'react';

function SvgVisszanyil(props) {
  return (
    <svg
      viewBox="0 0 15 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.171 1.457L2.663 12.422a1 1 0 000 1.384l10.508 10.965"
        stroke="#003194"
        strokeWidth={3}
      />
    </svg>
  );
}

export default SvgVisszanyil;
