import * as React from 'react';

function SvgUtolsoEsemeny(props) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M.547.219H.82c.182 0 .328.147.328.328v3.025A6.778 6.778 0 017.022.219c3.729.011 6.765 3.063 6.76 6.792a6.781 6.781 0 01-11.326 5.023.329.329 0 01-.013-.477l.193-.193a.328.328 0 01.45-.013A5.85 5.85 0 0012.852 7 5.85 5.85 0 001.798 4.32h3.125c.181 0 .328.147.328.328v.274a.328.328 0 01-.328.328H.547a.328.328 0 01-.328-.328V.547c0-.181.147-.328.328-.328z"
        fill="#003194"
      />
    </svg>
  );
}

export default SvgUtolsoEsemeny;
