import { Functions } from './../components/menu/Menu.types';
import { ErrorAlert } from './../components/ErrorAlert';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, makeVar } from "@apollo/client";
import { IS_LOGGED_IN, IS_LOGGED_IN_INTERFACE } from "./User.entity";
import { onError } from "@apollo/client/link/error";

const link = onError(({ graphQLErrors, networkError }) => { // TODO: send to admin
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // Alert.alert(translate('common.status.no-internet'));
    ErrorAlert('network');
  }
});

export const token = makeVar('');
export const userVar = makeVar<IS_LOGGED_IN_INTERFACE>({
  isLoggedIn: false,
  email: '',
  token: '',
  role: '',
  id: 0,
  functions: [],
  error: undefined,
  validity: undefined,
  dispId: 0,
});

export const userFunctions = makeVar<Functions[]>([]);

export const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
            userFunctions: {
              read() {
                return userFunctions();
              }
            },
           token: {
             read() {
               return token();
             }
           },
           userVar: {
             read() {
               return userVar();
             }
           }
        }
      }
    }
  });

export const client = new ApolloClient({
    // uri: 'http://report.ifleet:8001/graphql',
    // uri: 'http://localhost:8001/graphql',
    // uri: 'http://192.168.50.53:8001/graphql',
    // uri: 'https://ifleet.hu/login/',
    cache,
    link: ApolloLink.from([
      link, 
      new HttpLink({uri: 'https://ifleet.hu/backend/login/'}),
      // new HttpLink({uri: 'http://localhost:8001/graphql'}),
    ])
});

export const obuClient = new ApolloClient({
  cache,
  // link: ApolloLink.from([link, new HttpLink({uri: 'http://192.168.50.181:3000/graphql'})]), // local
  link: ApolloLink.from([link, new HttpLink({uri: 'https://ifleet.hu/backend/obu/'})]),
});

// cache.writeQuery({
//     query: IS_LOGGED_IN,
//     data: {
//     //   isLoggedIn: !!localStorage.getItem("token"),
//       isLoggedIn: false,
//       email: '',
//       error: undefined,
//     },
// });