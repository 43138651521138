import {gql, useMutation} from '@apollo/client';
import * as React from 'react';
import {Container} from './EmailVerificationView';
import IfleetLogo from '../../assets/images/ifleet.png';
import {ActivityIndicator, Button, TextInput} from 'react-native-paper';
import {translate} from '../../components/hooks/useTranslate';
import { goToLogin, navigate } from '../../components/RootNavigation';
import ScreenNames from '../../screens/ScreenNames';

const checkToken = gql`
  mutation CheckToken($token: String!) {
    resetPasswordCheckToken(confirmationToken: $token) {
      status
    }
  }
`;

const setNewPassword = gql`
  mutation SetNewPassword($token: String!, $password: String!) {
    resetPasswordSetNewPassword(
      confirmationToken: $token
      newPassword: $password
    ) {
      status
    }
  }
`;

export const CreateNewPasswordView: React.FC<{
  route: {params: {token: string}};
}> = props => {
  const [error, setError] = React.useState<string>();

  const [password, setPassword] = React.useState<string>('');
  const [
    confirmationPassword,
    setConfirmationPassword,
  ] = React.useState<string>('');

  const [checkTokenFunc, checkTokenStates] = useMutation(checkToken);
  const [setNewPasswordFunc, setNewPasswordStates] = useMutation(
    setNewPassword,
  );

  const {token} = props.route.params;

  React.useEffect(() => {
    if (token?.length) {
      checkTokenFunc({variables: {token}});
    }
  }, [token]);

  if (checkTokenStates.loading || setNewPasswordStates.loading) {
    return (
      <Container>
        <img src={IfleetLogo} />
        <h2>Kommunikáció folyamatban.</h2>
        <p>Kérjük várjon.</p>
        <ActivityIndicator />
      </Container>
    );
  }

  /**
   * Ha rossz a token, vagy valamiért nem elérhető a szolgáltatás.
   */
  if (checkTokenStates.error) {
    return (
      <Container>
        <img src={IfleetLogo} />
        <h2>Hiba történt, kérjük próbálja meg újra.</h2>
      </Container>
    );
  }

  /**
   * Létező felhasználóról van szó, és helyes a token is amit generáltunk számára.
   */
  if (checkTokenStates.data?.resetPasswordCheckToken?.status === 'ok' && setNewPasswordStates.data?.resetPasswordSetNewPassword?.status === undefined) {
    return (
      <Container>
        <img src={IfleetLogo} />
        <h2>Adja meg kétszer új jelszavát</h2>
        <TextInput
          label={translate('login.input.password')}
          // label={'E-mail'}
          autoCapitalize={'none'}
          textContentType={'password'}
          secureTextEntry
          error={!!error?.length}
          value={password}
          onChangeText={text => setPassword(text)}
        />
        <TextInput
          label={translate('login.input.password')}
          // label={'E-mail'}
          autoCapitalize={'none'}
          textContentType={'password'}
          secureTextEntry
          error={!!error?.length}
          value={confirmationPassword}
          onChangeText={text => {
            setConfirmationPassword(text);
            if (text !== password) {
              setError('Jelszavak nem egyeznek');
            } else {
              setError(undefined);
            }
          }}
        />

        {error?.length && <p style={{color: 'red'}}>{error}</p>}

        <Button
          onPress={() => {
            if (!password.length) {
              setError('Kérjük adja meg új jelszavát.');
            } else {
              setNewPasswordFunc({variables: {password, token}});
            }
          }}>
          Mentés
        </Button>
      </Container>
    );
  }

    /**
   * Sikeresen meg lett változtatva a jelszava.
   */
     if (setNewPasswordStates.data?.resetPasswordSetNewPassword?.status === 'ok') {
      return (
          <Container>
              <img src={IfleetLogo} />
              <h2>Sikeres jelszó változtatás!</h2>
              <Button onPress={() => goToLogin()}>Bejelentkezés</Button>
          </Container>
      );
  }

  if (checkTokenStates.data?.resetPasswordCheckToken?.status === 'not-found') {
    return (
      <Container>
          <img src={IfleetLogo} />
          <h2>Nem létező kérés. Kérjen új jelszó emlékeztetőt.</h2>
      </Container>
  );
  }

  return (
    <Container>
        <img src={IfleetLogo} />
        <h2>Új jelszó kérése hamarosan elindul, ha nem változna az oldal kérem firssítsen.</h2>
    </Container>
);
};
