import * as React from 'react';
import {getActualDriverName, getTollStatus} from './Constants';
import {
  LicenseNo,
  Ignition,
  Driver,
  LastEvent,
  CurrentSpeed,
  DailyDist,
  Toll,
  TollError,
  EngineTemp,
  FuelLevel,
  DailyMaxSpeed,
  Address,
  ShippingFastOrange,
  ShippingFastTur,
  GasPumpOrange,
  GasPumpTur,
  ThermometerHalfOrange,
  ThermometerHalfTur,
  RoadOrange,
  RoadTur,
  TachometerAltFastOrange,
  TachometerAltFastTur,
  RouteOrange,
  RouteTur,
  UserOrange,
  UserTur,
  PowerOffOrange,
  PowerOffTur,
} from '../components/icons/Icons';
import {GPS, Vehicle} from '../containers/FleetstateContainer';
import moment from 'moment';
import { AnalogsFields } from '../containers/route/RouteEntity';
import Analogs from './vehicle_details/Analogs';

export type Type = 'string' | 'date' | 'number' | 'boolean' | 'function';

export interface VehicleDetailsSetting {
  id: KeyForObjects;
  path: string; // HA '.' az értéke, akkor megkapja az egész objectet. ( Vehicle ), Function TYPE-nál!!
  type: Type;
  icon?: any;
  iconError?: any;
  tooltipText?: string;
  propsPath?: string;
  isNullText?: string;
  hasError?: boolean;
  selected?: boolean;
  onPress?: () => void;
  list?: {
    selectedIcon: any;
    icon: any;
  };
}

export interface BooleanType extends VehicleDetailsSetting {
  trueValue: string;
  falseValue: string;
}

export interface NumberType extends VehicleDetailsSetting {
  multiplication?: number;
  fixed?: number;
  suffix?: string;
}

export interface FunctionType extends VehicleDetailsSetting {
  callFunction: any;
}

export interface DateType extends VehicleDetailsSetting {
  format: string;
}

// OBJECTS

export const LicenseNoObject: VehicleDetailsSetting = {
  id: 'license_no',
  path: 'license_no',
  type: 'string',
  icon: LicenseNo,
  tooltipText: 'Rendszám',
};

const getGpsAddress = (gps?: GPS) => {
  if (!gps) {
    return '-';
  }

  const {geocode} = gps;
  if (geocode?.city && geocode?.road) {
    return `${geocode?.city}, ${geocode?.road},\n${geocode?.country}\n${moment(
      gps.time,
    ).fromNow()}`;
  } else {
    return '-';
  }
};

export const AddressObject: FunctionType = {
  id: 'address',
  path: 'gps',
  type: 'function',
  icon: Address,
  tooltipText: 'Cím',
  callFunction: getGpsAddress,
};

export const IgnitionObject: BooleanType = {
  id: 'ignition',
  path: 'ignition',
  type: 'boolean',
  icon: Ignition,
  list: {
    selectedIcon: PowerOffOrange,
    icon: PowerOffTur,
  },
  tooltipText: 'Gyújtás',
  trueValue: 'On',
  falseValue: 'Off',
};

export const DriverObject: FunctionType = {
  id: 'driver',
  path: 'drivers',
  propsPath: 'vehicle.drivers',
  type: 'function',
  icon: 'Driver',
  list: {
    selectedIcon: UserOrange,
    icon: UserTur,
  },
  tooltipText: 'Sofőr',
  callFunction: getActualDriverName,
};

export const DailyDistObject: NumberType = {
  id: 'daily_dist',
  path: 'daily_dist',
  type: 'number',
  icon: DailyDist,
  list: {
    selectedIcon: RouteOrange,
    icon: RouteTur,
  },
  multiplication: 0.001,
  fixed: 0,
  isNullText: '0',
  suffix: 'km',
  tooltipText: 'Megtett út',
};

export const LastEventObject: DateType = {
  id: 'last_event',
  path: 'last_event',
  type: 'date',
  icon: LastEvent,
  tooltipText: 'Utolsó adat',
  format: 'YYYY.MM.DD HH:mm',
  isNullText: 'Nincs esemény.',
};

export const GpsSpeedObject: NumberType = {
  id: 'gps_speed',
  path: 'gps.speed',
  type: 'number',
  icon: CurrentSpeed,
  list: {
    selectedIcon: TachometerAltFastOrange,
    icon: TachometerAltFastTur,
  },
  tooltipText: 'Sebesség',
  multiplication: 3.6,
  fixed: 0,
  isNullText: '0',
  suffix: 'km/h',
};

export const TollStatusObject: FunctionType = {
  id: 'toll_status',
  path: '',
  propsPath: 'vehicle.ud',
  type: 'function',
  icon: Toll,
  iconError: TollError,
  list: {
    selectedIcon: RoadOrange,
    icon: RoadTur,
  },
  callFunction: getTollStatus,
  tooltipText: 'Útdíj',
};

export const EngineTempObject: NumberType = {
  id: 'engine_temp',
  path: 'engine_temp',
  type: 'number',
  icon: EngineTemp,
  list: {
    selectedIcon: ThermometerHalfOrange,
    icon: ThermometerHalfTur,
  },
  tooltipText: 'Hőmérséklet',
  suffix: '°C',
  fixed: 0,
  isNullText: '0',
};

export const FuelLevelObject: NumberType = {
  id: 'fuel_level',
  path: 'fuel_level',
  type: 'number',
  icon: FuelLevel,
  list: {
    selectedIcon: GasPumpOrange,
    icon: GasPumpTur,
  },
  tooltipText: 'Üzemanyag',
  suffix: 'L',
  fixed: 0,
  isNullText: '0',
};

export const DailyMaxSpeedObject: NumberType = {
  id: 'daily_max_speed',
  path: 'max_speed',
  type: 'number',
  icon: DailyMaxSpeed,
  list: {
    selectedIcon: ShippingFastOrange,
    icon: ShippingFastTur,
  },
  tooltipText: 'Max sebesség',
  suffix: 'km/h',
  fixed: 0,
  isNullText: '0',
  multiplication: 3.6,
};

export const DefaultSettings: VehicleDetailsSetting[] = [
  // LicenseNoObject,
  IgnitionObject,
  DriverObject,
  DailyDistObject,
  // LastEventObject,
  GpsSpeedObject,
  EngineTempObject,
  FuelLevelObject,
  DailyMaxSpeedObject,
];

export const MapDefaultSettings: VehicleDetailsSetting[] = [
  // LicenseNoObject,
  DailyMaxSpeedObject,
  IgnitionObject,
  DriverObject,

  GpsSpeedObject,
  FuelLevelObject,
  DailyDistObject,
];

export const ListDefaultSettings: VehicleDetailsSetting[] = [
  // LicenseNoObject,
  DailyDistObject,
  DriverObject,
  GpsSpeedObject,
];

export type KeyForObjects =
  | 'license_no'
  | 'address'
  | 'ignition'
  | 'driver'
  | 'daily_dist'
  | 'last_event'
  | 'gps_speed'
  | 'toll_status'
  | 'engine_temp'
  | 'fuel_level'
  | 'daily_max_speed'
  // ANALOG
  // | 'temp_front'
  // | 'temp_middle'
  | AnalogsFields
  // /
  | undefined;

const AllDataObject = new Map<KeyForObjects, VehicleDetailsSetting>();

// AllDataObject.set('license_no', LicenseNoObject);
// AllDataObject.set('address', AddressObject);
AllDataObject.set('ignition', IgnitionObject);
AllDataObject.set('driver', DriverObject);
AllDataObject.set('daily_dist', DailyDistObject);
// AllDataObject.set('last_event', LastEventObject);
AllDataObject.set('gps_speed', GpsSpeedObject);
AllDataObject.set('toll_status', TollStatusObject);
AllDataObject.set('engine_temp', EngineTempObject);
AllDataObject.set('fuel_level', FuelLevelObject);
AllDataObject.set('daily_max_speed', DailyMaxSpeedObject);

// ANALOG
Analogs.forEach(a => AllDataObject.set(a.id, a));

export const AllDataObjectArray = [...AllDataObject.values()];

export const MaxFieldCountOnMapVehicleDetails = 6;
export const MaxFieldCountOnVehicleDetails = 9;
export const MaxFieldCountOnListVehicleDetails = 3;

export default AllDataObject;
