import * as React from 'react';
import {AppRegistry, StyleSheet, Text, View} from 'react-native';
import App from './App';
import {name as appName} from './app.json';

import 'react-native-gesture-handler';

import moment from 'moment';
moment.locale('hu'); // TODO: ez kell ide ?

import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';

library.add(fas, far, fal, fad);

// import { init } from '../constants/Notifications';

// init();

import {setI18nConfig} from "./components/hooks/useTranslate";

setI18nConfig();

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
    // Mount the react-native app in the "root" div of index.html
    rootTag: document.getElementById('app-root'),
});
