import { CommonActions, NavigationContainerRef } from "@react-navigation/native";
import * as React from 'react';
import ScreenNames from "../screens/ScreenNames";

export const navigationRef = React.createRef<NavigationContainerRef | null>();

export function navigate(name: string, params: any = undefined) {
    navigationRef.current?.navigate(name, params);
}

export function dispatch(arg: any) {
  navigationRef?.current?.dispatch(arg);
}

export function back() {
  navigationRef.current?.goBack();
}

export function goToLogin() {
    navigationRef.current?.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{name: ScreenNames.login}],
        }),
      );
}