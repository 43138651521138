import * as React from 'react';
import {
  SafeAreaView,
  View,
  StyleSheet,
  Linking,
  TouchableOpacity,
  Alert,
} from 'react-native';
import {useNavigation, CommonActions} from '@react-navigation/native';

import {
  translate,
  useLangContext,
  LanguageContext,
} from '../components/hooks/useTranslate';
import Colors from '../constants/Colors';
import {
  ActivityIndicator,
  Button,
  Caption,
  Headline,
  Snackbar,
  Text,
  Title,
  TextInput,
} from 'react-native-paper';
// import {FleetLogo} from '../components/icons/Icons';
import FleetLogo from '../assets/images/icons_components/Ifleet.js';
import {DeviceType} from '../constants/Constants';
import ScreenNames from './ScreenNames';
import {gql, useLazyQuery} from '@apollo/client';
import { token, userVar } from '../graphql/Client';
import jwtDecode from 'jwt-decode';
import { getUniqueId, } from 'react-native-device-info';
import { JWTPayload, setJWT, setUser, } from '../components/hooks/useJWTLogin';
import styled from 'styled-components';
// import messaging from '@react-native-firebase/messaging';


export const LOGIN = gql`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`;

type NavigationState = 'login' | 'registration' | 'choose';

const LoginInputs: React.FC<{
  state: NavigationState;
  setState: (arg: NavigationState) => void;
  setError: (arg: any) => void;
}> = ({state, setState, setError}) => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [login, {loading, data, error}] = useLazyQuery(LOGIN, {fetchPolicy: 'network-only', returnPartialData: true, errorPolicy: 'all'});
  const navigation = useNavigation();
  // const client = useApolloClient();

  React.useEffect(() => {
    setError(error && error.networkError === null ? true : false);
    // console.warn(JSON.stringify(error));
  }, [error]);

  React.useEffect(() => {
      if (data?.login?.token) {
        const payload: JWTPayload = jwtDecode(data.login.token);

        if (Date.now() >= payload.exp * 1000) {
            // TODO nem valid már...
            setError(true);
            return;
        }
        token(data.login.token);

        if (payload && data) {
            setUser(data.login.token, payload);

            navigation.dispatch(CommonActions.reset({
              index: 0,
              routes: [
                {name: ScreenNames.menu}
              ],
            }));
        }

        setJWT(data.login.token);
        
      }

  }, [data]);

  if (loading) {
    return (
      <SafeAreaView style={styles.container}>
        <ActivityIndicator />
        <Text>{translate('login.status.login-progress')}</Text>
      </SafeAreaView>
    );
  }

  switch (state) {
    case 'choose':
      return (
        <>
          <Button
            style={[styles.button, {backgroundColor: '#c3c3c3c3'}]}
            onPress={() => {
              setState('login');
            }}>
            {translate('login.button.login').toUpperCase()}
          </Button>

          <Button
            style={[styles.button, {backgroundColor: '#c3c3c3c3'}]}
            color={Colors.secondary}
            disabled
            onPress={() => {
              Alert.alert(
                'Dolgozunk rajta!',
                'Keresse az ifleet weboldalon....',
              );
            }}>
            {translate('login.button.registration').toUpperCase()}
          </Button>
        </>
      );
    case 'login':
      return (
        <>
          <TextInput
            style={styles.inputField}
            label={translate('login.input.e-mail')}
            value={username}
            onChangeText={(text) => setUsername(text)}
            autoCapitalize={'none'}
            textContentType={'emailAddress'}
            // autoCompleteType={'email'}
            autoCompleteType={'off'}
            keyboardType={'email-address'}
          />
          <TextInput
            style={styles.inputField}
            label={translate('login.input.password')}
            value={password}
            onChangeText={(text) => setPassword(text)}
            autoCapitalize={'none'}
            textContentType={'password'}
            secureTextEntry
          />
          <Button
            mode="contained"
            style={styles.button}
            disabled={!username.length || !password.length}
            onPress={async () => {
              // const token = await messaging().getToken();
              const token = 'todo'; // TODO
              login({variables: {
                username, 
                password, 
                deviceType: await DeviceType(),
                deviceId: getUniqueId(),
                firebaseToken: token,
              }});
            }}>
            {translate('login.button.login')}
          </Button>
        </>
      );
    case 'registration':
      return (
        <>
          <Text>Working progress...</Text>
        </>
      );
  }
};

export const LoginScreen: React.FC<any> = ({route}) => {
  // TODO: log a screen to analytics

  const [navigationState, setNavigationState] = React.useState<NavigationState>(
    'choose',
  );

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalCounter, setModalCounter] = React.useState(0);
  const [errorSnackbarIsOpen, setErrorSnackBar] = React.useState(false);

  const navigation = useNavigation();
  const [language] = useLangContext();

  // React.useEffect(() => {
  //   if (route.params?.error) {
  //     setErrorSnackBar(true);
  //   } else {
  //     setErrorSnackBar(false);
  //   }
  // }, [route.params?.error]);

  return (
      <LanguageContext.Consumer>
        {(lang) => {
          const {lang: language, setLang: setLanguage} = lang;
          return (
            <LoginBox>
                <View style={styles.container}>
                  <View style={{width: 200, height: 200, display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <FleetLogo height={120} width={120}/>
                  </View>
                  <View>
                    <Headline>{translate('common.welcome')}</Headline>
                  </View>

                  <View style={styles.pleaseLoginContainer}>
                    <Text style={styles.pleaseLoginText}>
                      {translate(
                        navigationState === 'choose'
                          ? 'login.text.select-login-type'
                          : 'login.text.please',
                      )}
                    </Text>
                    <View style={styles.pleaseLoginLine}></View>
                  </View>

                  <LoginInputs
                    state={navigationState}
                    setState={setNavigationState}
                    setError={setErrorSnackBar}
                  />

                  {navigationState !== 'choose' ? (
                    <>
                      <Text
                        style={styles.forgetPasswordText}
                        onPress={() =>
                          navigation.navigate(ScreenNames.resetPassword)
                        }>
                        {translate('login.text.forget-password')}
                      </Text>
                      <Text
                        style={styles.forgetPasswordText}
                        onPress={() => setNavigationState('choose')}>
                        {translate('common.cancel')}
                      </Text>
                    </>
                  ) : (
                    <></>
                  )}

                  {navigationState === 'choose' ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        width: 200,
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        height: 60,
                      }}>
                      <TouchableOpacity onPress={() => setLanguage('hu')}>
                        <Text
                          style={[
                            styles.languageKeyText,
                            language === 'hu'
                              ? {fontSize: 16, color: Colors.primary}
                              : undefined,
                          ]}>
                          HU
                        </Text>
                      </TouchableOpacity>
                      <Text style={styles.languageKeyText}>/</Text>
                      <TouchableOpacity onPress={() => setLanguage('en')}>
                        <Text
                          style={[
                            styles.languageKeyText,
                            language === 'en'
                              ? {fontSize: 16, color: Colors.primary}
                              : undefined,
                          ]}>
                          EN
                        </Text>
                      </TouchableOpacity>
                      <Text style={styles.languageKeyText}>/</Text>
                      <TouchableOpacity onPress={() => setLanguage('de')}>
                        <Text
                          style={[
                            styles.languageKeyText,
                            language === 'de'
                              ? {fontSize: 16, color: Colors.primary}
                              : undefined,
                          ]}>
                          DE
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <></>
                  )}

                  <Snackbar
                    wrapperStyle={{
                      zIndex: 999,
                    }}
                    visible={errorSnackbarIsOpen}
                    onDismiss={() => setErrorSnackBar(false)}
                    action={{
                      label: translate('common.help'),
                      onPress: () => navigation.navigate(ScreenNames.support),
                    }}>
                    {translate('login.status.wrong-login')}
                  </Snackbar>

                  <Caption
                    style={styles.dataText}
                    onPress={() => {
                      Linking.openURL(
                        'https://icell.hu/adatvedelmi-nyilatkozat/',
                      );
                    }}>
                    {translate('login.text.privacy-policy')}
                  </Caption>
                </View>
            </LoginBox>
          );
        }}
      </LanguageContext.Consumer>
  );
};

const LoginBox = styled.div`
  backdrop-filter: blur(3px);
  background-color: rgba(240, 240, 240, 0.8);
  width: 350px; 
  border-radius: 10px; 
  padding: 10px; 
  z-index: 1; 
  box-shadow: 0px 0px 19px 0px rgba(50, 50, 50, 0.75);
`;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
  imageAndWelcomeTextContainer: {
    alignItems: 'center',
  },
  inputContainer: {
    flexShrink: 0,
    width: '80%',
    display: 'flex',
    justifyContent: 'space-around',
    height: 280,
    // backgroundColor: Colors.background,
  },
  inputField: {
    backgroundColor: 'transparent',
    width: '80%',
    outlineStyle: 'none',
    outline: 'none',
  },
  button: {
    height: 60,
    paddingTop: 12,
    width: '80%',
    marginTop: 10,
  },
  forgetPasswordText: {
    textDecorationLine: 'underline',
    color: Colors.secondary,
    marginTop: 20,
  },
  dataText: {
    marginTop: 20,
    paddingHorizontal: 20,
    textAlign: 'left',
    textDecorationLine: 'underline',
  },
  image: {
    // marginTop: 'auto'
  },
  pleaseLoginContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 40,

    width: '100%',
  },
  pleaseLoginText: {
    color: 'gray',
    textTransform: 'uppercase',
    backgroundColor: Colors.background,
    textAlign: 'center',
    zIndex: 1,
    paddingHorizontal: 10,
    maxWidth: 220,
  },
  pleaseLoginLine: {
    position: 'absolute',
    top: '50%',
    height: 1,
    width: '80%',
    backgroundColor: 'gray',
  },
  languageKeyText: {
    color: 'grey',
    fontSize: 12,
    fontWeight: '600',
  },
});

const SupportModalContent = (props: any) => {
  return (
    <View style={styleModal.container}>
      <Title>Elakadt?</Title>
      <Text> Keresse ügyfélszolgálatunkat.</Text>
      <View style={styleModal.buttonContainer}>
        <Button
          mode={'contained'}
          onPress={() => {
            props.navigation.navigate(ScreenNames.support);
            props.setModalIsOpen(false);
          }}>
          Ügyfélszolgálat
        </Button>
        <Button onPress={() => props.setModalIsOpen(false)}>Mégse</Button>
      </View>
    </View>
  );
};

const styleModal = StyleSheet.create({
  container: {
    width: '80%',
    height: 180,
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
  },
});
