import * as React from 'react';
import {useEventHandlers} from '@react-leaflet/core';
// import { onChange } from 'react-native-reanimated';
import FleetstateContainer, {Vehicle} from '../containers/FleetstateContainer';
import FleetstateListScreen from '../screens/fleetstate/list/ListScreen';
import MapScreenVehicleDetails from '../screens/fleetstate/map/MapScreenVehicleDetails.web';

import {Map} from './Map';
import {MapContainer} from './MapContentContainer';
import {LatLngBounds, LatLngBoundsExpression} from 'leaflet';

import {ImageOverlay, LayerGroup, Marker, Popup, useMap} from 'react-leaflet';
import L from 'leaflet';
// @ts-ignore
import ReactDOMServer from 'react-dom/server';
import {IgnitionOffMarker, IgnitionOnMarker} from '../components/icons/Icons';
import VehicleRouteContainer from '../containers/VehicleRouteContainer';
import _ from 'lodash';

const MapBounds: React.FC<{isSelected: boolean}> = ({isSelected}) => {
  const map = useMap();

  const [bounds, setBounds] = React.useState<LatLngBounds>(map.getBounds());

  useEventHandlers(
  // @ts-ignore
  {instance: map},
    {
      moveend: () => {
        if (!isSelected) {
          setBounds(map.getBounds());
        }
      },
      // zoomend: () => {
      //     if (!isSelected) {
      //         setZoom(map.getZoom());
      //     }
      // },
    },
  );

  React.useEffect(() => {
    if (!isSelected && bounds) {
      map.flyToBounds(bounds);
    }
  }, [isSelected]);

  return <></>;
};

export const FleetstateView: React.FC = () => {
  const [selectedVehicle, setSelectedVehicle] = React.useState<
    Vehicle | undefined
  >();
  const [opened, setOpened] = React.useState<boolean>(false);

  return (
    <>
      <Map>
        {selectedVehicle && (
          <MapContainer
            type={'center'}
            anotherType={'bottom'}
            width={'600px'}
            height={'auto'}>
            <MapScreenVehicleDetails
              vehicle={selectedVehicle}
              setVehicle={setSelectedVehicle}
            />
          </MapContainer>
        )}

        <FlyToVehicle currentSelected={selectedVehicle}/>

        <MapBounds isSelected={selectedVehicle === undefined ? false : true} />
        <FleetstateMarkers
          selected={selectedVehicle}
          setSelected={setSelectedVehicle}
        />
      </Map>
      <MapContainer
        type={'right'}
        anotherType={'top'}
        // fullHeight={opened}
        width={opened ? '350px' : '60px'}
        height={opened ? 'calc(100% - 60px)' : '60px'}
        gradient={!opened}>
        <FleetstateListScreen
          setSelectedVehicle={setSelectedVehicle}
          selectedVehicle={selectedVehicle}
          // setOpened={setOpened}
          // opened={opened}
        />
      </MapContainer>

      {/* <div style={{overflowY: 'scroll', width: '200px', position: 'absolute', right: 0, top: 0, height: '80vh'}}>
                <div style={{width: '200px', height: '80vh', backgroundColor: 'red'}}>a</div>
                <div style={{width: '200px', height: '80vh', backgroundColor: 'blue'}}>b</div>
                <div style={{width: '200px', height: '80vh', backgroundColor: 'orange'}}>c</div>
            </div> */}
    </>
  );
};

const FlyToVehicle: React.FC<{currentSelected?: Vehicle}> = ({currentSelected}) => {
  const vehicleContainer = VehicleRouteContainer.useContainer();
  const map = useMap();

  React.useLayoutEffect(() => {
    if (currentSelected?.vehicle_id === vehicleContainer.vehicle?.vehicle_id) {
      if (
        vehicleContainer.vehicle?.gps &&
        _.isNumber(vehicleContainer.vehicle?.gps.latitude) &&
        _.isNumber(vehicleContainer.vehicle?.gps.longitude)
      ) {
        map.flyTo(
          {
            lat: vehicleContainer.vehicle?.gps.latitude,
            lng: vehicleContainer.vehicle?.gps.longitude,
          },
          15,
        );
      }
    }
  }, [currentSelected, vehicleContainer.vehicle]);

  return <></>;
};

const FleetstateMarkers: React.FC<{
  selected?: Vehicle;
  setSelected: (arg: any) => void;
}> = props => {
  const map = useMap();
  const fleetstateContainer = FleetstateContainer.useContainer();

  const Layers = React.useMemo(() => {

    let newZoom = '' + (2*(map.getZoom()+2)) +'px';

    return (
      <LayerGroup>
        {fleetstateContainer?.data?.vehicles?.map(v => {
          if (v.gps?.latitude && v.gps?.longitude) {
            let opacity = 1;
            if (props.selected) {
              opacity = 0;
              // if (props.selected.vehicle_id === v.vehicle_id) {
              // 	opacity = 1;
              // } else {
              // 	opacity = 0;
              // }
            }

            return (
              <>
              {/* <Marker position={{lat: v.gps.latitude, lng: v.gps.longitude}} /> */}
              <Marker
                key={`${v.vehicle_id}${v.license_no}-marker`}
                position={{lat: v.gps.latitude, lng: v.gps.longitude}}
                icon={L.divIcon({
                  className: 'customIcon',
                  iconAnchor: [22, 50],
                  // shadowSize:   [22, 50],
                  // shadowAnchor: [22, 50],
                  html: v.ignition
                    ? ReactDOMServer.renderToString(
                        // <IgnitionOnMarker height={70} width={25} />,
                        <IgnitionOnMarker height={newZoom} width={newZoom} />,
                      )
                    : ReactDOMServer.renderToString(
                        <IgnitionOffMarker height={newZoom} width={newZoom} />,
                      ),
                })}
                opacity={opacity}
                eventHandlers={{
                  click() {
                    props.setSelected(v);
                  },
                }}
              />
              </>
            );
          } else {
            return <></>;
          }
        })}
      </LayerGroup>
    );
  }, [fleetstateContainer.data, map.getZoom()]);

  return Layers;
};
