import { sendEvent } from './EventContainer';
import React from 'react';
import {createContainer} from 'unstated-next';
import axios from 'axios';
import {APIURL} from '@env';
// import crashlytics from '@react-native-firebase/crashlytics';

const tokenKey = 'token';

export type Status = 'loading' | 'error' | 'success';

export interface Loggined {
  userName: string;
  sessionId: string;
  dispId: string;
  dispGroupId: string;
  groupType: string;
  companyId: string;
  companyCode: string;
}

function useLogin() {
  const [loggined, setLoggin] = React.useState<Loggined | undefined>(undefined);
  const [status, setStatus] = React.useState<Status>();
  const [errorCode, setErrorCode] = React.useState<number | undefined>();

  const login = async (
    companyCode: string,
    userName: string,
    password: string,
  ) => {
    try {
      setStatus('loading');

      const {data, status, statusText, request} = await axios.post(
        `${APIURL}/session_fx/login`,
        {
          req_id: 0,
          func: '/login',
          params: {
            comp_code: companyCode,
            login: userName,
            password,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      // console.log({
      //   status,
      //   statusText,
      //   data,
      //   request
      // });

      if (data?.msg) {
        // invalid login
        setStatus('error');
        setErrorCode(status);
        sendEvent('error', {
          function: 'login',
          status,
          data,
        });
      } else if (status !== 200) {
        // something went wrong
        setStatus('error');
        setErrorCode(status);
        sendEvent('error', {
          function: 'login',
          status,
          data,
        });
      } else {
        // console.log({ asd: data?.result });

        const {
          session_id,
          disp_id,
          disp_ug_id,
          ug_type,
          comp_id,
        } = data?.result;
        const loginInfo = {
          userName,
          sessionId: session_id,
          dispId: disp_id,
          dispGroupId: disp_ug_id,
          groupType: ug_type,
          companyId: comp_id,
          companyCode,
        };

        await setToken(session_id);
        await setLoginInfo(loginInfo);

        setLoggin(loginInfo);
        setStatus('success');
      }
    } catch (loginExp) {
      console.error({APIURL, companyCode, userName, password, loginExp});
      setStatus('error');
      // crashlytics().recordError(loginExp);
      sendEvent('error', {
        function: 'login',
        exception: loginExp,
      });
      if (loginExp.response) {
        setErrorCode(loginExp.response.status);
      } else {
        setErrorCode(0);
      }
    }
  };

  const checkToken = async () => {
    try {
      setStatus('loading');
      const token = await getToken();

      if (!token) {
        setStatus(undefined);
        return;
      }

      const {data, status} = await axios.post(
        `${APIURL}/session_fx/check`,
        {
          req_id: 0,
          func: '/check',
          session_id: token,
          params: {},
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (data?.msg) {
        // invalid login
        await removeToken();
        setStatus('error');
        setErrorCode(status);
        sendEvent('error', {
          function: 'login-check',
          status,
          data,
        });
      } else if (status !== 200) {
        // something went wrong
        await removeToken();
        setStatus('error');
        setErrorCode(status);
        sendEvent('error', {
          function: 'login-check',
          status,
          data,
        });
      } else {
        await setToken(token);

        const {login, disp_id, disp_ug_id, ug_type, comp_id} = data?.result;
        const oldLoginInfo = await getLoginInfo();

        setLoggin({
          userName: login,
          sessionId: token,
          dispId: disp_id,
          dispGroupId: disp_ug_id,
          groupType: ug_type,
          companyId: comp_id,
          companyCode: oldLoginInfo.companyCode,
        });

        setStatus('success');
      }
    } catch (checkTokenExp) {
      console.error({checkTokenExp});
      setStatus('error');
      if (checkTokenExp.response) {
        setErrorCode(checkTokenExp.response.status);
      } else {
        setErrorCode(0);
      }

      sendEvent('error', {
        function: 'login-check',
        exception: checkTokenExp,
      });
    }
  };

  const logout = async () => {
    await removeToken();
    await removeLoginInfo();
    setLoggin(undefined);
  };

  return {loggined, login, logout, status, checkToken, errorCode};
}

async function getToken(): Promise<string | null> {
  // const tokenRaw = await SInfo.getItem(tokenKey, {});
  // console.warn({obj: JSON.parse(tokenRaw)});
  // if (!tokenRaw) {
    // return null;
  // }
  // const token = JSON.parse(tokenRaw);
  // console.warn({sessionId: token.token});
  // return token.token;
  return 'asd';
}

const setToken = async (token: string) => {
  // @ts-ignore
  global.token = token;
  // await SInfo.setItem(tokenKey, JSON.stringify({token}), {});
};

const removeToken = async () => {
  // @ts-ignore
  delete global.token;
  // await SInfo.deleteItem(tokenKey, {});
};

const setLoginInfo = async (data: any) => {
  // await SInfo.setItem('data', JSON.stringify(data), {});
  // await SInfo.setItem('login', JSON.stringify({userName: data.userName, companyCode: data.companyCode}), {});
};

export const getLoginInfo = async () => {
  // const dataRaw = await SInfo.getItem('data', {});
  // console.warn({dataRaw});
  // if (!dataRaw) {
  //   return null;
  // }
  // const data = JSON.parse(dataRaw);
  // // console.warn({data});
  // return data;
  return {companyCode: "asd"}
};

const removeLoginInfo = async () => {
  // await SInfo.deleteItem('data', {});
};

const LoginContainer = createContainer(useLogin);

export default LoginContainer;
