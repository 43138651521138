import { useLazyQuery, gql, useMutation } from '@apollo/client';
import * as React from 'react';
import { TextInput, Button, ActivityIndicator } from 'react-native-paper';
import { setI18nConfig, translate } from '../../components/hooks/useTranslate';
import { Container } from './EmailVerificationView';

import IfleetLogo from '../../assets/images/ifleet.png';
import { GoogleReCaptcha, useGoogleReCaptcha, } from 'react-google-recaptcha-v3';

const checkEmailAddress = gql`
    mutation CheckEmailAddress($email: String!, $token: String!) {
        resetPasswordCheckEmail(email: $email, recaptchaToken: $token) {
            status
        }
    }
`;

export const ResetPasswordView: React.FC<{route: {params?: {}}}> = (props) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [email, setEmail] = React.useState<string>('');
    const [token, setToken] = React.useState<string>();

    const [error, setError] = React.useState<string>();
    const [checkEmail, {loading, data, error: checkEmailError}] = useMutation(checkEmailAddress);

    // React.useLayoutEffect(() => {
    //     setI18nConfig().then(() => {
    //         setError(undefined);
    //     });
    // }, []);

    React.useEffect(() => {
        (async () => {
            const tokenTemp = await executeRecaptcha?.("reset_password_page");
            // console.log({tokenTemp});
            if (tokenTemp) {
                console.log({urlencoded: encodeURIComponent(tokenTemp)});
                setToken(encodeURIComponent(tokenTemp));
            }
        })()
    }, [executeRecaptcha]);

    React.useEffect(() => {
        if (checkEmailError) { // valamilyen kommunikációs hiba
            setError('Valami általános hiba');
        } else {
            setError(undefined);
        }
    }, [checkEmailError]);

    if (data?.resetPasswordCheckEmail?.status === 'ok') {
        return (
            <Container>
                <img src={IfleetLogo} />
                <h2>Megadott e-mail címre, elküldtük a további teendőket.</h2>
                <p>Ezt az ablakot bezárhatja.</p>
            </Container>
        );
    }

    if (loading) {
        return (
            <Container>
                <img src={IfleetLogo} />
                <h2>Kommunikáció folyamatban.</h2>
                <p>Kérjük várjon.</p>
                <ActivityIndicator />
            </Container>
        );
    }

    return (
        <Container>
            <img src={IfleetLogo} />
            <h2>Elfelejtett jelszó</h2>
            <TextInput
                label={translate('login.input.e-mail')}
                // label={'E-mail'}
                autoCapitalize={'none'}
                textContentType={'emailAddress'}
                autoCompleteType={'email'}
                keyboardType={'email-address'}
                error={!!error?.length}
                value={email}
                onChangeText={(text) => setEmail(text)}
            />
            {
                data?.resetPasswordCheckEmail?.status === 'not-found' && 
                    <p style={{color: 'red'}}>Nem létező e-mail cím. Kérjük ellenőrizze helyesen adta-e meg.</p>
            }

            {
                data?.resetPasswordCheckEmail?.status === 'captcha-error' &&
                    <p style={{color: 'red'}}>Captcha hiba történt, kérjük próbálja újra.</p>
            }

            {
                error?.length && 
                    <p style={{color: 'red'}}>{error}</p>
            }

            <Button onPress={() => {
                if (!email.length) {
                    setError('Kérjük adja meg e-mail címét.');
                } if (!token?.length) {
                    setError('Hiba a google captha-val!');
                }else {
                    checkEmail({variables: { email, token }});
                }
            }}>Megerősítés</Button>
        </Container>
    );
}