import { gql, useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { ActivityIndicator, Button } from 'react-native-paper';
import styled from 'styled-components';
import { navigate } from '../../components/RootNavigation';
import { CenterDiv } from '../../components/Utils';
import Colors from '../../constants/Colors';
import ScreenNames from '../../screens/ScreenNames';

import IfleetLogo from '../../assets/images/ifleet.png';

const VERIFY_EMAIL = gql`
    mutation EmailVerification($token: String!) {
        verifyEmail(confirmationToken: $token) {
            status
        }
    }
`;

/**
 * TODO: communicate login backend to verification
 * @returns 
 */
export const EmailVerificationView: React.FC<{route: {params: {token: string}}}> = (props) => {
    const [verifyEmail, {loading, data, error}] = useMutation(VERIFY_EMAIL);

    const {token} = props.route.params;

    React.useEffect(() => {
        if (token?.length) {
            verifyEmail({variables: {token}});
        }
    }, []);
    
    if (loading) {
        return (
            <Container>
                <img src={IfleetLogo} />
                <h2>E-mail verifikáció folyamatban.</h2>
                <p>Kérjük várjon.</p>
                <ActivityIndicator />
            </Container>
        );
    }

    if (error || data?.verifyEmail?.status === 'error') {
        return (
            <Container>
                <img src={IfleetLogo} />
                <h2>Hiba történt. Kérjük próbálja újra.</h2>
                <sub>Vagy vegye fel a kapcsolatott:</sub>
                <a href={'tel:+3614671755'}><sub>+36 1 467 1755</sub></a>
                <a href={'mailto:support@icell.hu'}><sub>support@icell.hu</sub></a>
            </Container>
        );
    }

    if (data?.verifyEmail?.status === 'ok' || data?.verifyEmail?.status === 'verify') {
        return (
            <Container>
                <img src={IfleetLogo} />
                <h2>Sikeres e-mail megerősítés!</h2>
                <h3>Nincs más dolga, mint bejelentkezni az alkalmazásba.</h3>
                {/* <Button onPress={() => navigate(ScreenNames.login, null)}>Bejelentkezés</Button> */}
            </Container>
        );
    }

    if (data?.verifyEmail?.status === 'verified') {
        return (
            <Container>
                <img src={IfleetLogo} />
                <h2>A regisztráció sikeres</h2>
                <h3>Nincs más dolga, mint bejelentkezni az alkalmazásba.</h3>
                {/* <Button onPress={() => navigate(ScreenNames.login, null)}>Bejelentkezés</Button> */}
            </Container>
        );
    }

    return (
        <Container>
            <img src={IfleetLogo} />
            <h2>E-mail verifikáció hamarosan elindul, ha nem változna az oldal, kérem firssítsen.</h2>
        </Container>
    );
}

export const Container = styled(CenterDiv)`
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    background-color: ${Colors.background};

    & > * ~ * {
        margin-top: 30px;
    }
`;