import * as React from 'react';
 
export const useOutsideClick = (ref: React.MutableRefObject<any>, callback: () => void) => {
    const handleClick = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
          callback();
        }
      };
    
      React.useEffect(() => {
        document.addEventListener("click", handleClick);
    
        return () => {
          document.removeEventListener("click", handleClick);
        };
      });
}