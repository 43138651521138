import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { View, Platform, FlatList, StyleSheet } from 'react-native';
import { ActivityIndicator, Divider, FAB, IconButton, Searchbar, TouchableRipple, Text } from 'react-native-paper';
import FleetstateContainer, { Vehicle } from '../../containers/FleetstateContainer';
import SettingsContainer from '../../containers/settings/SettingsContainer';
import { translate } from '../hooks/useTranslate';
import ScreenNames from '../../screens/ScreenNames';
import Colors from '../../constants/Colors';
import { VehicleDetailsGird } from '../VehicleDetailsGridGenerator';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { navigate } from '../RootNavigation';
import moment from 'moment';
import { CenterDiv } from '../Utils';
import { SearchComponent, useMotiAnimationState } from './SearchBox';

interface VehicleListProps {
    setSelected?: (arg: any) => void;
	selected?: VehicleWithIndex;
	setOpened?: (arg: boolean) => void;
	opened?: boolean;
}

let fleetstateIntervalId: any;
const selectedNodeRef = React.createRef<any>();

const useWebHooks = ({selected}: any) => {
    const fleetstateContainer = FleetstateContainer.useContainer();

    React.useEffect(() => {
		if (fleetstateContainer.status === undefined || fleetstateContainer.status === 'error') {
			fleetstateContainer.getAll()
			.then(() => {
				fleetstateIntervalId = setInterval(() => {
					const diffInSec = moment().diff(moment(fleetstateContainer.data?.timestamp), "seconds");
					if (diffInSec > 30) {
					//   setSnackBarVisible(true);
					  fleetstateContainer
						  .getAll()
						  .then(() => {
							// setSnackBarVisible(false);
						  })
						  .catch(() => {
							// setSnackBarVisible(false);
						  });
					}
				  },
				  300000
				//   3000
				  );
			});
		}

		return () => {
			clearInterval(fleetstateIntervalId);
		  };
	}, []);

    React.useEffect(() => {
        if (selectedNodeRef.current) {
            selectedNodeRef.current.scrollIntoView({
              behavior: "smooth",
            });
        }
    }, [selectedNodeRef.current, selected]);

    React.useEffect(() => {
		(async () => {
		  if (!selected) {
			const diffInSec = moment().diff(moment(fleetstateContainer.data?.timestamp), "seconds");
			if (diffInSec > 30) {
			//   setSnackBarVisible(true);
	
			  fleetstateContainer
				  .getAll()
				  .then(() => {
					// setSnackBarVisible(false);
				  })
				  .catch(() => {
					// setSnackBarVisible(false);
				  });
			}
		  }
		})();
	
	  }, [selected]);
}

interface Asd extends VehicleListProps {
	route: {
		params?: VehicleListProps,
	}
}

export interface VehicleWithIndex {
	vehicle?: Vehicle;
	index?: number;
}

export const VehicleList: React.FC<Asd> = ({opened, setOpened, route: {params}}) => {
	// const [currentSelected, setCurrentSelected] = React.useState<VehicleWithIndex | undefined>(params?.selected);
	const [search, setSearch] = React.useState('');
    const fleetstateContainer = FleetstateContainer.useContainer();
	const settingsContainer = SettingsContainer.useContainer();

    const data = React.useMemo(() => fleetstateContainer.getDataWithSort(
        settingsContainer.shortByField,
        true,
        search,
    ), [search, settingsContainer.shortByField, fleetstateContainer.data]);

	const flatListRef = React.useRef<FlatList<Vehicle>>(null);

	if (Platform.OS === 'web') {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useWebHooks({selected: params?.selected});
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		React.useEffect(() => {
			if (fleetstateContainer.status === undefined || fleetstateContainer.status === 'error') {
				fleetstateContainer.getAll();
			}
		}, []);

		// React.useEffect(() => {
		// 	if (params?.selected && params.selected?.index > 0) {

		// 		setTimeout(() => {
		// 			flatListRef.current?.scrollToIndex({
		// 				animated: true,
		// 				//@ts-ignore
		// 				index: params.selected.index,
		// 			});
		// 		}, 200);
		// 	}
		// }, [ data ]);
	}


	const navigation = useNavigation();

    let searchbarPlaceHolder = React.useMemo(() => {
		return `${translate('common.text.search-by-field', {field: settingsContainer.shortByField?.tooltipText?.toLowerCase()})}`;
	}, [settingsContainer.shortByField]);

    const animateState = useMotiAnimationState();
    
    if (fleetstateContainer.status === 'loading') {
        if (Platform.OS !== 'web') {
            return (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator/>
                    <Text>{translate('common.status.data-loading')}</Text>
                </View>
            );
        }

		return (
			<CenterDiv 
				style={{height: '100%', flexDirection: 'column', cursor: 'pointer'}}
				onClick={() => setOpened?.(!opened)}
				>
				<ActivityIndicator/>
				{
					opened && (<Text>{translate('common.status.data-loading')}</Text>)
				}
			</CenterDiv>
		);
	}

	if (fleetstateContainer.status === 'error') {
		return (
			<View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
				<Text>{translate('common.status.pls-try-again')}</Text>
			</View>
		);
	}

    if (!opened && Platform.OS === 'web') {
		return (<CenterDiv style={{height: '100%', cursor: 'pointer'}} onClick={() => setOpened?.(true)}>
			<FontAwesomeIcon icon={['fal', 'list']} color={Colors.primary} size={24}/>
		</CenterDiv>);
	}

    return (
        <View style={{width: '100%', height: '100%'}}>
            <SearchComponent 
                animateState={animateState} 
                search={search}
                setSearch={setSearch}
                searchbarPlaceHolder={searchbarPlaceHolder}
                setOpened={setOpened}
            />

            { 
                Platform.OS !== 'web' && (
                    <View style={{marginTop: 20,}}>
                    <Divider/>
                    <FAB
                        style={{position: 'absolute', right: 20, top: -20, backgroundColor: Colors.primary, zIndex: 999, elevation: 2}}
                        color={'white'}
                        small
                        icon={'sort-amount-down-alt'}
                        onPress={() => navigation.navigate(ScreenNames.fleetState.vehicleListShort)}
                    />
                </View>)
            }

            <FlatList
				ref={flatListRef}
				data={data}
				keyExtractor={(item) => `${item.vehicle_id}`}
				windowSize={41}
				maxToRenderPerBatch={20}
				scrollEventThrottle={16}
				// onScrollToIndexFailed={error => {
				// 	flatListRef?.current?.scrollToOffset({
				// 		offset: error.averageItemLength * error.index,
				// 		animated: true,
				// 	});
				
				// 	setTimeout(() => {
				// 		if (flatListRef?.current !== null) {
				// 			flatListRef?.current.scrollToIndex({
				// 				index: error.index,
				// 				animated: true
				// 			});
				// 		}
				// 	}, 100);
				// }}
				onScroll={(event) => {
					// console.log({begin: true, ev: event.nativeEvent.contentOffset.y});
					const offsetY = event.nativeEvent.contentOffset.y;

					if (offsetY <= 0) {
						animateState?.transitionTo?.('from');
					} else {
						// @ts-ignore
						animateState?.transitionTo((currentState) => {
							if (currentState !== 'close') {
								return 'close';
							}
						});
					}
				}}
				renderItem={({item, index}) => (
					<RenderItem
						index={index}
						item={item}
						settingsContainer={settingsContainer}
                        selected={params?.selected?.vehicle}
                        setSelected={ (vehicle: Vehicle) => {
							// setCurrentSelected({index, vehicle});
							params?.setSelected?.({index, vehicle});
						} }
					/>
				)}
			/>
        </View>
    );
}

export interface SearchComponentProps extends SearchContentProps {
    animateState: any;
    setOpened?: (arg: any) => void;
}  

export interface SearchContentProps {
    searchbarPlaceHolder: string;
    search: string;
    setSearch: (arg: string) => void;
}

/**
 * TODO: onPress, hogy configolható legyen
 * @param param0 
 * @returns 
 */
const RenderItem: React.FC<{index: number, item: any, settingsContainer: any, selected?: Vehicle, setSelected?: (arg: any) => void}> = ({item, settingsContainer, selected, setSelected}) => {
	const currentItemIsSelected = selected?.vehicle_id === item.vehicle_id;
	
    const Icon = React.useMemo(() => {
        if (currentItemIsSelected) {
			if (Platform.OS === 'web') {
				return (
					<span style={{position: 'absolute', right: '10px', bottom: 'calc(50% - 5px)',background: 'linear-gradient(180deg, #FF8B33 0%, #D75E04 100%)', width: 10, height: 10, borderRadius: 10, }} />
				);
			} else {
				return (
					<View style={{position: 'absolute', right: -10, bottom: '45%',backgroundColor: '#FF8B33', width: 10, height: 10, borderRadius: 10, }}/>
				);
			}
        }

        return (
            <FontAwesomeIcon 
                color={Colors.primary} 
                style={renderItemStyles.icon}
                icon={['fal', 'chevron-right']}
            />
        );
    }, [currentItemIsSelected]);

    return (
		<TouchableRipple
			style={renderItemStyles.container}
			onPress={() => {
				// navigation.navigate(ScreenNames.fleetState.vehicleDetails, item);
                // if (Platform.OS === 'web') {
                    if (currentItemIsSelected) {
                        setSelected?.(undefined);
                    } else {
                        setSelected?.(item);
                    }
                // } else {
                //     navigate(ScreenNames.fleetState.vehicleDetails, item);
                // }
			}}>
			<View 
                style={Platform.OS === 'web' ? 
                    [
                        renderItemStyles.viewContainer, 
                        {backgroundColor: currentItemIsSelected ? 'lightgray' : 'transparent'},
                    ] 
                    : 
                    {}
                }
                ref={currentItemIsSelected ? selectedNodeRef : undefined}
            >
				<View>
					<VehicleDetailsGird 
						object={item}
						props={{vehicle: item}}
						settings={settingsContainer.listVehicleDetailsFields}
						selectedCard={settingsContainer?.shortByField?.id ?? 'license_no'}
						screenName={'list'}
					/>
				</View>
				{/* <FontAwesomeIcon 
					color={Colors.primary} 
					style={renderItemStyles.icon}
					icon={['fal', 'chevron-right']}
				/> */}
                {Icon}
			</View>
		</TouchableRipple>
	);
}

const renderItemStyles = StyleSheet.create({
    container: {
        borderStyle: 'solid',
        minHeight: 48,
        borderBottomColor: 'rgba(0,0,0,.12)',
        zIndex: 1,
        ...Platform.select({
            native: {
                borderBottomWidth: StyleSheet.hairlineWidth,
                paddingHorizontal: 16,
            }
        }),
    },
    viewContainer: {
        borderRadius: 10,
        margin: 10,
    },
    icon: {
		...Platform.select({
			native: {
				position: 'absolute', 
				right: 0, 
				bottom: '39%',
			},
			web: {
				position: 'absolute', 
				right: '10px', 
				bottom: 'calc(50% - 5px)',
			}
		})
    },
});


