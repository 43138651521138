// import SInfo from 'react-native-sensitive-info';
import React from 'react';
import {createContainer} from 'unstated-next';
// import messaging from '@react-native-firebase/messaging';
// import analytics from '@react-native-firebase/analytics';
import {Status, getLoginInfo} from './LoginContainer';
import axios from 'axios';
import { APIURL } from '@env';

export const sendEvent = async (type: string, data: any) => {
  // console.warn({type, data});
    try {
      // const token = await messaging().getToken();
      // const loginRaw = await SInfo.getItem('login', {});
      // console.warn({loginRaw});
      let loginData;
      // if (loginRaw) {
        // loginData = JSON.parse(loginRaw);
      // }

      // const {status} = await axios.post(
      //   `${HOST}/admin/event`,
      //   {
      //     type,
      //     data,
      //     fcmToken: token,
      //     companyCode: loginData?.companyCode,
      //     userName: loginData?.userName,
      //   },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
      // );
     
    } catch (saveEventExp) {
      // console.warn({saveEventExp});
    }
}
