import React from 'react';
import {View, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import {
  VehicleDetailsSetting,
  TollStatusObject,
  LicenseNoObject,
  AddressObject,
} from '../constants/VehicleDetailsConstants';
import Card from './Card';
import * as _ from 'lodash';
import {Divider, Text, Button} from 'react-native-paper';
import {KeyForObjects} from '../constants/VehicleDetailsConstants';
import {getVehicleData} from '../containers/settings/VehicleDataGenerator';
import Colors from '../constants/Colors';
import { useNavigation } from '@react-navigation/native';
import {translate} from "./hooks/useTranslate";
import ScreenNames from '../screens/ScreenNames';

export const VehicleDetailsGird: React.FC<{
  object: any,
  props: any,
  settings: VehicleDetailsSetting[],

  keyForSwitchToTollField?: KeyForObjects,
  tollStatus?: boolean,
  onPressCard?: (arg: KeyForObjects) => void,
  
  selectedCard?: KeyForObjects,
  screenName?: 'list' | 'map' | 'details',
  countItemInLine?: number,
}> = ({
  object,
  props,
  settings,
  keyForSwitchToTollField,
  tollStatus,
  onPressCard,
  selectedCard,
  screenName,

  countItemInLine = 3,
}) => {
  const navigation = useNavigation();

  let grid: React.ReactElement[] = [];
  let row: React.ReactElement[] = [];

  const licenseNo = getVehicleData(object, props, LicenseNoObject);
  const gpsAddress = getVehicleData(object, props, AddressObject);

  const address = gpsAddress.value.split('\n');

  const inListView = screenName === 'list';

  grid.push(
    <View
      key={`${object.vehicle_id}-header`}
      style={{paddingTop: 10, paddingLeft: 10, paddingRight: 10,}}>
      <TouchableOpacity
        onPress={() => onPressCard?.('license_no')}
        disabled={!onPressCard}
      >
        <Text
          numberOfLines={1}
            style={{
          color: inListView ? (selectedCard === 'license_no' ? Colors.secondary : Colors.tertiary) : Colors.secondary,
          fontWeight: '600',
          fontSize: 20,
          marginBottom: 5,
        }}>
          {licenseNo.value}
        </Text>
      </TouchableOpacity>
      <Text
          numberOfLines={1}
          style={{
        color: inListView ? (selectedCard === 'address' ? Colors.secondary : 'grey') : 'grey',
        fontSize: 16,
      }}
      >
        {address[0]}
      </Text>
        {address[1] ? <Text
          numberOfLines={1}
          style={{
            color: inListView ? (selectedCard === 'address' ? Colors.secondary : 'grey') : 'grey',
            fontSize: 16,
          }}>
        {address[1]}
      </Text> : <></> }
        {address[2] ? <Text numberOfLines={1}
            style={{
              color: inListView ? (selectedCard === 'address' ? Colors.secondary : 'grey') : 'grey',
              fontSize: 14,
            }}>
        {address[2]}
      </Text> : <></> }
    </View>,
  );

  settings.forEach((element: VehicleDetailsSetting, index: number) => {
    const selected = selectedCard === element.id;

    if (tollStatus && element.id === keyForSwitchToTollField) {
      const f = TollStatusObject;

      // const f = element as FunctionType;
      let va = '';
      if (f.propsPath) {
        va = _.get(props, f.propsPath);
      } else {
        va = _.get(object, f.path);
      }

      row.push(
        <Card
          key={`${f.id}-${object.vehicle_id}`}
          selected={selected}
          icon={ inListView ? f.list?.icon : f.icon }
          // selectedIcon={ inListView ? f.list?.selectedIcon : undefined }
          selectedIcon={ f.list?.selectedIcon }
          iconError={f.iconError}
          value={f.callFunction(va)}
          // tooltipText={f.tooltipText ?? ''}
          tooltipText={translate(`fleet.vehicle-details.fields.${f.id}`)}
          onPress={onPressCard ? () => onPressCard(f.id) : undefined}
        />,
      );
    } else {
      const vehicleData = getVehicleData(object, props, element);

      row.push(
        <Card
          key={`${element.id}-${object.vehicle_id}`}
          id={element.id}
          selected={selected}
          icon={ inListView ? element.list?.icon : element.icon }
          // selectedIcon={ inListView ? element.list?.selectedIcon : undefined }
          selectedIcon={ element.list?.selectedIcon }
          iconError={element.iconError}
          value={vehicleData.value}
          // tooltipText={element.tooltipText ?? ''}
          tooltipText={translate(`fleet.vehicle-details.fields.${element.id}`)}
          onPress={onPressCard ? () => onPressCard(element.id) : undefined}
        />,
      );
    }

    // TODO: comment
    if ((index + 1) % countItemInLine === 0 || index === settings.length - 1) {
      grid.push(
        <View key={`${object.vehicle_id}-${index}-row`}>
          <View style={styles.row}>{row}</View>
          <Divider />
        </View>,
      );
      row = [];
    }
  });

  if (settings.length === 0 && screenName === 'details') {
    grid.push (
      <View style={styles.errorContainer}>
        <View style={styles.row}>
          <Text>{translate('fleet.vehicle-details.fields.no-selected-data')}</Text>
        </View>
        <Button style={{marginTop: 10,}} icon={'cog'} onPress={() => navigation.navigate(ScreenNames.settings.allSettings)}>
          {translate('drawer.button.settings')}
        </Button>
      </View>
    );
  }

  return <>{grid}</>;
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: Platform.OS === "web" ? 'fit-content': 'auto',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    marginTop: 10,
  },
});
