import React from 'react';
import {
  View,
  SafeAreaView,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
// import analytics from '@react-native-firebase/analytics';
import {Text, TextInput, Button, Chip, Caption} from 'react-native-paper';
// import {AirbnbRating} from 'react-native-ratings';
import Colors from '../../constants/Colors';
import RatingContainer, {RateStatus} from '../../containers/RatingContainer.web';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {translate} from "../../components/hooks/useTranslate";

interface Props {
  back: () => void;
}

const Rate: React.FC<Props> = (props) => {
  const [rating, setRating] = React.useState<number>(0);
  const [message, setMessage] = React.useState<string>('');

  const [speedProblem, setSpeedProblem] = React.useState<boolean>(false);
  const [limitedData, setLimitedData] = React.useState<boolean>(false);
  const [application, setApplication] = React.useState<boolean>(false);
  const [someProblem, setSomeProblem] = React.useState<boolean>(false);

  const [sended, setSended] = React.useState<boolean>(false);

  const ratingContainer = RatingContainer.useContainer();

  if (sended) {
    return (
      <View
        style={[
          StyleSheet.absoluteFillObject,
          {
            alignItems: 'center',
          },
        ]}>
        <View style={{marginTop: 80}} />
        <FontAwesomeIcon icon={'check-circle'} size={40} color={'green'} />
        <Text style={{marginTop: 20}}>{translate('rate.thank-you-feedback')}</Text>
      </View>
    );
  }

  return (
    <KeyboardAvoidingView
      style={{flex: 1}}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      enabled
      keyboardVerticalOffset={20}>
      <SafeAreaView style={{flex: 1, backgroundColor: Colors.background}}>
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
          <View style={styles.container}>
            <View
              style={{
                // marginVertical: 10,
                marginBottom: 20,
                alignItems: 'center',
                width: '100%',
                height: 200
              }}>
              <FontAwesomeIcon icon={['far', 'comment-dots']} size={70} color={Colors.primary}/>
              <Text onPress={Keyboard.dismiss} style={[styles.text, {}]}>
                {translate('rate.feedback-text')}
              </Text>
              {/* <AirbnbRating
                defaultRating={rating}
                // @ts-ignore
                style={{
                  backgroundColor: 'transparent',
                  color: 'red',
                  tintColor: 'transparent',
                }}
                count={5}
                reviews={[
                  translate('rate.reviews.terrible'),
                  translate('rate.reviews.bad'),
                  translate('rate.reviews.ok'),
                  translate('rate.reviews.good'),
                  translate('rate.reviews.great'),
                ]}
                size={30}
                selectedColor={Colors.secondary}
                reviewColor={Colors.secondary}
                onFinishRating={(r) => setRating(r)}
              /> */}
            </View>
           
          <View style={{height: 160}}>
            {rating < 5 && rating > 0 ? (
              <>
                <Caption>{translate('rate.select-problems')}</Caption>
                <Chip
                  icon={speedProblem ? undefined : 'tachometer-alt-slow'}
                  selected={speedProblem}
                  onPress={() => setSpeedProblem(!speedProblem)}>
                  {translate('rate.problems.slow')}
                </Chip>
                <Chip
                  icon={limitedData ? undefined : 'table'}
                  selected={limitedData}
                  onPress={() => setLimitedData(!limitedData)}>
                  {translate('rate.problems.limited-data')}
                </Chip>
                <Chip
                  icon={application ? undefined : 'mobile-alt'}
                  selected={application}
                  onPress={() => setApplication(!application)}>
                  {translate('rate.problems.ui')}
                </Chip>

                <Chip
                  icon={someProblem ? undefined : 'bug'}
                  selected={someProblem}
                  onPress={() => setSomeProblem(!someProblem)}>
                  {translate('rate.problems.other')}
                </Chip>
              </>
            ) : (
              <></>
            )}
            </View>

            <TextInput
              label={translate('rate.comment')}
              multiline={true}
              numberOfLines={2}
              style={{
                backgroundColor: 'transparent',
                maxHeight: 120,
                // minHeight: 100,
              }}
              // numberOfLines={2}
              onChangeText={(text) => setMessage(text)}
              value={message}
            />
            <View style={styles.buttonContainer}>
              <Button
                onPress={() => {
                  props.back();
                  ratingContainer.saveRate({
                    message,
                    rate: rating,
                    status: RateStatus.later,

                    application,
                    limitedData,
                    speedProblem,
                    someProblem,
                  });

                  // analytics().logEvent('rate', {
                  //   message,
                  //   rate: rating,
                  //   status: RateStatus.later,

                  //   application,
                  //   limitedData,
                  //   speedProblem,
                  //   someProblem,
                  // });
                }}>
                {translate('rate.maybe-later')}
              </Button>
              <Button
                disabled={!rating}
                mode="contained"
                onPress={() => {
                  ratingContainer.saveRate({
                    message,
                    rate: rating,
                    status: RateStatus.rated,

                    application,
                    limitedData,
                    speedProblem,
                    someProblem,
                  });

                  // analytics().logEvent('rate', {
                  //   message,
                  //   rate: rating,
                  //   status: RateStatus.rated,

                  //   application,
                  //   limitedData,
                  //   speedProblem,
                  //   someProblem,
                  // });

                  setSended(true);
                }}>
                {translate('rate.send')}
              </Button>
            </View>

          </View>
        </TouchableWithoutFeedback>
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'flex-end',
  },
  text: {
    paddingVertical: 10,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 40,
    justifyContent: 'space-around',
  },
});

export default Rate;
