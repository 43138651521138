import { useReactiveVar } from '@apollo/client';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import Colors from '../constants/Colors';
import { userVar } from '../graphql/Client';
import { HeaderLeftOnWeb, HeaderRightOnWeb, LogoTitle } from '../screens/drawer/Header.web';
import { LoadingScreen } from '../screens/LoadingScreen';
import { Login } from './Login';
import { MenuScreen } from '../screens/menu/MenuScreen.web';
import ScreenName from '../screens/ScreenNames';
import { FleetstateView } from './FleetstateView';
import { NavigationContainer } from '@react-navigation/native';
import {back, navigationRef} from '../components/RootNavigation';
import ShortScreen from '../screens/fleetstate/list/ShortScreen';
import Rate from '../screens/rate/Rate.web';
import Settings from '../screens/settings/Settings';
import VehicleDetailsSettings from '../screens/settings/VehicleDetailsSettings';
import VehicleGroup from '../screens/settings/VehicleGroups';
import styled, { css } from 'styled-components';
import { Button, IconButton, Modal } from 'react-native-paper';
import { ContactsView } from './ContactsView';
import { EmailVerificationView } from './common/EmailVerificationView';
import { ResetPasswordView } from './common/ResetPasswordView';
import { CreateNewPasswordView } from './common/CreateNewPasswordView';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const WebSiteName = 'Next Generation iFleet';

const Stack = createStackNavigator();

interface Screens {
  [key: string]: {
    component: React.ComponentType<any>;
    options?: StackNavigationOptions;
  }
}


const config = {
  screens: {
    // [ScreenName.login]: 'login',
    // Profile: 'user',
    [ScreenName.fleetState.tabNavigation]: {
      path: 'fleet-state',
    },
    [ScreenName.emailVerification]: {
      path: ScreenName.emailVerification + '/:token',
    },
    [ScreenName.createNewPassword]: {
      path: ScreenName.resetPassword + '/:token',
    },
    [ScreenName.resetPassword]: {
      path: ScreenName.resetPassword + '/',
    }
  },
};

const linking = {
  prefixes: ['https://ifleet.hu', 'ifleet://'],
  config,
};

const commonScreens: Screens = {
  // Help: HelpScreen,
  [ScreenName.emailVerification]: {
    component: EmailVerificationView,
    options: {
      header: () => null,
    }
  },
  [ScreenName.resetPassword]: {
    component: (props: any) => <GoogleReCaptchaProvider reCaptchaKey="6Le2hs8aAAAAAKkp5CQmWuyAJ_LfKFy2ikkJd2Ua" ><ResetPasswordView {...props}/></GoogleReCaptchaProvider>,
    options: {
      header: () => null,
    }
  },
  [ScreenName.createNewPassword]: {
    component: (props: any) => <GoogleReCaptchaProvider reCaptchaKey="6Le2hs8aAAAAAKkp5CQmWuyAJ_LfKFy2ikkJd2Ua" ><CreateNewPasswordView {...props}/></GoogleReCaptchaProvider>,
    options: {
      header: () => null,
    }
  },
};

const authScreens: Screens = {
  [ScreenName.loading]: {
    component: LoadingScreen,
    options: {
      header: () => null,
    }
  },
  [ScreenName.login]: {
    component: Login,
    options: {
      header: () => null,
    }
  },
};

const userScreens: Screens = {
  [ScreenName.menu]: {
    component: MenuScreen,
    options: {
      // title: 'Menu'
      // headerTitleAlign: 'left',
      // headerShown: true,
    }
  },
  [ScreenName.fleetState.tabNavigation]: {
    component: FleetstateView,
    options: {
      // headerTitleAlign: 'left',
      // headerShown: true,

      // headerTitle: () => <LogoTitle />,
      // headerTitleContainerStyle: {
      //   justifyContent: 'center',
      //   alignItems: 'center',
      // }
    }
  },
  [ScreenName.fleetState.vehicleListShort]: {
    component: (props: any) => <ModalForScreens screen={ShortScreen} navProps={props} />,
    options: {
      headerShown: true,
    }
  },
  [ScreenName.rate]: {
    component: (props: any) => <ModalForScreens screen={Rate} navProps={props} />,
  },
  [ScreenName.settings.allSettings]: {
    component: (props: any) => <ModalForScreens screen={Settings} navProps={props} />,
  },
  [ScreenName.settings.vehicleDetails]: {
    component: (props: any) => <ModalForScreens screen={VehicleDetailsSettings} navProps={props} />,
    options: {
      cardStyle: {
        backgroundColor: 'transparent'
      }
    }
  },
  [ScreenName.settings.vehicleGroup]: {
    component: (props: any) => <ModalForScreens screen={VehicleGroup} navProps={props} />,
  },
  [ScreenName.obu.list]: {
    component: () => (<div>Fejlesztés alatt...</div>)
  },
  [ScreenName.contacts]: {
    component: (props: any) => <ModalForScreens screen={ContactsView} navProps={props} disablePadding width={'800px'}/>,
  },
};

interface ModalStyleOptions {
  disablePadding?: boolean;
  width?: string;
}

interface ModalOptions extends ModalStyleOptions {
  screen: any;
  navProps: any;
}

const ModalForScreens: React.FC<ModalOptions> = ({screen: Child, navProps, disablePadding, width}) => {
  return (
    <ContainerForSettings >
      <ContainerForChild disablePadding={disablePadding} width={width}>
        <IconButton style={{
          width: 40, 
          height: 40, 
          marginBottom: 'auto', 
          borderRadius: 50, 
          backgroundColor: 'lightgrey', 
          position: 'absolute', 
          right: 5, 
          top: 5, 
          zIndex: 9999,
          }} onPress={() => back()} icon={'times'} color={Colors.primary}/>
        <Child {...navProps} />
      </ContainerForChild>
    </ContainerForSettings>
  );
}

const ContainerForChild = styled.div<ModalStyleOptions>`
  position: relative;
  & > :first-child{
    box-shadow: 0px 5px 5px #80808052;
    /* background: linear-gradient(150.64deg, #FFFFFF 0%, #CECECE 100%); */
  }

  & > div + div {
    border-radius: 10px;
    ${props => !props.disablePadding && css`padding: 20px;` }
    background-color: ${Colors.background};
    ${props => !props.width ? 
      css`
        width: 400px;
        max-width: 400px;
      ` 
      : 
      css`
        width: ${props.width};
        /* max-width: 400px; */
      `
    }

    max-height: 95%;
    /* overflow-x: scroll; */
  }
`;

const ContainerForSettings = styled.div<ModalStyleOptions>`
  display: flex;
  position: relative;
  /* align-items: center; */
  padding-top: 20px;
  justify-content: center;
  /* width: 10vw !important; */
  /* height: 80vh; */
  height: 100%;
  width: 100%;
  /* background-color: white */
  
`;

export const StackNavigator: React.FC = () => {
  const {isLoggedIn} = useReactiveVar(userVar);

  return (
    <Stack.Navigator
    detachInactiveScreens={false}
    mode={'modal'}
    headerMode={'screen'}
    keyboardHandlingEnabled
    screenOptions={({navigation}) => ({
      // headerShown: false,
      cardStyle: { backgroundColor: 'rgba(0,0,0,0.1)' },
      cardOverlayEnabled: true,
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),

      title: WebSiteName,
      // headerShown: false,
      // headerTransparent: true,
      animationEnabled: true,
      headerBackTitleVisible: false,
      headerTitleAlign: 'center',
      headerTitle: () => <LogoTitle />,
      headerTintColor: Colors.primary,
      headerStyle: {
        backgroundColor: Colors.background,
        height: 40,
        borderBottomWidth: 1,
      },
      headerLeft: ({tintColor}) => (
        <HeaderLeftOnWeb tintColor={tintColor} navigation={navigation} />
      ),

      headerRight: ({tintColor}) => (
        <HeaderRightOnWeb tintColor={tintColor} navigation={navigation} />
      ),
      
    })}
    >
      {Object.entries({
        ...(isLoggedIn ? userScreens : authScreens),
        ...commonScreens,
      }).map(([name, value]) => (
        <Stack.Screen name={name} component={value.component} options={value.options}/>
      ))}
    </Stack.Navigator>
  );
}

export const Navigation: React.FC = () => {
  return (
    <NavigationContainer
        // @ts-ignore
        ref={navigationRef}
        linking={linking}
        onReady={() => {
        }}
        >
          <StackNavigator />
        </NavigationContainer>
  );
}