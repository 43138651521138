import * as React from 'react';
import {Platform, Pressable, View} from 'react-native';
import Colors from '../../constants/Colors';
import ScreenNames from '../../screens/ScreenNames';
import {ObUellKivalasztva,} from '../../assets/images/icons_components/drawer';
import { RatingIcon, SettingsIcon, OBUModuleIcon } from '../icons/Icons';
import { logoutJWT } from '../hooks/useJWTLogin';
import { Logout } from '../icons/Icons';
import { Menu } from './Menu';
import { Functions } from './Menu.types';
import { useReactiveVar } from '@apollo/client';
import { userFunctions } from '../../graphql/Client';

export const MenuItem: React.FC<{
    fun: Functions;
    navigation: any;
    disabled?: boolean;
    leftFloatMenuStyle?: boolean;
  }> = (props) => {
    switch (props.fun) {
      case 'parking':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.parking);
            }}
            iconName={'parking-circle'}
            color={'white'}
            secondaryColor={Colors.primary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      case 'fleetstate':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.fleetState.tabNavigation);
            }}
            iconName={'map-marked-alt'}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      case 'route':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.route);
            }}
            iconName={'route'}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      case 'obu':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.obu.list);
            }}
            iconName={'road'}
            icon={Platform.OS === 'web' ? ObUellKivalasztva : OBUModuleIcon}
            color={Colors.primary}
            secondaryColor={'rgba(255, 255, 255, 1)'}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      case 'e-obu-registration':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.obu.list);
            }}
            iconName={'sack-dollar'}
            // icon={ObUellKivalasztva}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      case 'bug-report':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.obu.list);
            }}
            iconName={'megaphone'}
            // icon={ObUellKivalasztva}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      case 'service-prices':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.obu.list);
            }}
            iconName={'file-invoice-dollar'}
            color={'transparent'}
            secondaryColor={Colors.tertiary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      case 'contacts':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.contacts);
            }}
            iconName={'address-book'}
            color={Colors.tertiary}
            secondaryColor={Colors.primary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
        case 'menu':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.menu);
            }}
            iconName={'home'}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
        case 'rate':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.rate);
            }}
            iconName={'home'}
            icon={RatingIcon}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
        case 'settings':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              props.navigation.navigate(ScreenNames.settings.allSettings);
            }}
            iconName={'home'}
            icon={SettingsIcon}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
        case 'logout':
        return (
          <Menu
            fun={props.fun}
            onPress={() => {
              // props.navigation.navigate(ScreenNames.settings);
              logoutJWT();
            }}
            iconName={'home'}
            icon={Logout}
            color={Colors.primary}
            secondaryColor={Colors.secondary}
            disabled={props.disabled}
            leftFloatMenuStyle={props.leftFloatMenuStyle}
          />
        );
      default:
        return <></>;
    }
  };
  