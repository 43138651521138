import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { View, Text, Platform } from 'react-native';
import {IconName} from '@fortawesome/pro-solid-svg-icons';
// @ts-ignore
import styled, { css } from 'styled-components/native';
import styledWeb from 'styled-components';
import { Functions } from '.';
import { translate } from '../hooks/useTranslate';
import {MenuComponent} from './MenuComponent';

interface MenuProps {
  fun: Functions;
  onPress: () => void;
  iconName: IconName;
  icon?: any;
  color: string;
  secondaryColor: string;
  disabled?: boolean;
  leftFloatMenuStyle?: boolean;
}

export const Menu: React.FC<MenuProps> = ({
  onPress, 
  leftFloatMenuStyle, 
  icon, 
  disabled, 
  iconName, 
  color, 
  secondaryColor, 
  fun
}) => {
  const containerRef = React.useRef();
  const UniIcon = icon;
  return (
    <MenuComponent
        onPress={onPress}
        leftFloatMenuStyle={leftFloatMenuStyle}
        disabled={disabled}
        fun={fun}
    >
      <MenuContainer
        ref={containerRef}
        disabled={disabled}
        leftFloatMenuStyle={leftFloatMenuStyle}
        //@ts-ignore
        onMouseEnter={() => containerRef?.current?.focus()}>
        {UniIcon ? (
          <UniIcon
            height={leftFloatMenuStyle ? 22 : 50}
            width={leftFloatMenuStyle ? 22 : 50}
          />
        ) : (
          <Icon
            icon={['fad', iconName]}
            size={leftFloatMenuStyle ? 22 : 50}
            color={color}
            secondaryColor={secondaryColor}
          />
        )}
        <View
          style={{
            marginLeft: 20,
          }}>
          <Text
            style={{
              fontSize: leftFloatMenuStyle ? 18 : 20,
              color: '#7B7B7B',
            }}>
            {translate(`menu.${fun}.header`)}
          </Text>
          {/* <Caption>{translate(`menu.${props.fun}.description`)}</Caption> */}
        </View>
      </MenuContainer>
    </MenuComponent>
  );
};

interface MenuContainerProps {
    disabled?: boolean;
    leftFloatMenuStyle?: boolean;
}

const MenuContainer = (Platform.OS === 'web' ? 
    styledWeb.div<MenuContainerProps>`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding: 20px;
        background-color: ${(props: any) =>
            props.disabled ? 'grey' : 'white'};
        border-radius: 15px;
        box-shadow: 5px 5px 5px #8080800f;
    
        height: 70px;
        padding: 0;
        padding-left: 10px;

        ${(props: any) => props.leftFloatMenuStyle && css`
            height: 40px;
            background-color: transparent;
            box-shadow: none;
        `}

        :hover {
            background-color: lightgrey;
            cursor: pointer;
        }
    `
    : 
    styled.View<MenuContainerProps>`
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding: 20px;
        background-color: white;
        border-radius: 15px;
        box-shadow: 5px 5px 5px #8080800f;

        ${(props: any) => props.disabled && css`
          background-color: #e4e4e4;
          box-shadow: 0 0 rgba(0,0,0,0);
          border-width: 0;
        `};


        /* ${(props: any) => props.leftFloatMenuStyle && css`
            height: 40px;
            background-color: transparent;
            box-shadow: none;
        `} */
    `)

const Icon = styled(FontAwesomeIcon)`
  ${Platform.select({
    web: css`
      height: 30px;
    `,
  })}
`;