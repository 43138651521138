import {useApolloClient, useQuery, useReactiveVar} from '@apollo/client';
import {
  CommonActions,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import {View, Text, ActivityIndicator, StyleSheet} from 'react-native';
import {
  getJWT,
  JWTPayload,
  refreshToken,
  setErrorForUser,
  setUser,
  useCheckLoginToken,
} from '../components/hooks/useJWTLogin';
import {token, userVar} from '../graphql/Client';
import {IS_LOGGED_IN, IS_LOGGED_IN_INTERFACE} from '../graphql/User.entity';
import {getActiveRouteName} from './HomeScreen';
import ScreenNames from './ScreenNames';

let tokenRefreshTimeoutId: any;

const setTimeoutForRefreshToken = (
  oldToken: string,
  payLoadexp: number,
  client: any,
) => {
  const differenceInMillisecond = payLoadexp * 1000 - Date.now();
  const exp =
    differenceInMillisecond > 0
      ? differenceInMillisecond - 5000 // 5 sec-el hamarabb mint amikor lejár
      : 0; // egyből újítsa meg

  // console.warn(`refresh started, exp: ${exp}`);

  tokenRefreshTimeoutId = setTimeout(() => {
    refreshToken(oldToken, client).then((token) => {
      if (token) {
        const newPayload: {
          exp: number;
          sub: string;
          auth: string;
          id: number;
          comp_id: number;
          functions: string[];
        } = jwtDecode(token);
        setTimeoutForRefreshToken(token, newPayload.exp, client);
      }
    });
  }, exp);
};

export const LoadingScreen: React.FC<any> = ({route}) => {
  const navigationRef = useNavigation();
  const navigationState = useNavigationState((state) => state);
  const client = useApolloClient();

  const tokenValue = useReactiveVar(token);
  const userValue = useReactiveVar(userVar);

  React.useEffect(() => {
    // console.warn({token: userValue.token})
    if (userValue.isLoggedIn) {
      navigationRef.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{name: ScreenNames.menu}],
        }),
      );
    }

    if (userValue.error) {
        navigationRef.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{name: ScreenNames.login, params: {error: userValue.error}}],
            }),
          );
    }
  }, [userValue]);

  React.useEffect(() => {
    // INIT
    const init = async () => {
      const jwt = await getJWT();

      if (!jwt) {
        navigationRef?.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{name: ScreenNames.login}],
          }),
        );

        return;
      }

      const payload: JWTPayload = jwtDecode(jwt);
      // console.info({payload});

      if (Date.now() >= payload.exp * 1000) {
        const newToken = await refreshToken(jwt as string, client);

        if (newToken) {
          //   await setTimeOutRefresh(payload.exp, newToken);

          const newPayload: {
            exp: number;
            sub: string;
            auth: string;
            id: number;
            comp_id: number;
            functions: string[];
          } = jwtDecode(newToken);

        //   const differenceInMillisecond = newPayload.exp * 1000 - Date.now();
        //   const exp =
        //     differenceInMillisecond > 0
        //       ? differenceInMillisecond - 5000 // 5 sec-el hamarabb mint amikor lejár
        //       : 0; // egyből újítsa meg
        //   console.warn(`refresh started, exp: ${exp}`);

        //   tokenRefreshTimeoutId = setTimeout(() => {
        //     refreshToken(newToken, client);
        //   }, exp);

        setTimeoutForRefreshToken(newToken, newPayload.exp, client);

          //   setState('success');
        } else {
          //   setState('error');
          // console.error('csöcs');
          // userVar({
          //   error: `no token...`,
          //   isLoggedIn: false,
          //   email: '',
          //   token: '',
          //   role: '',
          //   id: 0,
          //   functions: [],
          //   validity: undefined,
          //   dispId: 0,
          // });

          setErrorForUser('no token...');
        }

        navigationRef.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{name: ScreenNames.menu}],
          }),
        );
      } else {
        token(jwt);

        setUser(jwt, payload);

        // userVar({
        //   token: jwt,
        //   email: payload.sub,
        //   role: payload.auth,
        //   isLoggedIn: true,
        //   id: payload.id,
        //   functions: payload.functions,
        //   error: undefined,
        //   validity: undefined,
        //   dispId: payload.v4diid,
        // });

        // const differenceInMillisecond = payload.exp * 1000 - Date.now();
        // const exp =
        //   differenceInMillisecond > 0
        //     ? differenceInMillisecond - 5000 // 5 sec-el hamarabb mint amikor lejár
        //     : 0; // egyből újítsa meg
        // console.warn(`refresh started, exp: ${exp}`);

        // tokenRefreshTimeoutId = setTimeout(() => {
        //   refreshToken(jwt, client);
        // }, exp);

        setTimeoutForRefreshToken(jwt, payload.exp, client);

        navigationRef.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{name: ScreenNames.menu}],
            }),
          );
      }
    };

    init();
  }, []);

  return (
    <View style={styles.container}>
      <Text style={{color: 'black'}}>Loading...</Text>
      <ActivityIndicator />
      <Text>{`${tokenValue}`}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
