import * as React from 'react';

function SvgSofor(props) {
  return (
    <svg
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.088 12.938c-1.29 0-1.91.718-4.025.718-2.116 0-2.732-.719-4.026-.719A6.04 6.04 0 000 18.976v1.869C0 22.034.966 23 2.156 23H17.97c1.19 0 2.156-.966 2.156-2.156v-1.869a6.04 6.04 0 00-6.037-6.038zm4.6 7.906a.72.72 0 01-.72.718H2.157a.72.72 0 01-.719-.718v-1.869c0-2.538 2.062-4.6 4.6-4.6.881 0 1.757.719 4.026.719 2.264 0 3.144-.719 4.024-.719 2.539 0 4.6 2.062 4.6 4.6v1.869zM10.063 11.5a5.75 5.75 0 100-11.5 5.75 5.75 0 100 11.5zm0-10.063a4.32 4.32 0 014.312 4.313 4.32 4.32 0 01-4.313 4.313A4.32 4.32 0 015.75 5.75a4.32 4.32 0 014.313-4.313z"
        fill="#003194"
      />
    </svg>
  );
}

export default SvgSofor;
