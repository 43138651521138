import * as React from 'react';
import { KeyForObjects } from "../VehicleDetailsConstants";
import {
    LicenseNo,
    Ignition,
    Driver,
    LastEvent,
    CurrentSpeed,
    DailyDist,
    Toll,
    TollError,
    EngineTemp,
    FuelLevel,
    DailyMaxSpeed,
    Address,
    ShippingFastOrange,
    ShippingFastTur,
    GasPumpOrange,
    GasPumpTur,
    ThermometerHalfOrange,
    ThermometerHalfTur,
    RoadOrange,
    RoadTur,
    TachometerAltFastOrange,
    TachometerAltFastTur,
    RouteOrange,
    RouteTur,
    UserOrange,
    UserTur,
    PowerOffOrange,
    PowerOffTur,
  } from '../../components/icons/Icons';
import { AnalogsFields } from '../../containers/route/RouteEntity';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import Colors from '../Colors';

export const getIcon = (
    key: KeyForObjects, 
    hasError: boolean, 
    selected: boolean, 
    width: number = 20,
    height: number = 20,
): React.ReactElement => {
    if (hasError) {
        return getErrorIcon(key, width, height);
    } else if (selected) {
        return getSelectedIcon(key, width, height);
    } else {
        return getSimpleIcon(key, width, height);
    }
}

export const getSimpleIcon = (
  key: KeyForObjects,
  width: number,
  height: number,
) => {
  switch (key) {
    case 'address':
      return <Address width={width} height={height} />;
    case 'daily_dist':
      return <RouteTur width={width} height={height} />;
    case 'daily_max_speed':
      return <ShippingFastTur width={width} height={height} />;
    case 'driver':
      return <UserTur width={width} height={height} />;
    case 'engine_temp':
      return <ThermometerHalfTur width={width} height={height} />;
    case 'fuel_level':
      return <GasPumpTur width={width} height={height} />;
    case 'gps_speed':
      return <TachometerAltFastTur width={width} height={height} />;
    case 'ignition':
      return <PowerOffTur width={width} height={height} />;
    case 'last_event':
      return <LastEvent width={width} height={height} />;
    case 'license_no':
      return <Address width={width} height={height} />;
    case 'toll_status':
      return <RoadTur width={width} height={height} />;
    case AnalogsFields.TempFront:
    case AnalogsFields.TempMiddle:
    case AnalogsFields.TempRear:
      return <FontAwesomeIcon icon={['fal', 'thermometer']} size={width} color={Colors.tertiary} />;
    default:
      return <Driver width={20} height={20} />;
  }
};

export const getErrorIcon = (
  key: KeyForObjects,
  width: number,
  height: number,
) => {
  switch (key) {
    case 'address':
      return <Address width={width} height={height} />;
    case 'daily_dist':
      return <DailyDist width={width} height={height} />;
    case 'daily_max_speed':
      return <DailyMaxSpeed width={width} height={height} />;
    case 'driver':
      return <Driver width={width} height={height} />;
    case 'engine_temp':
      return <EngineTemp width={width} height={height} />;
    case 'fuel_level':
      return <FuelLevel width={width} height={height} />;
    case 'gps_speed':
      return <CurrentSpeed width={width} height={height} />;
    case 'ignition':
      return <Ignition width={width} height={height} />;
    case 'last_event':
      return <LastEvent width={width} height={height} />;
    case 'license_no':
      return <Address width={width} height={height} />;
    case 'toll_status':
      return <Toll width={width} height={height} />;
    default:
      return <Driver width={20} height={20} />;
  }
};

export const getSelectedIcon = (
  key: KeyForObjects,
  width: number,
  height: number,
) => {
  switch (key) {
    case 'address':
      return <Address width={width} height={height} />;
    case 'daily_dist':
      return <RouteOrange width={width} height={height} />;
    case 'daily_max_speed':
      return <ShippingFastOrange width={width} height={height} />;
    case 'driver':
      return <UserOrange width={width} height={height} />;
    case 'engine_temp':
      return <ThermometerHalfOrange width={width} height={height} />;
    case 'fuel_level':
      return <GasPumpOrange width={width} height={height} />;
    case 'gps_speed':
      return <TachometerAltFastOrange width={width} height={height} />;
    case 'ignition':
      return <PowerOffOrange width={width} height={height} />;
    case 'last_event':
      return <LastEvent width={width} height={height} />;
    case 'license_no':
      return <Address width={width} height={height} />;
    case 'toll_status':
      return <RoadOrange width={width} height={height} />;
    default:
      return <Driver width={20} height={20} />;
  }
};
