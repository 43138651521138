import {gql, useApolloClient} from '@apollo/client';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
// import SInfo from 'react-native-sensitive-info';
import {token, userVar} from '../../graphql/Client';
// import messaging from '@react-native-firebase/messaging';
import {getUniqueId} from 'react-native-device-info';
import {DeviceType} from '../../constants/Constants';
import { goToLogin } from '../RootNavigation';

export interface JWTPayload {
  exp: number;
  sub: string;
  auth: string;
  id: number;
  comp_id: number;
  functions: string[];

  v4diid: number;
}

const refreshTokenGql = gql`
  query RefreshToken(
    $token: String!
    $deviceType: String!
    $deviceId: String!
    $firebaseToken: String!
  ) {
    refreshToken(
      token: $token
      deviceType: $deviceType
      deviceId: $deviceId
      firebaseToken: $firebaseToken
    ) {
      token
    }
  }
`;

export const getJWT = async () => {
  // const tokenRaw = await SInfo.getItem('jwt', {});
  // console.warn({obj: JSON.parse(tokenRaw)});
  // if (!tokenRaw) {
  //   return undefined;
  // }
  // const token = JSON.parse(tokenRaw);
  // console.warn({sessionId: token.token});
  // return token.jwt;
  return localStorage.getItem('jwt');
};

export const setJWT = async (jwt: string) => {
  // await SInfo.setItem('jwt', JSON.stringify({jwt}), {});
  localStorage.setItem('jwt', jwt);
};

export const logoutJWT = async () => {
  // await SInfo.deleteItem('jwt', {});
  localStorage.removeItem('jwt');
  userVar({
    isLoggedIn: false,
    email: '',
    token: '',
    role: '',
    id: 0,
    functions: [],
    error: undefined,
    validity: undefined,
    dispId: 0,
  });
  token(undefined);

  goToLogin();
};

export const setErrorForUser = (error: string) => {
  console.error({error});
  userVar({
    error: `${error}`,
    isLoggedIn: false,
    email: '',
    token: '',
    role: '',
    id: 0,
    functions: [],
    validity: undefined,
    dispId: 0,
  });
};

export const setUser = (token: string, payload: JWTPayload) => {
  // console.log({token, payload});
  
  userVar({
    token: token,
    email: payload.sub,
    role: payload.auth,
    isLoggedIn: true,
    id: payload.id,
    functions: payload.functions,
    error: undefined,
    validity: new Date(payload.exp * 1000),
    dispId: payload.v4diid,
  });
};

// TIMER
let jwtRefreshIntervalId: any;

export const refreshToken = async (
  oldToken: string,
  client: any,
): Promise<string | undefined> => {
  try {
    const fbToken = ''; // await messaging().getToken();
    const {data, error} = await client.query({
      query: refreshTokenGql,
      variables: {
        token: oldToken,
        deviceType: await DeviceType(),
        deviceId: getUniqueId(),
        firebaseToken: fbToken,
      },
      fetchPolicy: 'network-only',
    });

    if (error) {
      setErrorForUser(error);
      logoutJWT();
      return new Promise((_, reject) => reject(error));
    }

    if (data?.refreshToken?.token) {
      token(data?.refreshToken?.token);

      const payload: JWTPayload = jwtDecode(data?.refreshToken?.token);

      setUser(data?.refreshToken?.token, payload);

      return new Promise((resolve, _) =>
        resolve(data?.refreshToken?.token as string),
      );
    }
  } catch (refreshTokenExp) {
    console.error({refreshTokenExp});
    setErrorForUser(refreshTokenExp);
    logoutJWT();
  }
};

export const useCheckLoginToken = () => {
  const [state, setState] = React.useState<
    'loading' | 'error' | 'success' | undefined
  >(undefined);
  const client = useApolloClient();

  React.useEffect(() => {
    const setTimeOutRefresh = async (payloadExp: number, token: string) => {
      const differenceInMillisecond = payloadExp * 1000 - Date.now();
      const exp =
        differenceInMillisecond > 0
          ? differenceInMillisecond - 5000 // 5 sec-el hamarabb mint amikor lejár
          : 0; // egyből újítsa meg

      await new Promise((resolve, reject) => {
        jwtRefreshIntervalId = setTimeout(() => {
          refreshToken(token, client)
            .then((fun: any) => {
              setState('success');
              resolve('end');
              // fun();
            })
            .catch((e) => {
              setState('error');
              reject(e);
            });
        }, exp);
      });
    };

    const init = async () => {
      setState('loading');

      try {
        const temp = await getJWT();

        if (temp) {
          const payload: JWTPayload = jwtDecode(temp);

          if (Date.now() >= payload.exp * 1000) {
            const newToken = await refreshToken(temp as string, client);
            if (newToken) {
              await setTimeOutRefresh(payload.exp, newToken);
              setState('success');
            } else {
              setErrorForUser('no refresh token');
              setState('error');
              logoutJWT();
            }
          } else {
            token(temp); 
            setUser(temp, payload);

            setState('success');

            await setTimeOutRefresh(payload.exp, temp);
          }
        }
      } catch (exp) {
        console.error({exp}); // TODO: show error and navigate

        setErrorForUser(exp);

        setState('error');
        logoutJWT();

      }
    };

    init();
  }, []);

  return state;
};
