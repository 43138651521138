import * as React from 'react';
import {createContainer} from 'unstated-next';
import * as _ from 'lodash';
// import {APIURL} from '@env';
import { useReactiveVar } from '@apollo/client';
import axios from 'axios';

import { token, userVar } from './../graphql/Client';
import {getVehicleData} from './settings/VehicleDataGenerator';
import {
  VehicleDetailsSetting,
  NumberType,
} from '../constants/VehicleDetailsConstants';
// import crashlytics from '@react-native-firebase/crashlytics';
import {getActualDriver} from '../constants/Constants';
import {sendEvent} from './EventContainer';
import DispatcherContainer from './DispatcherContainer';
import SettingsContainer from './settings/SettingsContainer';
import { AnalogsFields, analogTypes, digitals } from './route/RouteEntity';

const APIURL = 'https://ifleet.hu'

export type Status = 'loading' | 'error' | 'success';

export interface Geocode {
  country?: string;
  city?: string;
  road?: string;
  poi?: {
    name: string;
    id: number;
  };
}

export interface GPS {
  fix: boolean;
  time: string;
  longitude: number;
  latitude: number;
  direction: number;
  speed: number;
  geocode: Geocode;
}

export type TollState =
  | 'none'
  | 'noassign'
  | 'gpserror'
  | 'balancewarning'
  | 'balanceerror'
  | 'fine'
  | 'nobalancedata';

export interface Drivers {
  state: boolean;
  name: string;
  logintime: string;
}

export interface Vehicle {
  license_no: string;
  vehicle_id: number;
  detail?: {
    model?: string;
    year?: number;
    chassis_no?: string;
  };
  share?: boolean;
  last_event: string;
  ignition: boolean;
  private_use: boolean;
  gps: GPS;
  actualDriver: {
    name: string;
    logintime?: string;
  };
  drivers: Drivers[];
  trailers: any[];
  digitals: any[];
  analogs: number[];
  kmclock?: any;
  fuel_level: number;
  rpm: number;
  engine_temp: number;
  fms_dist: number;
  total_fuel_flow: number;
  report_state?: any;
  ud?: TollState;
  daily_dist: number;
  signal_means: {
    digitals: digitals[];
    analogs: AnalogsFields[];
    analogTypes: analogTypes[];
  };
}

export interface FleetData {
  vehicles: Vehicle[];
  timestamp: Date;
}

function useFleetstate() {
  const [status, setStatus] = React.useState<Status>();
  const [errorCode, setErrorCode] = React.useState<number | undefined>();
  const [data, setData] = React.useState<FleetData>();

  const user = useReactiveVar(userVar);
  const {token: jwt} = user;

  const dispatcherContainer = DispatcherContainer.useContainer();
  const settingsContainer = SettingsContainer.useContainer();

  // React.useEffect(() => { // TODO: majd megcsinálni
  //     getAll();
  // }, [dispatcherContainer.currentVehicleGroup]);

  // React.useEffect(() => {
  //     getAll();
  // }, [settingsContainer.mapVehicleLicenseNoShowOnMarker]);

  const getAll = async () => {
    try {
      setStatus('loading');

      // @ts-ignore
      // const token = global['token'];

      if (!jwt) {
        console.error('No token, fleetstate get all function.', token);
        // crashlytics().log(
        //   `No token, fleetstate getAll function. Token: ${token}`,
        // );
        // analytics().logEvent('no-token-error', {
        //     function: 'fleetstate-getAll',
        // });
        setStatus('error');
        return;
      }

      // @ts-ignore
      // const groupId = global['vehicleGroup']?.id;

      // console.log({groupId});

      const getURL = 
        `${APIURL}/fleetstate/token?${dispatcherContainer.currentVehicleGroup?.id ? '&groupId=' + dispatcherContainer.currentVehicleGroup?.id : ''}`;
      // console.log({getURL});
      const {data, status} = await axios.get(getURL, { headers: {
        Authorization: `Bearer ${jwt}`
      }});

      if (status === 200 && data?.vehicles && Array.isArray(data.vehicles)) {
        const tempVehicle = data.vehicles
          .filter((vehicle: any) => vehicle.license_no)
          .map((v: any) => ({...v, actualDriver: getActualDriver(v)}));
        setData({
          vehicles: tempVehicle as Vehicle[],
          timestamp: new Date(),
        });
        setStatus('success');
      } else {
        // crashlytics().log(
        //   `Fleetstate getAll error, data: ${data}, status: ${status}`,
        // );
        // analytics().logEvent('fleetstate-get-all-error', {
        //     function: 'fleetstate-getAll',
        //     status,
        //     data,
        // });
        sendEvent('error', {
          function: 'fleetstate-getAll',
          status,
          data,
        });
        setErrorCode(1);
        setStatus('error');
      }
    } catch (fleetGetAllExp) {
      console.error({fleetGetAllExp});
      setStatus('error');
      // crashlytics().log(
      //   `Fleetstate getAll exception, exp: ${JSON.stringify(fleetGetAllExp)}`,
      // );
      // analytics().logEvent('fleetstate-get-all-error', {
      //     function: 'fleetstate-getAll',
      //     exception: fleetGetAllExp,
      // });
    //   console.warn({fleetGetAllExp});
      sendEvent('error', {
        function: 'fleetstate-getAll',
        exception: fleetGetAllExp,
      });
      if (fleetGetAllExp.response) {
        setErrorCode(fleetGetAllExp.response.status);
      } else {
        setErrorCode(0);
      }
    }
  };

  // TODO: minden mezőtípusra felkészíteni....
  const getDataWithSort = (
    column: VehicleDetailsSetting | undefined,
    asc: boolean,
    filter: string = '',
  ) => {
    // setStatus('loading');
    if (
      !data?.vehicles ||
      !Array.isArray(data?.vehicles) ||
      column === undefined
    ) {
      return [];
    }

    let {vehicles} = data;

    if (filter.length) {
      vehicles = data.vehicles.filter((vehicle) => {
        // TODO: comment why {vehicle}
        const value = getVehicleData(vehicle, {vehicle}, column).value;

        if (typeof value === 'string') {
          return value.toLowerCase().includes(filter);
        }

        if (column.type === 'number') {
          const col = column as NumberType;
          if (col.multiplication) {
            return (
              +(value * col.multiplication).toFixed(col.fixed) ===
              Number.parseInt(filter)
            );
          }
          return +value.toFixed(col.fixed) === Number.parseInt(filter);
        }

        return false;
      });
    }

    let temp;
    if (asc) {
      temp = vehicles.sort((a, b) => {
        const value = getVehicleData(a, {vehicle: a}, column, true).value;
        const valueB = getVehicleData(b, {vehicle: b}, column, true).value;

        if (column.type === 'number') {
          if (_.isNumber(+value) && _.isNumber(+valueB)) {
            return value - valueB;
          }

          if (_.isUndefined(value)) {
            return -1;
          }

          if (_.isUndefined(valueB)) {
            return 1;
          }
        }

        // Azért, hogy a nincs sofőr az mindig a lista végén legyen
        if (column.id === 'driver') {
          if (typeof valueB === 'undefined' || valueB === 'Nincs sofőr') {
            return -1;
          }

          if (typeof value === 'undefined' || value === 'Nincs sofőr') {
            return 1;
          }
        }

        if (typeof valueB === 'undefined') {
          return 1;
        }

        if (typeof value === 'undefined') {
          return -1;
        }

        if (typeof value === 'string' && typeof valueB === 'string') {
          return ('' + value).localeCompare('' + valueB);
        }

        // if (_.isNumber(value) && _.isNumber(valueB)) {
        //     console.log({value, valueB, isNumber: true})
        //     return (value) - (valueB);
        // }

        return 0;
      });
    } else {
      temp = vehicles.sort((a, b) => {
        const value = getVehicleData(a, {vehicle: a}, column).value;
        const valueB = getVehicleData(b, {vehicle: b}, column).value;

        if (column.type === 'number') {
          if (_.isNumber(value) && _.isNumber(valueB)) {
            return valueB - value;
          }

          if (_.isUndefined(value)) {
            return 1;
          }

          if (_.isUndefined(valueB)) {
            return -1;
          }
        }

        // Azért, hogy a nincs sofőr az mindig a lista végén legyen
        if (column.id === 'driver') {
          if (typeof valueB === 'undefined' || valueB === 'Nincs sofőr') {
            return -1;
          }

          if (typeof value === 'undefined' || value === 'Nincs sofőr') {
            return 1;
          }
        }

        if (typeof valueB === 'undefined') {
          return -1;
        }

        if (typeof value === 'undefined') {
          return 1;
        }

        if (typeof value === 'string' && typeof valueB === 'string') {
          return ('' + valueB).localeCompare('' + value);
        }

        // if (_.isNumber(value) && _.isNumber(valueB)) {
        //     return (valueB) - (value);
        // }

        return 0;
      });
    }

    // setStatus('success');
    return temp;
  };

  return {status, getAll, data, getDataWithSort, errorCode};
}

const FleetstateContainer = createContainer(useFleetstate);

export default FleetstateContainer;
