import React from 'react';
import {SafeAreaView, View, StyleSheet, Alert} from 'react-native';
import SettingsContainer from '../../containers/settings/SettingsContainer';
import {Text, Headline, Divider, Switch} from 'react-native-paper';
import {TouchableOpacity} from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import Colors from '../../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {translate} from "../../components/hooks/useTranslate";
import ScreenNames from '../ScreenNames';

const Settings = () => {
  const settingsContainer = SettingsContainer.useContainer();
  const navigation = useNavigation();

  return (
    <SafeAreaView style={styles.container}>
      <Headline style={styles.headText}>{translate('drawer.button.settings')}</Headline>
      <View>
        <Divider></Divider>
        <TouchableOpacity onPress={() => navigation.navigate(ScreenNames.settings.vehicleDetails, {type: 'map-vehicle-fields'})}>
          <View style={styles.page}>
            <Text style={styles.pageName}>{translate('settings.header.map-vehicle-fields')}</Text>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} color={Colors.primary}/>
          </View>
        </TouchableOpacity>
        <Divider></Divider>

        {/* <TouchableOpacity onPress={() => navigation.navigate(ScreenNames.settings.vehicleDetails, {type: 'vehicle-fields'})}>
        <View style={styles.page}>
            <Text style={styles.pageName}>{translate('settings.header.vehicle-fields')}</Text>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} color={Colors.primary}/>
          </View>
        </TouchableOpacity>
        <Divider></Divider> */}

        <TouchableOpacity onPress={() => navigation.navigate(ScreenNames.settings.vehicleDetails, {type: 'list-vehicle-fields'})}>
        <View style={styles.page}>
            <Text style={styles.pageName}>{translate('settings.header.list-vehicle-fields')}</Text>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} color={Colors.primary}/>
          </View>
        </TouchableOpacity>
        <Divider></Divider>


        <TouchableOpacity onPress={() => navigation.navigate(ScreenNames.settings.vehicleGroup)}>
        <View style={styles.page}>
            <Text style={styles.pageName}>{translate('settings.vehicle-group')}</Text>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} color={Colors.primary}/>
          </View>
        </TouchableOpacity>
        <Divider></Divider>

        <View style={styles.containerSwitch}>
          <Divider />
          <View style={styles.page} >
            <Text style={styles.pageName}>{translate('settings.visible-license-no-on-map')}</Text>
            <Switch style={{ transform: [{ scaleX: .8 }, { scaleY: .8 }] }} value={settingsContainer.mapVehicleLicenseNoShowOnMarker} onValueChange={() => settingsContainer.setMapVehicleLicenseNoShowOnMarker(!settingsContainer.mapVehicleLicenseNoShowOnMarker)}/>
          </View>
          <Divider />
          <Divider />
          <View style={styles.page} >
            <Text style={styles.pageName}>{translate('settings.satellite-map')}</Text>
            <Switch style={{ transform: [{ scaleX: .8 }, { scaleY: .8 }] }} value={settingsContainer.mapType === 'hybrid'} onValueChange={() => settingsContainer.setMapType(settingsContainer.mapType === 'standard' ? 'hybrid' : 'standard')}/>
          </View>
          <Divider />
          <Divider />
          <View style={styles.page} >
            <Text style={styles.pageName}>{translate('settings.route-alignment')}</Text>
            <Switch style={{ transform: [{ scaleX: .8 }, { scaleY: .8 }] }} value={settingsContainer.routeAlignment} onValueChange={(value) => settingsContainer.setRouteAlignment(value)}/>
          </View>
          <Divider />
          {/* <Divider />
          <View style={styles.page} >
            <Text style={styles.pageName}>Parkolási idő - számláló</Text>
            <Switch style={{ transform: [{ scaleX: .8 }, { scaleY: .8 }] }} value={settingsContainer.counterForParking} onValueChange={(value) => settingsContainer.setCounterForParking(value)}/>
          </View>
          <Divider /> */}
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
  },
  headText: {
    fontWeight: '600',
    marginBottom: 40,
    marginTop: 10,
  },
  page: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  },
  pageName: {
    color: 'grey',
    fontSize: 16,
  },

  containerSwitch: {
    marginTop: 60,
  }
});

export default Settings;
