import * as React from 'react';
// import styled from 'styled-components';
import {LayersControl, MapContainer, TileLayer} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Colors from '../constants/Colors';

const initalValue = {
    latitude: 47.502630,
    longitude: 19.047231,
    zoom: 10,
}
export interface MapOptions {
  children?: React.ReactNode;
  center?: {
    latitude: number;
    longitude: number;
  };
  zoom?: number;
}

export const Map: React.FC<MapOptions> = ({children, center, zoom}) => {
  return (
    <div style={{width: '100%', height: '100%'}}>
      <MapContainer
        id={'map'}
        style={{
          width: '100%', 
          height: '100%',
          background: Colors.background,
          backgroundImage: 'linear-gradient(lightgray .1em, transparent .1em), linear-gradient(90deg, lightgray .1em, transparent .1em)',
          backgroundSize: '3em 3em',
        }}
        center={center ? [center.latitude, center.longitude] : [initalValue.latitude, initalValue.longitude]}
        zoom={zoom ? zoom : initalValue.zoom}
        scrollWheelZoom={true}>
        <LayersControl position="bottomleft">
          <LayersControl.BaseLayer name="GoogleMaps.Satellite">
            <ReactLeafletGoogleLayer type={'satellite'} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="GoogleMaps.Roadmap">
            <ReactLeafletGoogleLayer type={'roadmap'} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="GoogleMaps.Hybrid">
            <ReactLeafletGoogleLayer type={'hybrid'} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="OpenStreetMap.NoLabel">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://tiles.wmflabs.org/osm-no-labels/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Maps.WMF">
            <TileLayer
              attribution='<a href="https://foundation.wikimedia.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png"
            />
          </LayersControl.BaseLayer>

        </LayersControl>
        {children}
      </MapContainer>
    </div>
  );
};
