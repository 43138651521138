import * as React from 'react';

function SvgGyujtas(props) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.176 1.809A9.688 9.688 0 0119.687 10c0 5.344-4.328 9.676-9.668 9.688-5.332.011-9.699-4.336-9.707-9.672a9.687 9.687 0 014.512-8.207.468.468 0 01.66.164l.153.273a.469.469 0 01-.16.625A8.458 8.458 0 001.561 10c0 4.684 3.801 8.438 8.438 8.438 4.683 0 8.437-3.801 8.437-8.438a8.418 8.418 0 00-3.918-7.125.47.47 0 01-.16-.625l.153-.273a.474.474 0 01.664-.168zm-4.551 8.972V.47A.47.47 0 0010.156 0h-.312a.47.47 0 00-.47.469V10.78c0 .258.212.469.47.469h.312a.47.47 0 00.469-.469z"
        fill="#003194"
      />
    </svg>
  );
}

export default SvgGyujtas;
