import * as React from 'react';

function SvgJelenlegiSebesseg(props) {
  return (
    <svg
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#jelenlegi_sebesseg_svg__clip0)">
        <path
          d="M4.688 12.5a.937.937 0 11-1.875 0 .937.937 0 011.874 0zm6.562-6.563a.937.937 0 100-1.874.937.937 0 000 1.875zm-5.313.313a.937.937 0 100 1.874.937.937 0 000-1.874zm11.018.452c.27.217.31.61.093.878l-3.794 4.688c.308.416.496.925.496 1.482a2.5 2.5 0 11-2.5-2.5c.37 0 .718.085 1.034.23l3.793-4.685a.625.625 0 01.878-.093zM12.5 13.75a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm6.25-2.188a.937.937 0 100 1.875.937.937 0 000-1.874zm3.75.938a11.19 11.19 0 01-1.526 5.656c-.219.376-.637.594-1.072.594H2.598c-.435 0-.853-.218-1.072-.594A11.191 11.191 0 010 12.5C0 6.287 5.037 1.25 11.25 1.25S22.5 6.287 22.5 12.5zm-1.25 0c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 1.768.469 3.506 1.355 5.027l17.297-.027a9.89 9.89 0 001.348-5z"
          fill="#003194"
        />
      </g>
      <defs>
        <clipPath id="jelenlegi_sebesseg_svg__clip0">
          <path fill="#fff" d="M0 0h22.5v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgJelenlegiSebesseg;
