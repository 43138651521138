import * as React from 'react';

function SvgGasPumpLight1Narancs(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M502.6 120L409 26.3c-3.1-3.1-8.2-3.1-11.3 0l-11.3 11.3c-3.1 3.1-3.1 8.2 0 11.3L416 78.6V160c0 26.5 21.5 48 48 48h16v174.2c0 15.9-10.9 30.8-26.6 33.4-20 3.3-37.4-12.1-37.4-31.6v-48c0-44.2-35.8-80-80-80h-16V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v416H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h336c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-24V288h16c26.5 0 48 21.5 48 48v44.4c0 31.9 21.7 61.5 53.2 66.7 40.1 6.6 74.8-24.3 74.8-63.1V142.6c0-8.5-3.4-16.6-9.4-22.6zM480 176h-16c-8.8 0-16-7.2-16-16v-49.4l32 32V176zM288 480H64V224h224v256zm0-288H64V64c0-17.6 14.4-32 32-32h160c17.6 0 32 14.4 32 32v128z"
        fill="#F86F08"
      />
    </svg>
  );
}

export default SvgGasPumpLight1Narancs;
