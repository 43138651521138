import * as React from 'react';

function SvgIfleet(props) {
  return (
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ifleet_svg__a">
          <stop stopColor="#4667B0" offset="0%" />
          <stop stopColor="#0E3A9A" offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="ifleet_svg__b">
          <stop stopColor="#F99B53" offset="0%" />
          <stop stopColor="#F87007" offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="ifleet_svg__c">
          <stop stopColor="#4DC8DD" offset="0%" />
          <stop stopColor="#14B5D4" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="23.198%"
          y1="0%"
          x2="85.634%"
          y2="85.79%"
          id="ifleet_svg__d">
          <stop stopColor="#4A6BB2" offset="0%" />
          <stop stopColor="#103D9B" offset="100%" />
        </linearGradient>
      </defs>
      <g transform="translate(230.4 221.867)" fill="none" fillRule="evenodd">
        <rect
          fill="url(#ifleet_svg__a)"
          y={238.933}
          width={153.6}
          height={384}
          rx={76.8}
        />
        <rect
          fill="url(#ifleet_svg__b)"
          x={238.933}
          width={384}
          height={153.6}
          rx={76.8}
        />
        <rect
          fill="url(#ifleet_svg__c)"
          x={238.933}
          y={238.933}
          width={273.067}
          height={153.6}
          rx={76.8}
        />
        <rect fill="url(#ifleet_svg__d)" width={153.6} height={153.6} rx={64} />
      </g>
    </svg>
  );
}

export default SvgIfleet;
