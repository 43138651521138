import { userVar } from './../graphql/Client';
import { sendEvent } from './EventContainer';
import React from 'react';
import { createContainer } from 'unstated-next';
import axios from 'axios';
import { APIURL } from '@env';
import { useReactiveVar } from '@apollo/client';
import moment from 'moment';
import { Route, VehicleData } from './route/RouteEntity';
import SettingsContainer from './settings/SettingsContainer';
export type Status = 'loading' | 'error' | 'success';

/**
 * TODO: kell egy leíró ami a következő adatokat tudja kiszedni textként:
 * - alap string
 * - boolean, true and false value
 * - number, with fixed and multiplication
 * - date, moment format,
 *
 * Ha null/undefined akkor mit írjon ki helyette.
 */

function useVehicleRoute() {
  const settingsContainer = SettingsContainer.useContainer();
  const user = useReactiveVar(userVar);
  const [status, setStatus] = React.useState<Status>();
  const [vehicle, setVehicle] = React.useState<VehicleData | undefined>();
  // const [route, setRoute] = React.useState<Map<string, Route> | undefined>();
  const [route, setRoute] = React.useState<Route[] | undefined>();
  const [errorCode, setErrorCode] = React.useState<number | undefined>();

  const get = async (vehicleId: number, startDate?: Date, endDate?: Date, withSignals: boolean = false) => {
    try {
      setStatus('loading');

      // @ts-ignore
      // const token = global['token'];

      if (!user.token) {
        console.error('No token, vehicle route get function.', user.token);
        // crashlytics().log(`No token, vehicle route get function. Token: ${user.token}`);
        // analytics().logEvent('no-token-error', {
        //   function: 'vehicle-route-get',
        // });
        setStatus('error');
        setErrorCode(2);
        return;
      }

      if (!vehicleId) {
        console.error('No vehicleId arg, vehicle route get function.', vehicleId, user.token);
        // crashlytics().log(`No vehicleId arg, vehicle route get function. Token: ${user.token}, VehicleId: ${vehicleId}`);
        // analytics().logEvent('no-vehicle-id-error', {
        //   function: 'vehicle-route-get',
        // });
        setStatus('error');
        setErrorCode(1);
        return;
      }
      let URL = `${APIURL}/beta/vehicleroute/token?vehicleId=${vehicleId}&withsignals=${withSignals}&roadmerge=${settingsContainer.routeAlignment}`;
      if (startDate && endDate) {
        URL += `&begin=${moment(startDate).format('yyyy-MM-DDT00:00:00\\Z')}&end=${moment(endDate).format('yyyy-MM-DDT23:59:59\\Z')}`;
      }

      const { data, status } = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });


      if (status === 200 && data?.license_no && data?.vehicle_id) {
        setVehicle(data);

        if (data?.last_duration) {
          const lastDuration = data.last_duration
          .filter((point: Route) => point.fix);
          setRoute(lastDuration);
        } else {
          setRoute(undefined);
        }

        setStatus('success');
      } else {
        sendEvent('error', {
          function: 'vehicle-route-get',
          status,
          data,
        });
        setErrorCode(1);
        setStatus('error');
      }
    } catch (vehicleGetExp) {
      console.error({ vehicleGetExp });
      setStatus('error');

      sendEvent('error', {
        function: 'vehicle-route-get',
        exception: vehicleGetExp,
      });

      if (vehicleGetExp.response) {
        setErrorCode(vehicleGetExp.response.status);
      } else {
        setErrorCode(0);
      }
    }
  };

  // const getPointData = (timestamp: string): Route | undefined => {
  //   return route?.get(timestamp);
  // }

  return { status, get, vehicle, route, errorCode };
}

const VehicleRouteContainer = createContainer(useVehicleRoute);

export default VehicleRouteContainer;
