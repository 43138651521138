import * as React from 'react';

function SvgJarmuGyujtaski(props) {
  return (
    <svg
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#jarmu_gyujtaski_svg__clip0)">
        <path
          d="M9.374 22.536C15.806 13.074 17 12.102 17 8.625 17 3.862 13.194 0 8.5 0S0 3.862 0 8.625c0 3.477 1.194 4.449 7.626 13.911a1.054 1.054 0 001.748 0zM8.5 12.219c-1.956 0-3.542-1.61-3.542-3.594 0-1.985 1.586-3.594 3.542-3.594 1.956 0 3.542 1.61 3.542 3.594 0 1.985-1.586 3.594-3.542 3.594z"
          fill="url(#jarmu_gyujtaski_svg__paint0_linear)"
        />
        <path
          d="M8.5 11A2.503 2.503 0 016 8.5C6 7.122 7.121 6 8.5 6S11 7.122 11 8.5 9.879 11 8.5 11z"
          fill="url(#jarmu_gyujtaski_svg__paint1_linear)"
        />
        <path
          d="M5.5 8.5c0 1.654 1.345 3 3 3s3-1.346 3-3-1.345-3-3-3-3 1.346-3 3z"
          stroke="#fff"
          strokeOpacity={0.6}
        />
      </g>
      <defs>
        <linearGradient
          id="jarmu_gyujtaski_svg__paint0_linear"
          x1={0}
          y1={0}
          x2={17}
          y2={23}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#58E" />
          <stop offset={1} stopColor="#042975" />
        </linearGradient>
        <linearGradient
          id="jarmu_gyujtaski_svg__paint1_linear"
          x1={6}
          y1={6}
          x2={11}
          y2={11}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B1B1B1" />
          <stop offset={1} stopColor="#797979" />
        </linearGradient>
        <clipPath id="jarmu_gyujtaski_svg__clip0">
          <path
            transform="matrix(-1 0 0 1 17 0)"
            fill="#fff"
            d="M0 0h17v23H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgJarmuGyujtaski;
