import * as React from 'react';
import { Marker } from 'react-leaflet';
import styled, { css } from 'styled-components';
import Colors from '../constants/Colors';

// export const MapContentContainer: React.FC<{children?: React.ReactNode}> = ({children: Child}) => {
//     return <Child />;
// }

const Container = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 801;
    height: 85vh;
    overflow-x: scroll;
    border-radius: 10px;
    background-color: ${Colors.background};
    padding: 10px;
`;

export const FullRightContainer = styled.div`
    position: absolute;
    top: 20px;
    bottom: 20px;
    right: 20px;
    z-index: 801;
    /* height: 85vh; */
    /* height: 100%; */
    overflow-x: scroll;
    border-radius: 10px;
    background-color: ${Colors.background};
    padding: 10px;
`;

export const BottomCenterContainer = styled.div<{width?: string, fullHeight?: boolean}>`
    position: absolute;
    bottom: 20px;

    ${props => props.fullHeight && css`
        top: 20px;
    `}

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    z-index: 801;
    overflow-x: scroll;
    border-radius: 10px;
    background-color: ${Colors.background};
    padding: 10px;

    width: ${props => props.width ?? '250px'};
`;

export const MapContainer = styled.div<{
    fullHeight?: boolean;
    fullWidth?: boolean;
    width?: string;
    height?: string;
    type: 'center' | 'left' | 'right';
    anotherType: 'top' | 'bottom';
    gradient?: boolean;
}>`
    position: absolute;
    z-index: 1000;
    // overflow-x: scroll;

    ${props => props.fullHeight && css`top: 20px; bottom: 20px;`}
    ${props => props.fullWidth && css`left: 20px; right: 20px;`}

    width: ${props => props.width ?? 'auto'};
    height: ${props => props.height ?? 'auto'};

    ${props => {
        switch(props.type) {
            case 'center':
                return css`
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                `;
            case 'left':
                return css`
                    left: 20px;
                `;
            case 'right':
                return css`
                    right: 20px;
                `;
        }
    }}

    ${props => {
        switch(props.anotherType) {
            case 'top':
                return css`
                    top: 20px;
                `;
            case 'bottom':
                return css`
                    bottom: 20px;
                `;
        }
    }}

    border-radius: 10px;
    background-color: ${Colors.background};

    ${props => props.gradient && css`background: linear-gradient(150.64deg, #FFFFFF 0%, #CECECE 100%);`}
    
    padding: 10px;
    box-shadow: 0px 5px 5px #80808052;

    transition: width 0.2s ease-out, height 0.8s;
`;