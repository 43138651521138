import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Checkbox, Divider, Headline, Text} from 'react-native-paper';
import {
  AddressObject,
  LicenseNoObject,
} from '../../../constants/VehicleDetailsConstants';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Colors from '../../../constants/Colors';
import SettingsContainer from '../../../containers/settings/SettingsContainer';
import {translate} from "../../../components/hooks/useTranslate";

const ShortScreen: React.FC = () => {
  // TODO selected field to fleet container
  const settingsContainer = SettingsContainer.useContainer();

  return (
    <View style={styles.container}>
      <Headline style={styles.headText}>{translate('common.list')}</Headline>
      <Text style={styles.secHeadText}>{translate('fleet.list.short-and-find-by-data')}</Text>
      <Divider />
      <TouchableOpacity
        onPress={() => {
          settingsContainer.setShortByField(LicenseNoObject);
        }}>
        <View style={styles.row}>
          <Text style={styles.rowName}>{translate('common.text.license_no')}</Text>
          <Checkbox
            status={
              settingsContainer.shortByField?.id === 'license_no'
                ? 'checked'
                : 'unchecked'
            }
            // onPress={() => setGroup(vGroup)}
            color={Colors.primary}
            // uncheckedColor={'grey'}
          />
        </View>
      </TouchableOpacity>
      <Divider />
      <TouchableOpacity
        onPress={() => {
          settingsContainer.setShortByField(AddressObject);
        }}>
        <View style={styles.row}>
          <Text style={styles.rowName}>{translate('fleet.vehicle-details.fields.address')}</Text>
          <Checkbox
            status={
              settingsContainer.shortByField?.id === 'address'
                ? 'checked'
                : 'unchecked'
            }
            // onPress={() => setGroup(vGroup)}
            color={Colors.primary}
            // uncheckedColor={'grey'}
          />
        </View>
      </TouchableOpacity>
      {settingsContainer.listVehicleDetailsFields.map((obj) => {
        return (
          <View key={`${obj.id}-short-screen`}>
            <Divider />
            <TouchableOpacity
              onPress={() => {
                settingsContainer.setShortByField(obj);
              }}>
              <View style={styles.row}>
                <Text style={styles.rowName}>{translate(`fleet.vehicle-details.fields.${obj.id}`)}</Text>
                <Checkbox
                  status={
                    settingsContainer.shortByField?.id === obj.id
                      ? 'checked'
                      : 'unchecked'
                  }
                  // onPress={() => setGroup(vGroup)}
                  color={Colors.primary}
                  // uncheckedColor={'grey'}
                />
              </View>
            </TouchableOpacity>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
  },
  headText: {
    fontSize: 26,
    fontWeight: '600',
    marginBottom: 5,
    marginTop: 10,
  },
  secHeadText: {
    fontSize: 16,
    fontWeight: '600',
    color: 'grey',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  rowName: {
    color: 'grey',
    fontSize: 18,
  },
});

export default ShortScreen;
