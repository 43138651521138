// import 'react-native-reanimated';
import {ApolloProvider} from '@apollo/client';
import * as React from 'react';
import {LangProvider, translate} from './components/hooks/useTranslate';
import {client} from './graphql/Client';
import {Provider as PaperProvider, DefaultTheme} from 'react-native-paper';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import Colors from './constants/Colors';
import FleetstateContainer from './containers/FleetstateContainer';
import SettingsContainer from './containers/settings/SettingsContainer';
import DispatcherContainer from './containers/DispatcherContainer';
import VehicleRouteContainer from './containers/VehicleRouteContainer';
import {Navigation} from './web/StackNavigator';
import {Theme} from 'react-native-paper/lib/typescript/types';
import RatingContainer from './containers/RatingContainer.web';

const theme: Theme = {
  ...DefaultTheme,
  roundness: 8,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.primary,
    accent: Colors.secondary,
  },
};

/**
 * Providers.
 * @returns
 */
const App: React.FC<any> = () => {
  return (
      <RatingContainer.Provider>
        <SettingsContainer.Provider>
          <DispatcherContainer.Provider>
            <FleetstateContainer.Provider>
              <VehicleRouteContainer.Provider>
                <PaperProvider
                  theme={theme}
                  settings={{
                    icon: props => {
                      if (!props?.name) {
                        return (
                          <FontAwesomeIcon
                            icon={['fal', 'empty-set']}
                            {...props}
                          />
                        );
                      }

                      return (
                        <FontAwesomeIcon
                        // @ts-ignore
                        icon={['fal', props.name]}
                          {...props}
                        />
                      );
                    },
                  }}>
                  <ApolloProvider client={client}>
                    <LangProvider>
                      <Navigation />
                    </LangProvider>
                  </ApolloProvider>
                </PaperProvider>
              </VehicleRouteContainer.Provider>
            </FleetstateContainer.Provider>
          </DispatcherContainer.Provider>
        </SettingsContainer.Provider>
      </RatingContainer.Provider>
  );
};

export default App;
