export default {
	login: 'login',
	loading: 'loading',
	menu: 'menu', // 1.6.0
	parking: 'parking', // 1.6.0
	about: 'about',
	support: 'support',
	logs: 'logs',
	rate: 'rate',
	error: 'error',
	home: 'home',
	contacts: 'contacts',
	newFunctions: 'newFunctionsMarketing',
	emailVerification: 'email-verification', // only web
	resetPassword: 'reset-password', // only web
	createNewPassword: 'create-new-password', // only web
	settings: {
		allSettings: 'settings',
		vehicleDetails: 'settingsVehicleDetails',
		vehicleGroup: 'settingsVehicleGroup',
	},
	route: 'route',
	vehicleSelector: 'vehicleSelector',
	vehicleRouteMap: 'vehicleRouteMap',
	dateRangePicker: 'dateRangePicker',
	fleetState: {
		tabNavigation: 'fleetState',
		vehicleDetails: 'fleetStateVehicleDetails',
		vehicleListShort: 'fleetStateVehicleListShort',
		logSheetDetails: 'fleetStateLogSheetDetails',
	},
	obu: {
		data: 'obuData',
		list: 'obuList',
		map: 'obuMap',
		jdb: 'obuJdb',
	},
};
