import { DefaultTheme } from "@react-navigation/native";

const Colors = {
    primary: 'rgb(33, 58, 143)',
    secondary: 'rgb(236, 102, 8)',
    tertiary: 'rgb(0, 177, 209)',
    background: '#F0F0F0',

    onlyRaw: {
        secondary: '236, 102, 8',
    },

    bar: {
        inactive: '#373737',
        active: 'rgb(236, 102, 8)',
    },
    
    fleetstate: {
        mapBarColor: '#E6E6E6',
        listBarColor: '#EEEEEE',
        tintColor: 'blue'
    },

    parking: {
        polygonSelected: {
            fill: 'rgba(236, 102, 8, 0.2)',
            stroke: 'rgba(236, 102, 8, 0.6)',
        },
        polygonUnselected: {
            fill: 'rgba(0, 177, 209, 0.2)',
            stroke: 'rgba(0, 177, 209, 0.6)',
        }
    }
}

export default Colors;

export const ReactNavigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: Colors.background
    },
  };
