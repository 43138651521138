import * as React from 'react';

function SvgErtekelesKivalasztva(props) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#Ertekeles_kivalasztva_svg__clip0)">
        <path
          d="M19.638 8.437l-3.956 3.843.933 5.429c.136.775-.485 1.396-1.181 1.396-.193 0-.383-.048-.554-.139l-4.888-2.564v-2.024l.836.438 3.814 2-.728-4.233-.16-.929.677-.657 3.087-3-4.27-.62-.932-.136-.42-.844L9.99 2.543V0a1.191 1.191 0 011.075.665l2.441 4.94 5.467.795c.98.141 1.374 1.347.663 2.037z"
          fill="url(#Ertekeles_kivalasztva_svg__paint0_linear)"
        />
        <path
          d="M3.376 17.705l.933-5.429L.362 8.433c-.709-.69-.318-1.896.661-2.037l5.457-.791L8.918.665A1.182 1.182 0 019.99 0v16.403l-4.882 2.56c-.874.459-1.9-.284-1.733-1.258z"
          fill="url(#Ertekeles_kivalasztva_svg__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="Ertekeles_kivalasztva_svg__paint0_linear"
          x1={9.991}
          y1={-0.17}
          x2={23.322}
          y2={2.546}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3563BF" />
          <stop offset={1} stopColor="#052F84" />
        </linearGradient>
        <linearGradient
          id="Ertekeles_kivalasztva_svg__paint1_linear"
          x1={4.995}
          y1={0}
          x2={4.995}
          y2={19.102}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8B33" />
          <stop offset={1} stopColor="#D75E04" />
        </linearGradient>
        <clipPath id="Ertekeles_kivalasztva_svg__clip0">
          <path fill="#fff" d="M0 0h20v19.105H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgErtekelesKivalasztva;
