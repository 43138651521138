import * as React from 'react';
import styled, { css } from 'styled-components';
import { MenuComponentProps } from './Menu.types';

export const MenuComponent: React.FC<MenuComponentProps> = ({
    children, 
    onPress, 
    leftFloatMenuStyle,
    disabled,
}) => {
    return (
        <Container 
            onClick={disabled === true ? undefined : onPress} 
            leftFloatMenuStyle={leftFloatMenuStyle}
            >
            {children}
        </Container>
    );
}

const Container = styled.div<{onClick: any, leftFloatMenuStyle?: boolean}>`

    background-color: transparent;

    width: 350px;
    margin: 0;

    ${(props: any) => props.leftFloatMenuStyle && css`
        width: 240px;
   `};

`;